import React from "react";
import "../Assets/UserProfile.css";
import { useTranslation } from "react-i18next";
const UserProfile = ({ user }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="profile_container">
      <h2>
      {t('topbar.hi')}, <span>{user.name}</span>
      </h2>
    </div>
  );
};

export default UserProfile;
