import "./GenericTable.css";
import ViewIcon from "../../Assets/Icons/ViewIcon"; // Import the ViewIcon component

const GenericTable = (props) => {
  // console.log(props);
  return (
    <TableContainer>
      <THeads cols={props.cols} />
      <TBody
        cols={props.cols}
        rows={props.rows}
        actionMethods={props.actionMethods}
      />
    </TableContainer>
  );
};

export default GenericTable;

const TableContainer = (props) => {
  return (
    <table class="table table-striped-1" style={{ minWidth: "1000px" }}>
      {props.children}
    </table>
  );
};

const THeads = ({ cols }) => {
  let colRenders = cols.map((col) => {
    

    return (
      <th
        key={"col-" + col.id}
        className={` ${col.customClassName ? col.customClassName : ""}`}
        style={{fontSize:"0.9rem"}}
      >
        {col.label}
      </th>
    );
  });
  return (
    <thead>
      <tr>{colRenders}</tr>
    </thead>
  );
};

const TBody = ({ cols, rows, actionMethods }) => {
  let tableRows =
    rows &&
    rows.map((row, ri) => {
      let cellData = cols.map((col, ci) => {
        if (col.id === "tableActions") {
          let actions = [
            {
              name: "view",
              label: <ViewIcon />, 
              method: actionMethods?.onViewClick,
            },
          ];
          let finalActions = null;
          if (col.actionOpts)
            finalActions = actions
              .filter((ac) => col.actionOpts[ac.name])
              .map((ac) => {
                return (
                  <span
                    className="actionItems"
                    key={`row${ri}View`}
                    onClick={(e) => ac.method && ac.method(row)}
                  >
                    {ac.label}
                  </span>
                );
              });
          return <td>{finalActions}</td>;
        }
        return (
          <td key={`row${ri}col${ci}`}>
            {col.accessor ? col.accessor(row, col) : row[col.id]}
          </td>
        );
      });
      return <tr>{cellData}</tr>;
    });
  return <tbody>{tableRows}</tbody>;
};
