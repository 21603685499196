import React, { useEffect, useState } from "react";
import "./Profile.css";
import axios from "axios";
import { getUser } from "../../utils/helperFunctions";
import ProfileDetails from "./ProfileDetails";
import MemberSideTabs from "./MemberSideTabs";
import DashboardApplications from "../Dashboard/DashboardApplications/DashboardApplications";
import DashboardUsers from "../Dashboard/DashboardUsers/DashboardUsers";
import { apiCaller } from "../../utils/api";
import MemberApplications from "../Dashboard/DashboardApplications/MemberApplications";
import { useTranslation } from "react-i18next";
import WnDepotCollection from "../WnDepotCollection";
const Profile = ({ socket, storeNotification }) => {
  const { t, i18n } = useTranslation();
  const [applicationData, setApplicationData] = useState({});
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [applications, setApplications] = useState([]);

  const [collapseMenu, setCollapseMenu] = useState(false);
   const [WndepotData, setWnDepotData] = useState({});

  const user = getUser();

  const [selectedTab, setTab] = useState("self_profile");

  const getWnDepots = async () => {
    try {
      const response = await axios.get(`${apiUrl}/wn-depot-directories`, {
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
      });
      const data = response?.data?.data;
       console.log("data", data);
      setWnDepotData(data);
    } catch (error) {
      console.log(error);
   setWnDepotData(null);
    }
  };
  useEffect(() => {
    getWnDepots();
  }, []);

  const getProfile = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/applications?filters[email][$eq]=${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      setLoading(false);
      const data = response?.data?.data[0]?.attributes;
      setApplicationData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadTabAndData = (tabId) => {
    setTab(tabId);
    const methods = {
      search_organization: getApplications,
    };

    methods[tabId] && methods[tabId]();
  };
  const getApplications = async () => {
    let result = await apiCaller(
      "get",
      "applications?filters[Approved][$eq]=true"
    );
    if (result.err) console.log(result.err);
    else {
      console.log("getApplications");

      setApplications(result.data);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  const renderMethod = () => {
    const rMethods = {
      self_profile: (
        <ProfileDetails
          socket={socket}
          applicationData={applicationData}
          user={user}
        />
      ),
      search_organization: (
        // <DashboardApplications tableData={applications} user={user} noEdit />
        <MemberApplications
          storeNotification={storeNotification}
          tableData={applications}
          user={user}
          noEdit
        />
        // "fidsfhsdfhsdf"
      ),
     wnDepot_Collection: <WnDepotCollection WndepotData={WndepotData} />,  
    };

    if (!rMethods[selectedTab]) return null;
    return rMethods[selectedTab];
  };
  return (
    <div>
      <div>
        {applicationData?.Approved === true} ?
        <div className="dashboard_container">
          <div>
            <span
              style={{
                marginLeft: ".8rem",
                cursor: "pointer",
                background: "#034ea2",
                padding: "0.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                position: "fixed",
                bottom: "1rem",
                zIndex: 1,
              }}
              onClick={() => setCollapseMenu(false)}
            >
              <svg
                width="1rem"
                height="1rem"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                class="sc-bdvvtL sc-gSQFLo dCzDuG hRlPBd"
              >
                <path
                  d="M0 1.88 6.107 8 0 14.12 1.88 16l8-8-8-8L0 1.88Z"
                  fill="#fff"
                ></path>
              </svg>
            </span>
          </div>
          <MemberSideTabs
            selectedTab={selectedTab}
            setCollapseMenu={setCollapseMenu}
            collapseMenu={collapseMenu}
            setTab={loadTabAndData}
          />
          <div
            className="profile_table_container"
            style={{
              marginLeft: collapseMenu ? "0px" : "200px",
              width: collapseMenu
                ? "calc(100vw - 100px)"
                : "calc(100vw - 300px)",
            }}
          >
            {renderMethod()}
          </div>
          <div
            className="profile_table_container_mobile"
            style={{
              marginLeft: collapseMenu ? "0px" : "0px",
            }}
          >
            {renderMethod()}
          </div>
        </div>{" "}
        <div className="emp-profile wrapper">
          {!applicationData && <h1>{t("members.profile.not_view_profile")}</h1>}
          {loading && <h1>{t("members.profile.loading")}</h1>}
          {applicationData?.Approved === false && (
            <h1>{t("members.profile.profile_in_review")}</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
