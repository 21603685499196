import React from "react";

const ComAddress = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1598 13.3613H0.840332C0.689313 13.3613 0.566895 13.2388 0.566895 13.0878C0.566895 12.9368 0.689313 12.8144 0.840332 12.8144H13.1598C13.3108 12.8144 13.4332 12.9368 13.4332 13.0878C13.4332 13.2388 13.3108 13.3613 13.1598 13.3613Z"
        fill="white"
      />
      <path
        d="M9.27982 3.58342H5.66553C5.51451 3.58342 5.39209 3.461 5.39209 3.30998V1.99696C5.39209 1.84594 5.51451 1.72353 5.66553 1.72353H9.27982C9.43082 1.72353 9.55326 1.84594 9.55326 1.99696V3.30998C9.55326 3.461 9.43084 3.58342 9.27982 3.58342ZM5.93899 3.03655H9.00641V2.2704H5.93899V3.03655Z"
        fill="white"
      />
      <path
        d="M10.5564 13.3613H4.38818C4.23716 13.3613 4.11475 13.2388 4.11475 13.0878V3.30998C4.11475 3.15896 4.23716 3.03654 4.38818 3.03654H10.5564C10.7074 3.03654 10.8299 3.15896 10.8299 3.30998V6.12513C10.8299 6.27615 10.7074 6.39857 10.5564 6.39857C10.4054 6.39857 10.283 6.27615 10.283 6.12513V3.58342H4.66162V12.8144H10.5564C10.7074 12.8144 10.8299 12.9368 10.8299 13.0878C10.8299 13.2388 10.7074 13.3613 10.5564 13.3613Z"
        fill="white"
      />
      <path
        d="M4.38813 13.3613H2.03027C1.87925 13.3613 1.75684 13.2388 1.75684 13.0878V7.00642C1.75684 6.8554 1.87925 6.73299 2.03027 6.73299H4.38813C4.53914 6.73299 4.66156 6.8554 4.66156 7.00642V13.0878C4.66156 13.2389 4.53914 13.3613 4.38813 13.3613ZM2.30371 12.8144H4.11469V7.27986H2.30371V12.8144Z"
        fill="white"
      />
      <path
        d="M4.38773 7.27986H2.81738C2.66636 7.27986 2.54395 7.15744 2.54395 7.00642V5.61977C2.54395 5.46875 2.66636 5.34633 2.81738 5.34633H4.38773C4.53875 5.34633 4.66117 5.46875 4.66117 5.61977V7.00642C4.66117 7.15744 4.53875 7.27986 4.38773 7.27986ZM3.09079 6.73299H4.11427V5.8932H3.09079V6.73299Z"
        fill="white"
      />
      <path
        d="M8.12645 13.3613H6.81885C6.66783 13.3613 6.54541 13.2388 6.54541 13.0878V10.9145C6.54541 10.7635 6.66783 10.641 6.81885 10.641H8.12645C8.27744 10.641 8.39989 10.7635 8.39989 10.9145V13.0878C8.39989 13.2389 8.27744 13.3613 8.12645 13.3613ZM7.09229 12.8144H7.85299V11.1879H7.09229V12.8144Z"
        fill="white"
      />
      <path
        d="M10.5361 13.3613C10.4368 13.3613 10.3452 13.3074 10.297 13.2205C10.1431 12.9431 9.90509 12.5644 9.6295 12.1259C8.63653 10.546 7.87695 9.28164 7.87695 8.57833C7.87695 7.11161 9.06985 5.91839 10.5361 5.91839C12.0029 5.91839 13.1961 7.11164 13.1961 8.57833C13.1961 9.37467 12.2803 10.8153 11.4723 12.0863C11.1982 12.5174 10.9393 12.9247 10.7753 13.2205C10.7271 13.3074 10.6355 13.3613 10.5361 13.3613ZM10.5361 6.46524C9.3714 6.46524 8.42383 7.41316 8.42383 8.57831C8.42383 9.17995 9.42803 10.7776 10.0925 11.8349C10.2564 12.0956 10.4072 12.3355 10.5363 12.5479C10.6742 12.3222 10.8353 12.0689 11.0108 11.7929C11.7022 10.7052 12.6492 9.21552 12.6492 8.57831C12.6493 7.41316 11.7013 6.46524 10.5361 6.46524Z"
        fill="white"
      />
      <path
        d="M10.536 9.82761C9.79755 9.82761 9.19678 9.22652 9.19678 8.48763C9.19678 7.74919 9.79755 7.14845 10.536 7.14845C11.2748 7.14845 11.876 7.74919 11.876 8.48763C11.876 9.22652 11.2749 9.82761 10.536 9.82761ZM10.536 7.69535C10.0991 7.69535 9.74365 8.05076 9.74365 8.48766C9.74365 8.92497 10.0991 9.28077 10.536 9.28077C10.9733 9.28077 11.3291 8.92497 11.3291 8.48766C11.3291 8.05076 10.9733 7.69535 10.536 7.69535Z"
        fill="white"
      />
      <path
        d="M7.15563 6.60009C7.00461 6.60009 6.8822 6.47767 6.8822 6.32665V5.45217H6.06104V6.32665C6.06104 6.47767 5.93862 6.60009 5.7876 6.60009C5.63658 6.60009 5.51416 6.47767 5.51416 6.32665V5.17873C5.51416 5.02771 5.63658 4.9053 5.7876 4.9053H7.15563C7.30662 4.9053 7.42907 5.02771 7.42907 5.17873V6.32665C7.42907 6.47767 7.30665 6.60009 7.15563 6.60009Z"
        fill="white"
      />
      <path
        d="M7.15563 8.88122C7.00461 8.88122 6.8822 8.75877 6.8822 8.60778V7.73251H6.06104V8.60778C6.06104 8.75877 5.93862 8.88122 5.7876 8.88122C5.63658 8.88122 5.51416 8.75877 5.51416 8.60778V7.4591C5.51416 7.30808 5.63658 7.18566 5.7876 7.18566H7.15563C7.30662 7.18566 7.42907 7.30808 7.42907 7.4591V8.60778C7.42907 8.75877 7.30665 8.88122 7.15563 8.88122Z"
        fill="white"
      />
    </svg>
  );
};

export default ComAddress;
