import React from "react";
import { useForm } from "react-hook-form";
import "./CompareForm.css";

const industryClassificationOptions = [
  "Container Traders",
  "NVOECs",
  "Shipping Related companies including Depot operators",
  "Transport Operators",
  "IT Industry",
  "Trade Associations",
  "Direct Shippers",
  "Shipping Agencies",
  "Government Agencies",
  "Freight Forwarders",
];

const companyTypeOptions = [
  "Limited Liability Company",
  "Partnership",
  "Sole Proprietorship",
];

const CompareForm = ({ application, changes }) => {
  const methods = useForm({
    defaultValues: {
      industryClassification: application?.attributes?.industryClassification,
      companyType: application?.attributes?.companyType,
      nameOfApplicantCompany: application?.attributes?.nameOfApplicantCompany,
      addressOfMainOffice: application?.attributes?.addressOfMainOffice,
      correspondenceAddress: application?.attributes?.correspondenceAddress,
      telephoneNumber: application?.attributes?.telephoneNumber,
      fax: application?.attributes?.fax,
      office1: application?.attributes?.office1,
      emailAddressOfCompany: application?.attributes?.emailAddressOfCompany,
      companyInfo: application?.attributes?.companyInfo,
      websiteAddressOfCompany: application?.attributes?.websiteAddressOfCompany,
      staffStrengthOfCompany: application?.attributes?.staffStrengthOfCompany,
      contactPerson1: application?.attributes?.contactPerson1,
      designation1: application?.attributes?.designation1,
      emailAddress1: application?.attributes?.emailAddress1,
      mobile1: application?.attributes?.mobile1,
      contactPerson2: application?.attributes?.contactPerson2,
      designation2: application?.attributes?.designation2,
      emailAddress2: application?.attributes?.emailAddress2,
      logoOfCompany: application?.attributes?.logoOfCompany,
    },
  });
  return (
    <div>
      <form className="formWrapper">
        <div className="column">
          <div className="singleField">
            <label htmlFor={"Industry Classification"} className="form-label">
              {"Industry Classification"}{" "}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              defaultValue={""}
              style={{
                background:
                  changes &&
                  changes?.industryClassification?.new !==
                    changes?.industryClassification?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.industryClassification?.new !==
                    changes?.industryClassification?.old
                    ? "white"
                    : "",
                height: "2.5rem",
              }}
              {...methods.register("industryClassification")}
            >
              <option value="" hidden>
                Choose
              </option>
              {industryClassificationOptions?.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="singleField">
            <label htmlFor={"Company Type"} className="form-label">
              {"Company Type"}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <select
              style={{
                background:
                  changes &&
                  changes?.companyType?.new !== changes?.companyType?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.companyType?.new !== changes?.companyType?.old
                    ? "white"
                    : "",
                height: "2.5rem",
              }}
              className="form-select"
              aria-label="Default select example"
              {...methods.register("companyType")}
            >
              <option value="" hidden>
                Choose
              </option>
              {companyTypeOptions?.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
            <div>
              <label
                htmlFor={"Name of Applicant Company"}
                className="form-label"
              >
                {"Name of Applicant Company"}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                style={{
                  background:
                    changes &&
                    changes?.nameOfApplicantCompany?.new !==
                      changes?.nameOfApplicantCompany?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.nameOfApplicantCompany?.new !==
                      changes?.nameOfApplicantCompany?.old
                      ? "white"
                      : "",
                }}
                type={"text"}
                id={"Name of Applicant Company"}
                className="form-control"
                placeholder={"Name of Applicant Company"}
                {...methods.register("nameOfApplicantCompany")}
              />
            </div>
            <div>
              <label htmlFor={"Address of Main Office"} className="form-label">
                {"Address of Main Office"}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                style={{
                  background:
                    changes &&
                    changes?.addressOfMainOffice?.new !==
                      changes?.addressOfMainOffice?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.addressOfMainOffice?.new !==
                      changes?.addressOfMainOffice?.old
                      ? "white"
                      : "",
                }}
                id={"Address of Main Office"}
                className="form-control"
                placeholder={"Address of Main Office"}
                {...methods.register("addressOfMainOffice")}
              />
            </div>
            <div>
              <label
                htmlFor={"Correspondence Address (if differs from above)"}
                className="form-label"
              >
                {"Correspondence Address (if differs from above)"}{" "}
              </label>
              <input
                defaultValue={""}
                style={{
                  background:
                    changes &&
                    changes?.correspondenceAddress?.new !==
                      changes?.correspondenceAddress?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.correspondenceAddress?.new !==
                      changes?.correspondenceAddress?.old
                      ? "white"
                      : "",
                }}
                type={"text"}
                id={"Correspondence Address (if differs from above)"}
                className="form-control"
                placeholder={"Correspondence Address (if differs from above)"}
                {...methods.register("correspondenceAddress")}
              />
            </div>
            <div>
              <label htmlFor={"Telephone Number"} className="form-label">
                {"Telephone Number"}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                style={{
                  background:
                    changes &&
                    changes?.telephoneNumber?.new !==
                      changes?.telephoneNumber?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.telephoneNumber?.new !==
                      changes?.telephoneNumber?.old
                      ? "white"
                      : "",
                }}
                id={"Telephone Number"}
                className="form-control"
                placeholder={"Telephone Number"}
                {...methods.register("telephoneNumber")}
              />
            </div>
            <div>
              <label htmlFor={"Fax"} className="form-label">
                {"Fax"}{" "}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Fax"}
                style={{
                  background:
                    changes && changes?.fax?.new !== changes?.fax?.old
                      ? "green"
                      : "",
                  color:
                    changes && changes?.fax?.new !== changes?.fax?.old
                      ? "white"
                      : "",
                }}
                className="form-control"
                placeholder={"Fax"}
                {...methods.register("fax")}
              />
            </div>
            <div>
              <label
                htmlFor={"Email Address of Company"}
                className="form-label"
              >
                {"Email Address of Company"}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                style={{
                  background:
                    changes &&
                    changes?.emailAddressOfCompany?.new !==
                      changes?.emailAddressOfCompany?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.emailAddressOfCompany?.new !==
                      changes?.emailAddressOfCompany?.old
                      ? "white"
                      : "",
                }}
                type={"text"}
                id={"Email Address of Company"}
                className="form-control"
                placeholder={"Email Address of Company"}
                {...methods.register("emailAddressOfCompany")}
              />
            </div>
            <div>
              <label htmlFor={"Company Information"} className="form-label">
                {"Company Information"}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>

              <textarea
                // style={{ height: "500px" }}
                style={{
                  background:
                    changes &&
                    changes?.companyInfo?.new !== changes?.companyInfo?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.companyInfo?.new !== changes?.companyInfo?.old
                      ? "white"
                      : "",
                }}
                id={"Company Information"}
                className="form-control"
                placeholder={"Company Information"}
                {...methods.register("companyInfo")}
              />
            </div>
            <div>
              <label
                htmlFor={"Website Address of Company"}
                className="form-label"
              >
                {"Website Address of Company"}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Website Address of Company"}
                className="form-control"
                style={{
                  background:
                    changes &&
                    changes?.websiteAddressOfCompany?.new !==
                      changes?.websiteAddressOfCompany?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.websiteAddressOfCompany?.new !==
                      changes?.websiteAddressOfCompany?.old
                      ? "white"
                      : "",
                }}
                placeholder={"Website Address of Company"}
                {...methods.register("websiteAddressOfCompany")}
              />
            </div>
            <div>
              <label
                htmlFor={"Staff Strength of Company"}
                className="form-label"
              >
                {"Staff Strength of Company"}{" "}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                style={{
                  background:
                    changes &&
                    changes?.staffStrengthOfCompany?.new !==
                      changes?.staffStrengthOfCompany?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.staffStrengthOfCompany?.new !==
                      changes?.staffStrengthOfCompany?.old
                      ? "white"
                      : "",
                }}
                id={"Staff Strength of Company"}
                className="form-control"
                placeholder={"Staff Strength of Company"}
                {...methods.register("staffStrengthOfCompany")}
              />
            </div>
          </div>
        </div>
        <div
          className="column"
          style={{
            width: "50%",
          }}
        >
          <div>
            <label htmlFor={"Contact Person 1"} className="form-label">
              {"Contact Person 1"}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Contact Person 1"}
              className="form-control"
              placeholder={"Contact Person 1"}
              style={{
                background:
                  changes &&
                  changes?.contactPerson1?.new !== changes?.contactPerson1?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.contactPerson1?.new !== changes?.contactPerson1?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("contactPerson1")}
            />
          </div>
          <div>
            <label htmlFor={"Designation"} className="form-label">
              {"Designation"}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Designation"}
              className="form-control"
              placeholder={"Designation"}
              style={{
                background:
                  changes &&
                  changes?.designation1?.new !== changes?.designation1?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.designation1?.new !== changes?.designation1?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("designation1")}
            />
          </div>
          <div>
            <label htmlFor={"Email Address"} className="form-label">
              {"Email Address"}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Email Address"}
              className="form-control"
              placeholder={"Email Address"}
              style={{
                background:
                  changes &&
                  changes?.emailAddress1?.new !== changes?.emailAddress1?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.emailAddress1?.new !== changes?.emailAddress1?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("emailAddress1")}
            />
          </div>
          <div>
            <label htmlFor={"Office"} className="form-label">
              {"Office"}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Office"}
              className="form-control"
              placeholder={"Office"}
              style={{
                background:
                  changes && changes?.office1?.new !== changes?.office1?.old
                    ? "green"
                    : "",
                color:
                  changes && changes?.office1?.new !== changes?.office1?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("office1")}
            />
          </div>
          <div>
            <label htmlFor={"Mobile"} className="form-label">
              {"Mobile"}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Mobile"}
              className="form-control"
              placeholder={"Mobile"}
              style={{
                background:
                  changes && changes?.mobile1?.new !== changes?.mobile1?.old
                    ? "green"
                    : "",
                color:
                  changes && changes?.mobile1?.new !== changes?.mobile1?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("mobile1")}
            />
          </div>
          <div>
            <label htmlFor={"Contact Person 2"} className="form-label">
              {"Contact Person 2"}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Contact Person 2"}
              className="form-control"
              placeholder={"Contact Person 2"}
              style={{
                background:
                  changes &&
                  changes?.contactPerson2?.new !== changes?.contactPerson2?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.contactPerson2?.new !== changes?.contactPerson2?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("contactPerson2")}
            />
          </div>
          <div>
            <label htmlFor={"Designation"} className="form-label">
              {"Designation"}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Designation"}
              className="form-control"
              placeholder={"Designation"}
              style={{
                background:
                  changes &&
                  changes?.designation2?.new !== changes?.designation2?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.designation2?.new !== changes?.designation2?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("designation2")}
            />
          </div>
          <div>
            <label htmlFor={"Email Address"} className="form-label">
              {"Email Address"}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Email Address"}
              className="form-control"
              placeholder={"Email Address"}
              style={{
                background:
                  changes &&
                  changes?.emailAddress2?.new !== changes?.emailAddress2?.old
                    ? "green"
                    : "",
                color:
                  changes &&
                  changes?.emailAddress2?.new !== changes?.emailAddress2?.old
                    ? "white"
                    : "",
              }}
              {...methods.register("emailAddress2")}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div style={{ flexGrow: "1" }}>
              <label htmlFor={"Logo Of Company"} className="form-label">
                {"Logo Of Company"}{" "}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Logo Of Company"}
                className="form-control"
                placeholder={"Logo Of Company"}
                style={{
                  background:
                    changes &&
                    changes?.logoOfCompany?.new !== changes?.logoOfCompany?.old
                      ? "green"
                      : "",
                  color:
                    changes &&
                    changes?.logoOfCompany?.new !== changes?.logoOfCompany?.old
                      ? "white"
                      : "",
                }}
                {...methods.register("logoOfCompany")}
              />
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                marginTop: "20px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                }}
                src={application?.attributes?.logoOfCompany}
                alt=""
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompareForm;
