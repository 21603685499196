import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormComponent from "../../../Components/MembersArea/FormComponent";
import axios from "axios";
import { toast } from "react-toastify";
import { apiCaller } from "../../../utils/api";
import { getUser } from "../../../utils/helperFunctions";

const CreateAppAdmin = ({ userData, setCreateAppData, getApplications }) => {
  const { t, i18n } = useTranslation();
  const [listOfCountries, setListOfCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [listOfCities, setListOfCities] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectedCountryIso = listOfCountries.find(
    (country) => country.value === selectedCountry
  )?.iso;

  const companyDetailsSchema = [
    {
      id: 0,
      name: "industryClassification",
      label: t("membership.application.Industry_classification"),
      type: "checkbox",
      options: [
        "Container Traders",
        "NVOECs",
        "Shipping Related companies including Depot operators",
        "Transport Operators",
        "IT Industry",
        "Trade Associations",
        "Direct Shippers",
        "Shipping Agencies",
        "Government Agencies",
        "Freight Forwarders",
      ],
    },
    {
      id: 1,
      name: "companyType",
      label: t("membership.application.comp_type"),
      title: t("membership.application.details_applicant_company"),
      type: "checkbox",
      rules: {
        required: true,
      },
      options: [
        "Limited Liability Company",
        "Partnership",
        "Sole Proprietorship",
      ],
    },
    {
      id: 2,
      name: "nameOfApplicantCompany",
      label: t("membership.application.comp_name"),
      type: "text",
      rules: {
        required: true,
      },
    },
    {
      id: 3,
      name: "addressOfMainOffice",
      label: t("membership.application.comp_main_address"),
      type: "text",
      rules: {
        required: true,
      },
    },
    {
      id: 4,
      name: "billingAddress",
      label: t("membership.application.comp_billing_address"),
      type: "text",
      dynamic: {
        field: "sameAsMainAddress",
        value: true,
      },
    },
    {
      id: 5,
      name: "sameAsMainAddress",
      label: t("membership.application.same_as_main"),
      type: "radio",
      styles: {
        padding: "0 10px 0 0px",
      },
    },
    {
      id: 6,
      name: "correspondenceAddress",
      label: t("membership.application.comp_correspondence_address"),
      type: "text",
    },
    {
      id: 7,
      subTitle: t("membership.application.contact_details"),
      name: "telephoneNumber",
      label: t("membership.application.comp_telephone"),
      type: "text",
      rules: {
        required: true,
      },
    },
    {
      id: 8,
      name: "fax",
      label: t("membership.application.comp_fax"),
      type: "text",
    },
    {
      id: 9,
      name: "emailAddressOfCompany",
      label: t("membership.application.comp_email"),
      type: "email",
      rules: {
        required: true,
      },
    },
    {
      id: 10,
      name: "companyInfo",
      label: t("membership.application.comp_info"),
      type: "textarea",
      style: { height: "500px" },
      rules: {
        required: true,
      },
    },
    {
      id: 11,
      name: "websiteAddressOfCompany",
      label: t("membership.application.comp_website"),
      type: "text",
      rules: {
        required: true,
      },
    },
    {
      id: 12,
      name: "staffStrengthOfCompany",
      label: t("membership.application.comp_staff"),
      type: "text",
    },
    {
      id: 13,
      name: "contactPerson1",
      label: t("membership.application.contact_person_1"),
      type: "text",
      rules: {
        required: true,
      },
    },
    {
      id: 14,
      name: "designation1",
      label: t("membership.application.person_designation"),
      type: "text",
      rules: {
        required: true,
      },
    },
    {
      id: 15,
      name: "emailAddress1",
      label: t("membership.application.person_email_1"),
      type: "email",
      rules: {
        required: true,
      },
    },
    {
      id: 16,
      subTitle: "Contact Number",
      name: "office1",
      label: t("membership.application.person_office_1"),
      type: "text",
    },
    {
      id: 17,
      name: "mobile1",
      label: t("membership.application.person_mobile_1"),
      type: "text",
      rules: {
        required: true,
      },
    },
    {
      id: 18,
      name: "contactPerson2",
      label: t("membership.application.contact_person_2"),
      type: "text",
    },
    {
      id: 19,
      name: "designation2",
      label: t("membership.application.person_designation"),
      type: "text",
    },
    {
      id: 20,
      name: "emailAddress2",
      label: t("membership.application.person_email_2"),
      type: "email",
    },
    {
      id: 22,
      name: "areYouInvolvedInContainerTrading",
      label: t("membership.application.container_trading"),
      type: "textarea",
    },
    {
      id: 23,
      name: "doYouHaveSocContractWithShippingLine",
      label: t("membership.application.soc_contract"),
      type: "textarea",
    },
    {
      id: 24,
      name: "whichTradeLaneAreYouActiveInOfferingNVOEC",
      label: t("membership.application.offering_services"),
      type: "textarea",
      styles: { height: "100px" },
    },
  ];

  const currentCountry = (data) => {
    setSelectedCountry(data);
  };

  const [applicationData, setApplicationData] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;
  const uploadUrl = process.env.REACT_APP_IMG_URL;
  const [allFiles, setFiles] = useState([]);
  const user = getUser();

  const separateDataAndFiles = (data) => {
    const formData = {};
    const files = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        files[key] = value;
      } else {
        formData[key] = value;
      }
    });

    return { formData, files };
  };

  const extractFiles = (data) => {
    const extractedData = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        extractedData[key] = value[0];
      }
    });

    return extractedData;
  };

  const uploadFiles = async (files, name) => {
    const formData = {
      files: files,
    };

    try {
      const response = await axios.post(`${apiUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.jwt}`,
        },
      });
      //   const response = await apiCaller("post", "/upload", formData);
      // Handle the upload response here

      setFiles((prevArray) => [...prevArray, { [name]: response.data[0].url }]);
    } catch (error) {
      toast.error("File size is too large");
    }
  };

  const onSubmit = async (data) => {
    const { formData, files } = separateDataAndFiles(data);
    const extractedFiles = extractFiles(files);

    let newFiles = {};

    allFiles.forEach((item) => {
      newFiles = {
        ...newFiles,
        ...item,
      };
    });

    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/applications`,
        {
          data: {
            ...data,
            username: userData.username,
            email: userData.email,
            name: userData.name,
            country: userData?.country,
            companyRegistrationCertificate: `${uploadUrl}${newFiles?.companyRegistrationCertificate}`,
            idOfTheBeneficialOwner: `${uploadUrl}${newFiles?.idOfTheBeneficialOwner}`,
            financialStatement: `${uploadUrl}${newFiles?.financialStatement}`,
            bankStatement: `${uploadUrl}${newFiles?.bankStatement}`,
            otherDocument: `${uploadUrl}${newFiles?.otherDocument}`,
            logoOfCompany: `${uploadUrl}${newFiles?.logoOfCompany}`,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      if (response.status === 200) {
        getApplications();

        toast.info(
          `${userData.name},${t(
            "membership.application.application_submitted_1"
          )}`
        );
        setLoading(false);
        setCreateAppData(null);
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/applications?filters[email][$eq]=${userData.email}`,
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      const data = response.data.data[0];
      setApplicationData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div
      className="main_area"
      style={{
        padding: " 0 5rem 0 3rem",
      }}
    >
      <div>
        <p>
          You are creating application for the user{" "}
          <strong>
            <span
              style={{
                marginRight: ".4rem",
              }}
            >
              {userData.name}
            </span>
            - <u>{userData.email}</u>
          </strong>
        </p>
        <div className="form_wrapper">
          <h3
            style={{
              fontSize: "1.4rem",
              fontWeight: "bold",
              margin: "1.5rem 0",
            }}
          >
            {t("membership.application.type_membership")}
          </h3>
          <h5 style={{ marginBottom: "1rem" }}>
            {t("membership.application.enroll_membership")}
          </h5>
          <FormComponent
            schema={companyDetailsSchema}
            onSubmit={onSubmit}
            loading={loading}
            watchFields={[
              "memberOfOtherNetworks",
              "country",
              "sameAsMainAddress",
            ]}
            currentCountry={currentCountry}
            customInputs={
              <div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    {t("membership.application.business_certificate")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="companyRegistrationCertificate"
                    required
                    onChange={(e) =>
                      uploadFiles(e.target.files[0], e.target.name)
                    }
                    id="exampleFormControlInput1"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    {t("membership.application.comp_owner_id")}
                  </label>
                  <input
                    type="file"
                    name="idOfTheBeneficialOwner"
                    className="form-control"
                    onChange={(e) =>
                      uploadFiles(e.target.files[0], e.target.name)
                    }
                    id="exampleFormControlInput1"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label"
                  >
                    {t("membership.application.logo_compony")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="logoOfCompany"
                    className="form-control"
                    required
                    onChange={(e) =>
                      uploadFiles(e.target.files[0], e.target.name)
                    }
                    id="exampleFormControlInput3"
                  />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAppAdmin;
