import React from "react";
import "./ValueHome.css";
import luxehomeimage1 from "../../Assets/Assets/ValueHome-image-2.png";
import luxehomeimage2 from "../../Assets/Assets/ValueHome-image-3.png";
import luxehomeimage3 from "../../Assets/Assets/ValueHome-image-4.png";
import luxehomeimage4 from "../../Assets/Assets/ValueHome-image-5.png";
import luxehomeimage5 from "../../Assets/SVG/FunctionalDesign.svg";
import luxehomeimage6 from "../../Assets/SVG/Affordability.svg";
import luxehomeimage7 from "../../Assets/SVG/ModernAmenities.svg";
import luxehomeimage8 from "../../Assets/SVG/DurableConstruction.svg";
import luxehomeimage9 from "../../Assets/SVG/CommunityFeatures.svg";
import luxehomeimage10 from "../../Assets/SVG/LowMaintenance.svg";

import { useTranslation } from "react-i18next";
import HomeContact from "./HomeContact";

const ValueHome = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* section 1 */}
      <div className=" container-fluid main-luxeHome">
        <div className="row">
          <div className="col-md-12">
            <div className="ValueHome-section-1">
              <div className="luxeHome-content-1">
                <h2 className="luxeHome-heading-1">
                  {t("ValuehomePage.Valuehome")}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 2 */}
      <div className="luxeHome-section-2">
        <div className=" container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="luxeHome-heading-2">
                {t("ValuehomePage.Valuehome")}
              </h2>
              <p className="luxeHome-para-2">
                {t("ValuehomePage.Valuehome-2")}
              </p>
              <h2 className="luxeHome-heading-3">
                {t("ValuehomePage.Valuehome-3")}
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-4 luxehomekey">
              <img
                src={luxehomeimage5}
                alt="Value Home"
                className="img-fluid"
              />
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-4")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-5")}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 luxehomekey">
              <img
                src={luxehomeimage6}
                alt="Value Home"
                className="img-fluid"
              />
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-6")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-7")}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 luxehomekey">
              <img
                src={luxehomeimage7}
                alt="Value Home"
                className="img-fluid"
              />
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-8")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-9")}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 luxehomekey">
              <img
                src={luxehomeimage8}
                alt="Value Home"
                className="img-fluid"
              />
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-10")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-11")}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 luxehomekey">
              <img
                src={luxehomeimage9}
                alt="Value Home"
                className="img-fluid"
              />
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-12")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-13")}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 luxehomekey">
              <img
                src={luxehomeimage10}
                alt="Value Home"
                className="img-fluid"
              />
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-23")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-24")}
              </p>
            </div>
            {/* <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage11} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("ValuehomePage.Valuehome-25")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("ValuehomePage.Valuehome-26")}
                            </p>
                        </div> */}
          </div>

          <div className="row">
            <div className="col-md-12">
              <h2 className="luxeHome-heading-4">
                {t("ValuehomePage.Valuehome-14")}
              </h2>
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-15")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-16")}
              </p>
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-17")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-18")}
              </p>
              <p className="luxeHome-para-3">
                {t("ValuehomePage.Valuehome-19")}
              </p>
              <p className="luxeHome-para-4">
                {t("ValuehomePage.Valuehome-20")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="luxeHome-section-2">
        <div className=" container">
          <div className="row">
            <h2 className="luxeHome-heading-5">
              <span className="luxe-span">
                {" "}
                {t("ValuehomePage.Valuehome-21")}{" "}
              </span>{" "}
              {t("ValuehomePage.Valuehome-22")}
            </h2>
            <div className="col-md-7">
              <div className="luxeHome-gallery mb-4">
                <img src={luxehomeimage1} alt="Value Home" className=" mb-4" />
                <img
                  src={luxehomeimage2}
                  alt="Value Home"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="luxeHome-gallery mb-5">
                <img src={luxehomeimage3} alt="Value Home" className="mb-4" />
                <img
                  src={luxehomeimage4}
                  alt="Value Home"
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
        </div>
        <HomeContact />
      </div>
    </>
  );
};

export default ValueHome;
