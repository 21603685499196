import React from "react";
import "./Team.css";
import eveImage from "../../Assets/Assets/Group 30.png";
import eveImage2 from "../../Assets/Assets/Group 31.png";
import talhaImage from "../../Assets/Assets/Talha-Moosani.jpg";
import { useTranslation } from "react-i18next";

const teamData = [
  {
    id: 1,
    name: "Eve Lee",
    title: "Director of Membership Relations",
    image: eveImage,
  },
  
  // {
  //   id: 3,
  //   name: "Talha Moosani",
  //   title: " Chief Tech Officer",
  //   image: talhaImage,
  // },
];

const Team = () => {
  const { t } = useTranslation();

  return (
    <div className="container py-5">
      <div className="top_section">
        <h3 className="top_title">{t("team.heading")}</h3>
        <p>{t("team.para")}</p>
      </div>
      <div className="bottom_section">
        {teamData?.map((item) => {
          return (
            <div key={item?.id} className="card_wrapper">
              <div className="img_wrapper">
                <img src={item?.image} alt="Profile Image" />
              </div>
              <div className="info">
                <span>{item?.name}</span>
                <p>{item?.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
