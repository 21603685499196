export const storeUser = (data) => {
  // console.log("data", data);
  localStorage.setItem(
    "userData",
    JSON.stringify({
      userName: data.user.username,
      name: data.user.name,
      email: data.user.email,
      city: data.user.city,
      referMemberId: data?.user?.referMemberId,
      country: data.user.country,
      jwt: data.jwt,
      "member-manager": data.user["member-manager"],
    })
  );
};

export const getUser = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  return user;
};

export const logoutUser = () => {
  localStorage.removeItem("userData");
};
