import React, { useEffect, useState } from "react";
import "./MemberProfiles.css";
import axios from "axios";
import { getUser } from "../../utils/helperFunctions";
import { Link } from "react-router-dom";

const MemberProfiles = () => {
  const [applicationData, setApplicationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const user = getUser();

  const getAllProfile = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/applications`, {
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
      });
      setLoading(false);
      const data = response;
      setApplicationData(data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProfile();
  }, []);

  return (
    <div className="container wrapper">
      {loading && <h1>Loading...</h1>}
      {applicationData?.map((data) => {
        return (
          <h1>
            <Link to={`/member-profiles/${data?.attributes?.username}`}>
              {data?.attributes?.username}
            </Link>
          </h1>
        );
      })}
    </div>
  );
};

export default MemberProfiles;
