import React from 'react'

const ReviewIcon = () => {
  return (
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.8968 3.1032C20.4468 2.65327 19.8364 2.40051 19.2 2.40051C18.5636 2.40051 17.9533 2.65327 17.5032 3.1032L8.40002 12.2064V15.6H11.7936L20.8968 6.4968C21.3468 6.04673 21.5995 5.43639 21.5995 4.8C21.5995 4.16361 21.3468 3.55327 20.8968 3.1032Z" fill="black" fill-opacity="0.6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.40002 7.2C2.40002 6.56348 2.65288 5.95303 3.10297 5.50295C3.55306 5.05286 4.1635 4.8 4.80002 4.8H9.60002C9.91828 4.8 10.2235 4.92643 10.4486 5.15148C10.6736 5.37652 10.8 5.68174 10.8 6C10.8 6.31826 10.6736 6.62349 10.4486 6.84853C10.2235 7.07357 9.91828 7.2 9.60002 7.2H4.80002V19.2H16.8V14.4C16.8 14.0817 16.9265 13.7765 17.1515 13.5515C17.3765 13.3264 17.6818 13.2 18 13.2C18.3183 13.2 18.6235 13.3264 18.8486 13.5515C19.0736 13.7765 19.2 14.0817 19.2 14.4V19.2C19.2 19.8365 18.9472 20.447 18.4971 20.8971C18.047 21.3471 17.4365 21.6 16.8 21.6H4.80002C4.1635 21.6 3.55306 21.3471 3.10297 20.8971C2.65288 20.447 2.40002 19.8365 2.40002 19.2V7.2Z" fill="black" fill-opacity="0.6"/>
</svg>
    </div>
  )
}

export default ReviewIcon