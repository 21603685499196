import React, { useEffect } from "react";
import "../Assets/Signup.css";
import { useState } from "react";

import { FormProvider, set, useForm } from "react-hook-form";
import Login from "./Login";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const SignUP = ({ setUser, socket, newNotification, storeNotification }) => {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [listOfCountries, setListOfCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [listOfStates, setListOfStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [listOfCities, setListOfCities] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
   const [referred, setReffered] = useState("No");
  const [loading, setLoading] = useState(false);

 const ReferCheck = () => {
    if (referred === "Yes") {
      setReffered("No");
    } else {
      setReffered("Yes");
    }
  };

  const selectedCountryIso = listOfCountries.find(
    (country) => country.value === selectedCountry
  )?.iso;

  const selectedStateIso = listOfStates.find(
    (state) => state.value === selectedState
  )?.iso;

  useEffect(() => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
    );

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data?.map((item) => ({
            value: item.name,
            iso: item.iso2,
          }))
        )
      )
      .catch((error) => {
        // console.log("error", error)
      });
  }, []);
  useEffect(() => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
    );

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      `https://api.countrystatecity.in/v1/countries/${selectedCountryIso}/states`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) =>
        setListOfStates(
          data?.map((item) => ({
            value: item.name,
            iso: item.iso2,
          }))
        )
      )
      .catch((error) => {
        // console.log("error", error)
      });
  }, [selectedCountry, selectedCountryIso]);

  useEffect(() => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
    );

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      `https://api.countrystatecity.in/v1/countries/${selectedCountryIso}/states/${selectedStateIso}/cities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) =>
        setListOfCities(
          data?.map((item) => ({
            value: item.name,
          }))
        )
      )
      .catch((error) => {
        // console.log("error", error)
      });
  }, [selectedCountry, selectedStateIso]);

  const methods = useForm({
    defaultValues: {
      username: "",
      name: "",
      email: "",
      countryCode: "+",
      phoneNumber: "",
      country: "",
      state: "",
      city: "",
      website: "",
      password: "",
      confirmPassword: "",
       referMemberId: "",
    },
  });

  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   socket?.emit("sendNotification", {
  //     // senderEmail: response?.data?.user?.email,
  //     // receiverEmail: "waleed.a@blurredego.com",
  //     // message: "A new user just signed up for WNVOEC",
  //     // subject: "New User",
  //     newNotification,
  //   });
  // }, [socket, newNotification]);

  const onSubmit = async (data) => {
       if (referred === "No") data.referMemberId = "";
    if (data?.password !== data?.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    data.username = data.email;
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/auth/local/register`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setError(null);
      methods.reset();
      setLoading(false);
      toast.info("Verification Email Sent");
      storeNotification(
        response?.data?.user?.email,
        "admin",
        "User created",
        `User ${response?.data?.user?.name} with email ${response?.data?.user?.email} has created an account and verified `
      );
      setShowPopup(true);
      // console.log(response);
    } catch (error) {
      // setError(error.response.data.error.message);
      toast.error(error.response.data.error.message);
      setLoading(false);
    }
  };

  return (
    <div className="mainContainer">
      {showPopup && (
        <div className="popup_container">
          <div className="popup">
            <p className="">
              <b>{t("signup.thank_signup")}</b>
              <br />
              <br />
              {t("signup.verifi_email")}
            </p>

            <div style={{ marginBottom: "1rem !important" }}>
              <button
                style={{ marginTop: "5rem" }}
                className="popup_btn "
                onClick={() => setShowPopup(false)}
              >
                {t("signup.ok")}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="transparentBackground form-wrapper">
        <ul class="nav nav-tabs">
          <li class="nav-item" onClick={() => setIsLogin(false)}>
            <div
              className={`nav-link ${isLogin ? "" : "active"}`}
              aria-current="page"
            >
              {t("signup.signup")}
            </div>
          </li>
          <li className={`nav-item`} onClick={() => setIsLogin(true)}>
            <div
              className={`nav-link ${isLogin ? "active" : ""}`}
              aria-current="page"
            >
              {t("signup.login")}
            </div>
          </li>
        </ul>
        {isLogin ? (
          <Login setError={setError} setUser={setUser} />
        ) : (
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="form"
            style={{
              padding: "2rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <h4 className="formHeading">{t("signup.become_member")}</h4>

            <div className="country-city">
              {/* <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Username"
                  name="username"
                  {...methods.register("username", { required: true })}
                  style={{ width: "14.5rem" }}
                />
                <label htmlFor="floatingInput">Username</label>
              </div> */}

              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Name"
                  name="name"
                  {...methods.register("name", { required: true })}
                  // style={{ width: "14.5rem" }}
                />
                <label htmlFor="floatingInput">{t("signup.name")}</label>
              </div>
            </div>

            <div className="form-floating ">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
                {...methods.register("email", { required: true })}
              />
              <label htmlFor="floatingInput">{t("signup.email")}</label>
            </div>

            <div className="country-city">
              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Country Code"
                  maxLength={4}
                  name="countryCode"
                  {...methods.register("countryCode", {
                    required: true,
                    maxLength: 4,
                  })}
                  style={{ width: "8rem" }}
                />
                <label htmlFor="floatingInput">
                  {t("signup.country_code")}
                </label>
              </div>

              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  style={{ width: "21rem" }}
                  {...methods.register("phoneNumber", { required: true })}
                />
                <label htmlFor="floatingInput">{t("signup.number")}</label>
              </div>
            </div>

            <div className="country-city-1">
              <div className="form-floating ">
                {/* <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Country"
                  name="country"
                  list="Country"
                  autocomplete="off"
                  {...methods.register("country", { required: true })}
                  style={{ width: "9.3rem" }}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                />
                <label htmlFor="floatingInput">{t("signup.country")}</label>
                <datalist id="Country">
                  {listOfCountries?.map((item) => {
                    return <option value={item.value} />;
                  })}
                </datalist> */}

                <select
                  className="form-control"
                  id="floatingInput"
                  name="country"
                  {...methods.register("country", { required: true })}
                  style={{ width: "9rem" }}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <option value="" disabled>
                    {t("signup.country")}
                  </option>
                  {listOfCountries?.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-floating ">
                {/* <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="State"
                  name="state"
                  list="State"
                  autocomplete="off"
                  {...methods.register("state", { required: true })}
                  style={{ width: "9.3rem" }}
                  onChange={(e) => setSelectedState(e.target.value)}
                />
                <label htmlFor="floatingInput">{t("signup.state")}</label>
                <datalist id="State">
                  {listOfStates?.map((item) => {
                    return <option value={item.value} />;
                  })}
                </datalist> */}

                <select
                  className="form-control"
                  id="floatingInput"
                  name="state"
                  {...methods.register("state", { required: true })}
                  style={{ width: "9rem" }}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="" disabled>
                    {t("signup.state")}
                  </option>
                  {listOfStates?.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-floating city-input">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  autocomplete="off"
                  placeholder="City"
                  name="city"
                  list="City"
                  {...methods.register("city", { required: true })}
                  style={{
                    width: "9rem",
                    marginRight: "1rem",
                  }}
                />
                <label
                  htmlFor="floatingInput"
                  style={{
                    paddingLeft: "1.5rem",
                  }}
                >
                  {t("signup.city")}
                </label>
                <datalist id="City">
                  {listOfCities?.map((item) => {
                    return <option value={item.value} />;
                  })}
                </datalist>

                {/* <select
                  className="form-control"
                  id="floatingInput"
                  name="city"
                  {...methods.register("city", { required: true })}
                  style={{ width: "9rem" }}
                  // onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="" disabled>
                    {t("signup.city")}
                  </option>
                  {listOfCities?.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </select> */}
              </div>
            </div>

            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingPassword"
                placeholder="Website"
                name="website"
                {...methods.register("website", { required: true })}
              />
              <label htmlFor="floatingPassword">{t("signup.website")}</label>
            </div>

            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                name="password"
                {...methods.register("password", { required: true })}
              />
              <label htmlFor="floatingPassword">{t("signup.password")}</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Confirm Password"
                name="confirmPassword"
                {...methods.register("confirmPassword", { required: true })}
              />
              <label htmlFor="floatingPassword">
                {t("signup.confirm_password")}
              </label>
            </div>
         
            <div className="signup-checkBox">
              <input
                className="checkbox-input"
                type="checkbox"
                onClick={ReferCheck}
              />
              <label className="checkbox-label">
                Are you Referred by WNVOEC member ?
              </label>
            </div>
            {referred === "Yes" && (
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Member ID"
                  name="referMemberId"
                  {...methods.register("referMemberId")}
                  // style={{ width: "14.5rem" }}
                />
                <label htmlFor="floatingInput">
                  {/* {t("signup.referMemberId")} */}
                   Referring Member ID
                </label>
              </div>
            )}

            <button
              className="btn btn-purple small-btn btn-md py-2"
              disabled={loading}
              style={{
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("signup.signup")}
            </button>
          </form>
        )}
        <p className="authentication-error">{error}</p>
      </div>
      <div className="content ">
        <p>{t("signup.join")}</p>
      </div>
    </div>
  );
};

export default SignUP;
