import axios from "axios";
import { getUser } from "./helperFunctions";

export const apiCaller = async (method, endpoint, body, headers) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = getUser();
  let additionalHeaders = headers ? headers : {};
  const header = {
    headers: {
      Authorization: `Bearer ${user.jwt}`,
      ...additionalHeaders,
    },
  };
  try {
    const response = await axios[method](
      `${apiUrl}/${endpoint}`,
      body ? body : header,
      body ? header : null
    );
    return response.data;
  } catch (error) {
    return { err: error };
  }
};
