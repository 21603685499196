import axios from "axios";
import "./HomeContact.css";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import phone from "../../Assets/SVG/bxs_phone-call.svg";
import mail from "../../Assets/SVG/ic_sharp-email.svg";
import ellipse1 from "../../Assets/Png/Ellipse 793.png";
import ellipse2 from "../../Assets/Png/Ellipse 794.png";
import down_arrow from "../../Assets/SVG/down-filled-triangular-arrow 1.svg";
import { Helmet } from "react-helmet-async";
import location from "../../Assets/SVG/carbon_location-filled.svg";

const HomeContact = () => {
    const { t } = useTranslation();
    const [listOfCountries, setListOfCountries] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const methods = useForm({
        defaultValues: {
            memberId: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            companyName: "",
            enquiry: "",
        },
    });

    useEffect(() => {
        const headers = new Headers();
        headers.append(
            "X-CSCAPI-KEY",
            "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
        );

        const requestOptions = {
            method: "GET",
            headers: headers,
            redirect: "follow",
        };

        fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
            .then((response) => response.json())
            .then((data) =>
                setListOfCountries(
                    data?.map((item) => ({
                        value: item.name,
                        iso: item.iso2,
                    }))
                )
            )
            .catch((error) => {
                // console.log("error", error)
            });
    }, []);

    const onSubmit = async (data) => {
        // console.log("data",data)
        try {
            const response = await axios.post(
                `${apiUrl}/wn-homes-contacts`,
                {
                    data: data,
                }
                // {
                //   headers: {
                //     "Content-Type": "application/json",
                //     Authorization: `Bearer ${user.jwt}`,
                //   },
                // }
            );
            if (response.status === 200) {
                toast.success("Message Sent Successfully");
                methods.reset();
            }
        } catch (error) {
            // console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Join Freight Logistics and Global Shipping Network</title>
                <meta
                    name="description"
                    content="Freight logistics and global shipping network, WNVOEC, offers membership to industry stakeholders to promote international trade and cooperation within the shipping community worldwide."
                />
            </Helmet>
            <div className="homecontact-main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1"> </div>
                        <div className="col-lg-10 col-md-12">
                            <div className="homecontact-headingBox">
                                <h2 className="contactUs-mainHeading">{t("contact.heading1")}</h2>
                                <p className="contactUs-para">{t("wnhomePage.wnhome-14")}</p>
                            </div>
                            <div className="contactUs-container">
                                <div className="homecontact-form-container">
                                    <form
                                        className="contactUs-form"
                                        onSubmit={methods.handleSubmit(onSubmit)}
                                    >
                                        <div className="contactUs-form-gridbox">
                                            <div className="contactUs-form-fieldBox">
                                                <p className="input-name-text">{t("contact.para2")}</p>
                                                <input
                                                    type="text"
                                                    className="input-field"
                                                    {...methods.register("firstName", { required: true })}
                                                />
                                            </div>
                                            <div className="contactUs-form-fieldBox">
                                                <p className="input-name-text">{t("contact.para3")}</p>
                                                <input
                                                    type="text"
                                                    className="input-field"
                                                    {...methods.register("lastName", { required: true })}
                                                />
                                            </div>
                                            <div className="contactUs-form-fieldBox">
                                                <p className="input-name-text">{t("contact.para4")}</p>
                                                <input
                                                    type="text"
                                                    className="input-field"
                                                    {...methods.register("email", { required: true })}
                                                />
                                            </div>
                                            <div className="contactUs-form-fieldBox">
                                                <p className="input-name-text">{t("contact.para5")}</p>
                                                <input
                                                    type="text"
                                                    className="input-field"
                                                    {...methods.register("phone", { required: true })}
                                                />
                                            </div>
                                            <div className="contactUs-form-fieldBox">
                                                <p className="input-name-text">{t("wnhomePage.wnhome-15")}</p>
                                                <input
                                                    type="text"
                                                    className="input-field"
                                                    {...methods.register("companyName", { required: true })}
                                                />
                                            </div>
                                            <div className="contactUs-form-fieldBox">
                                                <p className="input-name-text">{t("wnhomePage.wnhome-16")}</p>
                                                <input
                                                    type="text"
                                                    className="input-field"
                                                    {...methods.register("memberId")}
                                                />
                                            </div>
                                            <div className="contactUs-form-fieldBox contactUs-form-fieldBox-message">
                                                <p className="input-name-text">{t("contact.para8")}</p>
                                                <input
                                                    type="text"
                                                    className="input-field"
                                                    placeholder="Write your message.."
                                                    {...methods.register("enquiry", { required: true })}
                                                />
                                            </div>
                                        </div>
                                        <button className="homecontact-btn">{t("contact.para8")}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"> </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default HomeContact;
