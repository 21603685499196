import React, { useEffect, useState } from "react";
import "../Components/MembersArea/Members.css";
import AboutTabs from "../Components/AboutTabs";
import BenefitsContent from "../Components/MembersArea/BenefitsContent";
import MemberApplication from "../Components/MembersArea/MemberApplication";
import ProcessContent from "../Components/MembersArea/Process/ProcessContent";
import Fee from "../Components/MembersArea/Fee/Fee";
import axios from "axios";
import MemberProfile from "./MemberProfiles/MemberProfile";
import Profile from "./Profile/Profile";
import PayScreen from "../Components/MembersArea/PayScreen";
import { useTranslation } from "react-i18next";
const Members = ({ user, storeNotification }) => {
  const { t, i18n } = useTranslation();
  const [invoiceData, setInvoiceData] = useState({});
  const [applicationData, setApplicationData] = useState({});

  const tabs = [
    {
      id: 1,
      name: t("membership.benefits.benefits"),
      content: <BenefitsContent />,
    },
    {
      id: 2,
      name: t("membership.process.process"),
      content: <ProcessContent />,
    },
    {
      id: 3,
      name: t("membership.fees.fees"),
      content: <Fee />,
    },
    {
      id: 4,
      name: t("membership.application.application"),
      content: (
        <MemberApplication
          user={user}
          invoiceData={invoiceData}
          storeNotification={storeNotification}
        />
      ),
      // style: {
      //   display: invoiceData?.attributes?.received === true ? "none" : "block",
      // },
    },
    // {
    //   id: 5,
    //   name: "PROFILE",
    //   content: <h1>Profile</h1>,
    //   style: {
    //     display: invoiceData?.attributes?.received === true ? "block" : "none",
    //   },
    // },
  ];
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentTab, setCurrentTab] = useState(1);

  const getInvoiceData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/invoices?filters[email][$eq]=${user.email}&populate=*`,
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      setInvoiceData(response.data.data[0]);
    } catch (error) {
      console.log(null);
    }
  };

  const getUserData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/applications?filters[email][$eq]=${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      const data = response.data.data[0];
      setApplicationData(data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getInvoiceData();
    getUserData();
  }, []);

  return (
    <div>
      {/* <div className="banner_image"></div> */}
      {invoiceData?.attributes?.received === true ||
      applicationData?.attributes?.directAccess === true ? null : (
        <div className="main__title">
          {applicationData?.attributes?.Approved === true ? (
            <h1> {t("membership.download_invoice")}</h1>
          ) : (
            <h1>
              {t("membership.membership")}{" "}
              {tabs.map((tab) => {
                if (tab.id === currentTab) {
                  return tab.name;
                }
              })}
            </h1>
          )}
        </div>
      )}

      {/* {applicationData?.attributes?.Approved == true ? (
        <PayScreen invoiceData={invoiceData} />
      ) : (
        <div className="tabs">
          <TabsComponent tabs={tabs} setCurrentTab={setCurrentTab} />
        </div>
      )} */}

      {applicationData?.attributes?.Approved === true &&
        invoiceData?.attributes?.received === false &&
        applicationData?.attributes?.directAccess === false && (
          <PayScreen invoiceData={invoiceData} />
        )}

      {!applicationData && (
        <div className="tabs">
          <AboutTabs tabs={tabs} setCurrentTab={setCurrentTab} />
        </div>
      )}

      {invoiceData?.attributes?.received === true &&
        applicationData?.attributes?.Approved === true &&
        applicationData?.attributes?.directAccess === false && (
          <Profile storeNotification={storeNotification} />
        )}
      {applicationData?.attributes?.directAccess === true && (
        <Profile storeNotification={storeNotification} />
      )}

      {/* {invoiceData?.attributes?.received === true ? (
        <Profile />
      ) : (
        <div className="tabs">
          <TabsComponent tabs={tabs} setCurrentTab={setCurrentTab} />
        </div>
      )} */}
    </div>
  );
};

export default Members;
