import axios from "axios";
import React, { useEffect, useState } from "react";
import socketClient from "socket.io-client";
import { apiCaller } from "../../utils/api";

const SERVER = "http://localhost:1337";

const messages = [
  {
    sender: "Waleed",
    receiver: "Ali",
    message: "Hello",
    email: "waleed.a@blurredego.com",
  },
  {
    sender: "Ali",
    receiver: "Waleed",
    message: "Hi",
    email: "waleed.a@blurredego.com",
  },
  {
    sender: "Waleed",
    receiver: "Ali",
    message: "How are you?",
    email: "waleed.a@blurredego.com",
  },
  {
    sender: "Ali",
    receiver: "Waleed",
    message: "I am fine",
    email: "waleed.a@blurredego.com",
  },
  {
    sender: "Waleed",
    receiver: "Ahsan",
    message: "Hello Ahsan",
    email: "waleed.a@blurredego.com",
  },
  {
    sender: "Ahsan",
    receiver: "Waleed",
    message: "Hi Waleed",
    email: "waleed.a@blurredego.com",
  },
];

const SendingMessage = ({ user }) => {
  const socket = socketClient(SERVER);

  const [message, setMessage] = useState("");

  const sender = user.name;
  const email = user.email;
  const receiver = "Hello";

  const sendMessage = (message) => {
    if (message) {
      socket.emit(
        "sendMessage",
        { message, sender, receiver, email },
        (error) => {
          if (error) return alert(error);
        }
      );
    } else {
      alert("Please enter a message");
    }
  };

  const gettingMessages = async () => {
    const response = await apiCaller("get", "messages");
    console.log(response);
  };

  useEffect(() => {
    socket.emit("join", { sender }, (error) => {
      if (error) return alert(error);
    });
    socket.on("message", (message) => {
      console.log(message);
    });
  }, [sendMessage]);

  const messageHandler = () => {
    sendMessage(message);
    setMessage("");
  };

  return (
    <div>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Enter message"
      />
      <button onClick={messageHandler}>Send</button>
    </div>
  );
};

export default SendingMessage;
