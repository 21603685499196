import React, { useState } from "react";
import "./notifications.css";
import { apiCaller } from "../../utils/api";
import { getUser } from "../../utils/helperFunctions";
const Notifications = ({
  handleShowNotifications,
  notifications,
  getAllNotifications,
}) => {
  const user = getUser();
  const markAsRead = async (notification) => {
    try {
      const updatedNotification = {
        data: {
          isRead: true,
        },
      };
      const response = await apiCaller(
        "put",
        `notifications/${notification.id}`,
        updatedNotification
      );
      if (response) {
        console.log("Notification marked as read");
        getAllNotifications(user);
      } else {
        console.error("Failed to mark notification as read");
      }
    } catch (error) {
      console.error("Failed to mark notification as read:",null);
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      const response = await apiCaller(
        "delete",
        `notifications/${notificationId}`
      );
      if (response) {
        getAllNotifications(user);
      }
    } catch (error) {
      console.error("Failed to delete notification:", error);
    }
  };

  const lastTwoNotifications = notifications;
  return (
    <div className="notifications-panel">
      <div className="panel-header">
        <h4>Notifications</h4>
        <button className="close-btn" onClick={handleShowNotifications}>
          &times;
        </button>
      </div>
      <ul className="notification-list">
        {lastTwoNotifications && lastTwoNotifications.length === 0 ? (
          <p>There are no notifications to show.</p>
        ) : (
          lastTwoNotifications?.map((notification) => {
            const dateTimeString = notification?.attributes?.createdAt;
            const dateObject = new Date(dateTimeString);
            const date = dateObject.toLocaleDateString("en-US");
            const time = dateObject.toLocaleTimeString("en-US", {
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            });
            return (
              <li
                key={notification.id}
                className={notification.attributes?.isRead ? "isRead" : ""}
                style={{ position: "relative" }}
              >
                <div className="">
                  <h4 className="notification-message-heading">
                    {notification.attributes?.subject}
                  </h4>
                  <span className="notification-message-info">
                    {notification.attributes?.message}
                  </span>
                  <span
                    className="del-btn"
                    onClick={() => deleteNotification(notification.id)}
                  >
                    &times;
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span
                    className="notification-time text-nowrap"
                    style={{ marginRight: "0.4rem" }}
                  >
                    {time}
                  </span>
                  {notification.attributes?.isRead ? (
                    <svg
                      className="readed_icon"
                      style={{ fontSize: "1rem", minWidth: "1rem" }}
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M255.4 48.2c.2-.1 .4-.2 .6-.2s.4 .1 .6 .2L460.6 194c2.1 1.5 3.4 3.9 3.4 6.5v13.6L291.5 355.7c-20.7 17-50.4 17-71.1 0L48 214.1V200.5c0-2.6 1.2-5 3.4-6.5L255.4 48.2zM48 276.2L190 392.8c38.4 31.5 93.7 31.5 132 0L464 276.2V456c0 4.4-3.6 8-8 8H56c-4.4 0-8-3.6-8-8V276.2zM256 0c-10.2 0-20.2 3.2-28.5 9.1L23.5 154.9C8.7 165.4 0 182.4 0 200.5V456c0 30.9 25.1 56 56 56H456c30.9 0 56-25.1 56-56V200.5c0-18.1-8.7-35.1-23.4-45.6L284.5 9.1C276.2 3.2 266.2 0 256 0z" />
                    </svg>
                  ) : (
                    <svg
                      className="unreaded_icon"
                      style={{ fontSize: "1rem", minWidth: "1rem" }}
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      onClick={() => markAsRead(notification)}
                    >
                      <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                    </svg>
                  )}
                </div>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default Notifications;
