import React from "react";
import "../Assets/Home.css";
import asset21 from "../Assets/SVG/twitter.svg";
import asset22 from "../Assets/SVG/facebook.svg";
import asset23 from "../Assets/SVG/instagram.svg";
import linkedin from "../Assets/SVG/linkedin-2.svg";
import { Link } from "react-router-dom";
import NewsLetter from "./NewsLetter"
import { useTranslation } from "react-i18next";
const Footer = ({ user }) => {
  const { t } = useTranslation();
  return (
    <footer className="footer-5 mt-5">

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <h5
              className="text-uppercase mb-3 footer-wnvoec"
              style={{ color: "rgb(3, 78, 162)", fontWeight: "700", fontSize: "1.28rem" }}
            >
              WNVOEC
            </h5>
            <p className="description">{t("footer.description")}</p>
            <p className="mb-0">
              <a
                href={"https://twitter.com/wnvoec"}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={asset21}
                  width="20px"
                  height="20px"
                  className="me-3"
                  alt="."
                />
              </a>

              <a
                href="https://www.facebook.com/wnvoec"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={asset22}
                  width="20px"
                  height="20px"
                  className="me-3"
                  alt="."
                />
              </a>

              <a
                href="https://www.instagram.com/wnvoec/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={asset23}
                  width="20px"
                  height="20px"
                  className="me-3"
                  alt="."
                />
              </a>
              <a
                href="https://www.linkedin.com/company/wnvoec/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedin}
                  width="20px"
                  height="20px"
                  className="me-3"
                  alt="."
                />
              </a>
            </p>
          </div>

          <div className="col-md-6 col-lg-2 col-xl-2 mx-auto mb-4 mb-lg-0">
            <h6
              className="text-uppercase mb-3"
              style={{ color: "rgb(3, 78, 162)", fontWeight: "700", fontSize: "1.28rem" }}
            >
              {t("footer.quick_links")}
            </h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="link-1">
                  {t("footer.home")}
                </Link>
              </li>
              <li>
                <Link to="/products" className="link-1">
                  {t("footer.services")}
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="link-1">
                  {t("footer.blogs")}
                </Link>
              </li>
              <li>
                <Link to="/about" className="link-1">
                  {t("footer.about_us")}
                </Link>
              </li>
              <li>
                <Link to="/contact" className="link-1">
                  {t("footer.contacts")}
                </Link>
              </li>
            </ul>
          </div>

          <div class="col-md-6 col-lg-3 col-xl-3 mx-auto mb-4 mb-lg-0">
            <div class="footer-heading">
              <h6
                class="text-uppercase mb-3"
                style={{ color: "rgb(3, 78, 162)", fontWeight: "700", fontSize: "1.28rem" }}
              >
                {t("footer.members")}
              </h6>
            </div>
            <ul class="list-unstyled">
              {user && (
                <div>
                  <li>
                    <Link to="/profile" class="link-1">
                      {t("footer.profile")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile" class="link-1">
                      {t("footer.search")}
                    </Link>
                  </li>
                </div>
              )}
              <li>
                <Link to="/login" class="link-1">
                  {t("footer.login")}
                </Link>
              </li>
              <li>
                <Link to="/joinus" class="link-1">
                  {t("footer.signUp")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-lg-2 col-xl-3 mx-auto mb-4 mb-lg-0">
            <h6 className="text-uppercase mb-3" style={{ color: "rgb(3, 78, 162)", fontWeight: "700", fontSize: "1.28rem" }}>{t("footer.contact")}</h6>
            <ul className="list-unstyled">
              <li>
                <a href="mailto:info@wnvoec.org" className="link-1">
                  info@wnvoec.org
                </a>
              </li>
              <li>
                <a href="." className="link-2">
                  +65 62423656 /+65 96333990
                </a>
              </li>
              <li>
                <a href="." className="link-3">
                 43 Changi South Ave 2 #03-01 Singapore 486164
                </a>

              </li>

              <li >

                <NewsLetter />

              </li>


            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 text-center text-lg-start">
            <div
              class="copyright-text"
              style={{ color: "rgb(80, 93, 104)", fontWeight: "500", fontSize: "1.28rem" }}
            >
              <p>{t("footer.terms")}</p>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6  d-lg-inline-block text-xl-end text-center">
            <div
              class="footer-menu"
              style={{ color: "rgb(80, 93, 104)", fontWeight: "500", fontSize: "1.28rem" }}
            >
              <p>©2023 - WNVOEC | {t("footer.reserved")}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
