import { useState } from "react";
import "../Dashboard/DashboardManager.css";
import { useTranslation } from "react-i18next";
import SearchIconm from "../../Assets/Icons/SearchIconm";
import Userm from "../../Assets/Icons/Userm";
import WnDepotlogo from "../../Assets/Icons/WnDepotlogo";

const iconMapping = {
  self_profile: <Userm />,
  search_organization: <SearchIconm />,
  wnDepot_Collection: <WnDepotlogo />,
 
};

const MemberSideTabs = (props) => {
  // const [collapseMenu, setCollapseMenu] = useState(false);
  const { t, i18n } = useTranslation();

  const liItems = MemberSideTabsItems.map((item) => {
    const selected = item.id === props.selectedTab;
    const tabIcon = iconMapping[item.id];
    return (
      <li
        key={item.id}
        className={`nav-item dashboard-tabs-item ${selected ? "dashboard-tabs-item-active active" : ""
          }`}
        
        onClick={(e) => props.setTab(item.id)}
      >
        <div className="nav-link"  style={{  color: selected ? "rgba(3, 78, 162, 1)" : "rgba(0, 0, 0, 0.6)", margin:'1.5rem 0 0 0' ,  display: "flex", 
      alignItems: "center", }}>
        {tabIcon}
        <span style={{ marginLeft: "1.3rem" , fontSize:"1rem" }}>{item.label}</span>
        <span
      className="dot"
      style={{
        background: selected ? "blue" : "transparent",
        marginLeft: "0.5rem", 
        width: "8px", 
        height: "8px",
        borderRadius: "50%",
      }}
    ></span>
        
        </div>
      </li>
    );
  });
  return (
    <div
      className="dashboard-tabs-members"
      style={{
        width: props.collapseMenu ? "0" : "200px",
        overflow: "hidden",
        background:"#fff" ,
       
      }}
    >
      <ul className="nav nav-tabs" >{liItems} </ul>
      <div className="collapse-menu">
        {props.collapseMenu === false && (
          <div
            style={{
              display: "flex",
              gap: "3rem",
              marginLeft: "2rem",
              cursor: "pointer",
              color:"black"
            }}
            onClick={() => props.setCollapseMenu(true)}
          >
            <span>{t('members.collapse')}</span>
            <div>
              <svg
                width="1rem"
                height="1rem"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                class="sc-bdvvtL sc-gSQFLo dCzDuG hRlPBd"
              >
                <path
                  d="M9.88 14.12 3.773 8 9.88 1.88 8 0 0 8l8 8 1.88-1.88Z"
                  fill="black"
                ></path>
              </svg>
            </div>
          </div>
        )}
      </div>
    </div>
    // <div className="">
    //   <svg
    //         data-bs-toggle="offcanvas"
    //         data-bs-target="#offcanvasRight"
    //         aria-controls="offcanvasRight"
    //         xmlns="http://www.w3.org/2000/svg"
    //         viewBox="0 0 448 512"
    //         width="24"
    //         height="24"
    //         className="menu-icon"
    //         // style={{ marginLeft: "30px" }}
    //       >
    //         <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
    //       </svg>
    //       <div  className=""
    //         tabindex="-1"
    //         id="offcanvasRight"
    //         aria-labelledby="offcanvasRightLabel">
    //            <ul className="nav nav-tabs">{liItems} </ul>
    //         </div>
    // </div>
  );
};
export default MemberSideTabs;

const MemberSideTabsItems = [
  { label: "Profile", id: "self_profile" },
  { label: "Search", id: "search_organization" },
  { label: "WnDepot", id: "wnDepot_Collection" },
];
