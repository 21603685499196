import React, { useState } from 'react';
import { apiCaller } from '../utils/api';
import axios from 'axios';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
   const response = await axios.post(`${apiUrl}/news-letters`, {

    data: {
        email

    }
   })
  //  console.log({response});
    setSubscribed(true);
  };

  return (
    <div className="mt-4">
      {subscribed ? (
        <div className="alert alert-success" role="alert">
          Thank you for subscribing!
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-3" style={{ color: 'rgb(3, 78, 162)' }}>
            <p style={{ fontWeight: '800' }}>Sign Up for Newsletter</p>
          </div>
          <div className="input-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button type="submit" className="btn btn-primary">
              <i className="bi bi-arrow-right-circle-fill"></i>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default NewsLetter;
