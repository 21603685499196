import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormComponent from "../../Components/MembersArea/FormComponent";
import "../../Components/MembersArea/MemberApplication.css";
import { apiCaller } from "../../utils/api";
import { get } from "react-scroll/modules/mixins/scroller";
import { useForm } from "react-hook-form";
import EditProfileForm from "./EditProfileForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
const companyDetailsSchema = [
  {
    id: 0,
    name: "industryClassification",
    label: "Industry Classification",
    type: "checkbox",
    options: [
      "Container Traders",
      "Container Manufactures",
      "NVOCC",
      "NVOECs",
      "Shipping Related companies including Depot operators",
      "Transport Operators",
      "IT Industry",
      "Trade Associations",
      "Direct Shippers",
      "Shipping Agencies",
      "Government Agencies",
      "Freight Forwarders",
    ],
  },
  {
    id: 1,
    name: "companyType",
    label: "Company Type",
    title: "Details of Applicant Company",
    type: "checkbox",
    rules: {
      required: true,
    },
    options: [
      "Limited Liability Company",
      "Public Limited Company",
      "Partnership",
      "Sole Proprietorship",
    ],
  },
  {
    id: 2,
    name: "nameOfApplicantCompany",
    label: "Name of Applicant Company",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    id: 3,
    name: "addressOfMainOffice",
    label: "Address of Main Office",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    id: 4,
    name: "correspondenceAddress",
    label: "Correspondence Address (if differs from above)",
    type: "text",
  },
  {
    id: 5,
    subTitle: "Contact Details",
    name: "telephoneNumber",
    label: "Telephone Number",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    id: 6,
    name: "fax",
    label: "Fax",
    type: "text",
  },
  {
    id: 7,
    name: "emailAddressOfCompany",
    label: "Email Address of Company",
    type: "email",
    rules: {
      required: true,
    },
  },
  {
    id: 8,
    name: "companyInfo",
    label: "Company Information",
    type: "textarea",
    style: { height: "500px" },
    rules: {
      required: true,
    },
  },
  {
    id: 9,
    name: "websiteAddressOfCompany",
    label: "Website Address of Company",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    id: 10,
    name: "staffStrengthOfCompany",
    label: "Staff Strength of Company",
    type: "text",
  },
  {
    id: 11,
    name: "contactPerson1",
    label: "Contact Person 1",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    id: 12,
    name: "designation1",
    label: "Designation",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    id: 13,
    name: "emailAddress1",
    label: "Email Address",
    type: "email",
    rules: {
      required: true,
    },
  },
  {
    id: 14,
    subTitle: "Contact Number",
    name: "office1",
    label: "Office",
    type: "text",
  },
  {
    id: 15,
    name: "mobile1",
    label: "Mobile",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    id: 16,
    name: "contactPerson2",
    label: "Contact Person 2",
    type: "text",
  },
  {
    id: 17,
    name: "designation2",
    label: "Designation",
    type: "text",
  },
  {
    id: 18,
    name: "emailAddress2",
    label: "Email Address",
    type: "email",
  },
  // {
  //   id: 18,
  //   name: "companyRegistrationCertificate",
  //   label: "Company’s Business Registration Certificate",
  //   type: "file",
  //   // rules: {
  //   //   required: true,
  //   // },
  // },
  // {
  //   id: 19,
  //   name: "idOfTheBeneficialOwner",
  //   label: "ID Of The Beneficial Owner",
  //   type: "file",
  //   // rules: {
  //   //   required: true,
  //   // },
  // },
  // {
  //   id: 20,
  //   name: "financialStatement",
  //   label: "Financial Statement",
  //   type: "file",
  // },
  // {
  //   id: 21,
  //   name: "bankStatement",
  //   label: "Bank Statement",
  //   type: "file",
  // },
  // {
  //   id: 22,
  //   name: "otherDocument",
  //   label: "Other Document",
  //   type: "file",
  // },
];

const EditProfile = ({ user, storeNotification }) => {
  const { t } = useTranslation();
  const [application, setApplication] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const uploadUrl = process.env.REACT_APP_IMG_URL;

  const [files, setFiles] = useState([]);

  const [logo, setLogo] = useState("");
  const [comCert, setComCert] = useState("");

  // console.log("logo", logo);
  // console.log("comCert", comCert);

  const history = useHistory();
  const id = user?.email;

  const onSubmit = async (data) => {
    // console.log("Data", logo);
    try {
      const body = {
        data: {
          ...data,
          username: user.userName,
          email: user.email,
          name: user.name,
          logoOfCompany: logo == "" ? application?.attributes?.logoOfCompany : `${uploadUrl}${logo}`,
          companyRegistrationCertificate: `${uploadUrl}${comCert}`,
        },
      };
      let result = await apiCaller("post", `review-edits`, body);
      if (result) {
        setShowPopup(true);
        storeNotification(
          result?.data?.attributes?.email,
          "admin",
          "Member profile edit",
          `User ${result?.data?.attributes?.name} with email ${result?.data?.attributes?.email} has made changes to their member profile. Please review and approve the changes if necessary.`
        );
        storeNotification(
          "admin",
          result?.data?.attributes?.email,
          "Member profile edit",
          `Dear ${result?.data?.attributes?.name} Your profile edit has been submitted for review. It will be reviewed by an admin shortly.`
        );
      }
      if (result.err) return;
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(application?.attributes);

  //   const { id } = useParams();

  const getApplications = async () => {
    setLoading(true);
    let applicationSingle = await apiCaller(
      "get",
      `applications?filters[email][$eq]=${id}`
    );
    if (applicationSingle.err) return;
    else {
      setApplication(applicationSingle.data[0]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <div>
      {loading ? (
        <h1>{t("editProfile.loading")}</h1>
      ) : (
        <EditProfileForm
          application={application}
          onSubmit={onSubmit}
          user={user}
          setFiles={setFiles}
          setLogo={setLogo}
          setComCert={setComCert}
          newFile={files}
        />
      )}
      {showPopup && (
        <div className="popup_container">
          <div className="popup" style={{ height: "auto" }}>
            <p className="">
              <b>{t("editProfile.profile_update_submit")}</b>
              <br />
              <br />
              {t("editProfile.update_submit_info_1")}
              <br />
              <br />
              {t("editProfile.update_submit_info_2")}
              <br />
              <br />
              {t("editProfile.thanks")}
              <br />
              <br />
              <b>{t("editProfile.management")}</b>
            </p>
            <button
              style={{
                backgroundColor: "#034ea2",
                border: "none",
                padding: ".4rem 1rem",
                color: "white",
                borderRadius: "5px",
              }}
              onClick={() => history.push("/profile")}
            >
              {t("editProfile.view_profile")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
