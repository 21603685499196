import React, { useState } from "react";
import "../Profile/Profile.css";
import { useParams } from "react-router-dom";

const MemberProfile = () => {
  const [applicationData, setApplicationData] = useState({});

  const { id } = useParams();

  return (
    <div>
      <div className="emp-profile wrapper">
        <h1>{id}</h1>
        <div className="row ">
          <div className="col-md-3">
            <div className="profile-img">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-9 profile-top">
            <div className="profile-head">
              <h5>{applicationData?.username}</h5>
              <h6>{applicationData?.email}</h6>
            </div>
            <a href="#" className="profile-edit-btn">
              Edit Profile
            </a>
          </div>
        </div>
        <div className="row">
          <div className="main_content">
            <div className="main_content_left">
              <div className="cell">
                <strong>Company Name</strong>
                <span>{applicationData?.nameOfApplicantCompany || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Company type</strong>
                <span>{applicationData?.companyType || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>IT Industry</strong>
                <span>{applicationData?.industryClassification || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Staff Strength Of Company</strong>
                <span>{applicationData?.staffStrengthOfCompany || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Website Address Of Company</strong>
                <span>{applicationData?.websiteAddressOfCompany || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Email Address Of Company</strong>
                <span>{applicationData?.emailAddressOfCompany || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Telephone Number</strong>
                <span>{applicationData?.telephoneNumber || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Address Of Main Office</strong>
                <span>{applicationData?.addressOfMainOffice || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Correspondence Address</strong>
                <span>{applicationData?.correspondenceAddress || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Fax</strong>
                <span>{applicationData?.fax || "N/A"}</span>
              </div>
            </div>
            <div className="main_content_right">
              <div className="cell">
                <strong>Contact Person 1</strong>
                <span>{applicationData?.contactPerson1 || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Email Address 1</strong>
                <span>{applicationData?.emailAddress1 || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Office 1</strong>
                <span>{applicationData?.office1 || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Designation 1</strong>
                <span>{applicationData?.designation1 || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Mobile 1</strong>
                <span>{applicationData?.mobile1 || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Contact Person 2</strong>
                <span>{applicationData?.contactPerson2 || "N/A"}</span>
              </div>
              <div className="cell">
                <strong>Email Address 2</strong>
                <span>{applicationData?.emailAddress2 || "N/A"}</span>
              </div>

              <div className="cell">
                <strong>Designation 2</strong>
                <span>{applicationData?.designation2 || "N/A"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberProfile;
