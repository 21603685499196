import React from "react";
import "./ProcessContent.css";
import { useTranslation } from "react-i18next";

const ProcessContent = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="content-area">
      <p className="info">{t('membership.process.para')}
      </p>
      <h2 className="process_title">{t('membership.process.main_heading')}</h2>
      <div className="content_main">
        <h3>{t('membership.process.submit_form')}</h3>
        <p>
          {t('membership.process.submit_info')}
        </p>
      </div>
      <div className="content_main">
        <h3>{t('membership.process.verification')}n</h3>
        <p>
          {t('membership.process.verification_info')}
        </p>
      </div>
      <div className="content_main">
        <h3>{t('membership.process.invoicing')}</h3>
        <p>
          {t('membership.process.Invoicing_info')}
        </p>
      </div>
      <div className="content_main">
        <h3>{t('membership.process.payment')}</h3>
        <p>
          {t('membership.process.payment_info')}
        </p>
      </div>
      <div className="content_main">
        <h3>{t('membership.process.confirmation')}</h3>
        <p>
          {t('membership.process.confirmation_info')}
        </p>
      </div>
      <p className="info">
        {t('membership.process.join_community')}
      </p>
    </div>
  );
};

export default ProcessContent;
