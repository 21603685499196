import React from "react";
import "./AboutTabs.css";
function Tab(props) {
    return <div className="tab_content">{props.content}</div>;
}

function Navigation(props) {
    const getId = (id) => {
        props.onNavClick(id);
        if (props.setCurrentTab) {
            props.setCurrentTab(id);
        }
    };
    return (
        <ul className="tabs__nav1 mt-5">
            {props.tabs.map((item) => (
                <li key={item.id} className="tabs__item1">
                    <button
                        style={item.style}
                        className={`tabs__button1 ${props.activeTabId === item.id ? "active" : ""
                            }`}
                        onClick={() => getId(item.id)}
                    >
                        {item.name}
                    </button>
                </li>
            ))}
        </ul>
    );
}

function Tabs(props) {
    const [activeTabId, setActiveTab] = React.useState(props.tabs[0].id);

    const activeTab = React.useMemo(
        () => props.tabs.find((tab) => tab.id === activeTabId),
        [activeTabId, props.tabs]
    );

    return (
        <div className={`tabs`}>
            <Navigation
                tabs={props.tabs}
                onNavClick={setActiveTab}
                activeTabId={activeTabId}
                setCurrentTab={props.setCurrentTab}
            />
            <Tab content={activeTab.content} />
        </div>
    );
}

export default function AboutTabs(props) {
    return (
        <div className="container">
            <Tabs tabs={props.tabs} setCurrentTab={props.setCurrentTab} />
        </div>
    );
}
