import React, { useEffect, useState } from "react";
import "./TopBar.css";
import asset41 from "../../Assets/SVG/Asset41.svg";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import phone from "../../Assets/SVG/Asset 38.svg";
import mail from "../../Assets/SVG/envelope-solid.svg";
import location from "../../Assets/SVG/Asset36.svg";
import { getUser, logoutUser, storeUser } from "../../utils/helperFunctions";
import UserProfile from "../UserProfile";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Notifications from "../notifications/Notifications";
import NotificationIcon from "./NotificationIcon";

const TopBar = ({
  user,
  setUser,
  setEng,
  setChinese,
  setSpanish,
  notifications,
  getAllNotifications,
}) => {
  const { t, i18n } = useTranslation();

  // ==========

  const [showNotifications, setShowNotifications] = useState(false);
  const location = useLocation();
  const isDashboardManagerRoute = location.pathname === "/dashboardmanager";

  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleChangeLanguage = (language) => {
    if (language === "en") {
      setEng(true);
      setChinese(false);
      setSpanish(false);
    } else if (language === "zn") {
      setEng(false);
      setChinese(true);
      setSpanish(false);
    } else if (language === "es") {
      setEng(false);
      setChinese(false);
      setSpanish(true);
    }
    i18n.changeLanguage(language);
  };
  const activeLanguage = i18n.language;

  const [forgotPassword, setForgotPassword] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const navigator = useHistory();

  const [error, setError] = useState(null);
  const methods = useForm({
    defaultValues: {
      identifier: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/local`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setError(null);
      methods.reset();
      if (response.data) {
        storeUser(response.data);
        // console.log(response.data);
        setUser(getUser());
        navigator.push("/members");
      }
      toast.info(`Welcome ${response.data.user.name}`);
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  const sendResetLink = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/forgot-password`, {
        email: data?.identifier,
      });
      if (response.data) {
        toast.info("Reset link sent to your email");
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  const Logout = () => {
    logoutUser();
    setUser(getUser());
    navigator.push("/");
  };

  const contactInfo = [
    {
      icon: mail,
      title: t("topbar.email"),
      info: "info@wnvoec.org",
    },
    {
      icon: phone,
      title: t("topbar.phone"),
      info: "+65 62423656",
    },
    {
      icon: location,
      title: t("topbar.address"),
      info: "43 Changi South Ave 2 #03-01 Singapore 486164",
    },
  ];

  return (
    <nav className="topBar">
      <div className="languages_bar">
        <span
          onClick={() => handleChangeLanguage("en")}
          className={
            activeLanguage === "en" ? "active_language_btn " : "language_btn"
          }
        >
          ENG
        </span>{" "}
        |
        <span
          onClick={() => handleChangeLanguage("zn")}
          className={
            activeLanguage === "zn" ? "active_language_btn " : "language_btn"
          }
        >
          中文
        </span>{" "}
        |
        <span
          onClick={() => handleChangeLanguage("es")}
          className={
            activeLanguage === "es" ? "active_language_btn " : "language_btn"
          }
        >
          Español
        </span>
      </div>

      <div className="navbar_logo">
        <Link to="/">
          <img src={asset41} alt="Logo" />
        </Link>

        {isDashboardManagerRoute && <h2>Dashboard</h2>}
      </div>

      <div className="menu_right">
        <ul className="navbar_links">
          <li>
            <NavLink activeClassName="activeNavLink" exact to="/">
              {t("topbar.home")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="activeNavLink" exact to="products">
              {t("topbar.products & services")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="activeNavLink" exact to="membership">
              {t("topbar.membership")}
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/services" activeClassName="activeNavLink">
              {t("topbar.services")}
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/about" activeClassName="activeNavLink">
              {t("topbar.about_us")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" activeClassName="activeNavLink">
              {t("topbar.contact")}
            </NavLink>
          </li>

          {user ? (
            <li>
              <Link to="/members" className="join-btn">
                {t("topbar.member")}
              </Link>
            </li>
          ) : (
            <li>
              <Link to="/joinus" className="join-btn">
                {t("topbar.join")}
              </Link>
            </li>
          )}
          {isMemberManager(user) && (
            <li>
              <Link to="/dashboardmanager" className="join-btn">
                {t("topbar.dashboard")}
              </Link>
            </li>
          )}
        </ul>
        {user && (
          <div
            type="button"
            className=" position-relative"
            style={{
              padding: "0",
              backgroundColor: "rgb(245, 247, 251)",
              borderRadius: "50%",
            }}
            onClick={handleShowNotifications}
          >
            <NotificationIcon />
            {notifications?.filter(
              (notification) => !notification?.attributes?.isRead
            )?.length > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {
                    notifications?.filter(
                      (notification) => !notification?.attributes?.isRead
                    )?.length
                  }
                </span>
              )}
          </div>
        )}
        <div className="menu_icon">
          <svg
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width="24"
            height="24"
          >
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </svg>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        {user && <UserProfile user={user} />}
        <ul className="menu_navbar_links">
          <NavLink activeClassName="activeNavLinkMobile" exact to="/">
            <li data-bs-dismiss="offcanvas">{t("topbar.home")}</li>
          </NavLink>
          <NavLink to="/products" activeClassName="activeNavLinkMobile">
            <li data-bs-dismiss="offcanvas">{t("topbar.products & services")}</li>
          </NavLink>
          <NavLink activeClassName="activeNavLinkMobile" exact to="/membership">
            <li data-bs-dismiss="offcanvas"> {t("topbar.membership")}</li>
          </NavLink>
          {/* <NavLink activeClassName="activeNavLinkMobile" exact to="/products">
            <li data-bs-dismiss="offcanvas">{t("topbar.products")}</li>
          </NavLink> */}
          <NavLink to="/about" activeClassName="activeNavLinkMobile">
            <li data-bs-dismiss="offcanvas">{t("topbar.about_us")}</li>
          </NavLink>
          <NavLink to="/contact" activeClassName="activeNavLinkMobile">
            <li data-bs-dismiss="offcanvas">{t("topbar.contact")}</li>
          </NavLink>
          {user ? (
            <NavLink
              to="/members"
              activeClassName="activeNavLinkMobile"
              className="join_btn_mobile"
            >
              <li data-bs-dismiss="offcanvas">{t("topbar.member")}</li>
            </NavLink>
          ) : (
            <NavLink
              to="/joinus"
              activeClassName="activeNavLinkMobile"
              className="join_btn_mobile"
            >
              <li data-bs-dismiss="offcanvas">{t("topbar.join")}</li>
            </NavLink>
          )}

          {/* <li>
            <Link to="/signup" className="join-btn">
              JOIN
            </Link>
          </li> */}
        </ul>

        {!user && (
          <form
            onSubmit={methods.handleSubmit(
              forgotPassword ? sendResetLink : onSubmit
            )}
            style={{
              padding: "1rem 1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <div
              className="form-floating "
              style={{
                width: "100%",
              }}
            >
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="identifier"
                {...methods.register("identifier", { required: true })}
                style={{
                  width: "100%",
                }}
              />
              <label htmlFor="floatingInput">{t("topbar.email_address")}</label>
            </div>
            {forgotPassword ? null : (
              <div
                className="form-floating"
                style={{
                  width: "100%",
                }}
              >
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  name="password"
                  {...methods.register("password", { required: true })}
                  style={{
                    width: "100%",
                  }}
                />
                <label htmlFor="floatingPassword">{t("topbar.password")}</label>
              </div>
            )}
            <button
              data-bs-dismiss="offcanvas"
              className="btn btn-purple small-btn btn-md py-2"
              style={{
                width: "100%",

                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {forgotPassword ? t("topbar.reset_link") : t("topbar.login")}
            </button>
            <span
              className="forgot-password"
              style={{
                color: "#000",
              }}
              onClick={() => setForgotPassword(true)}
            >
              {t("topbar.forgot_password")}
            </span>
            <p data-bs-dismiss="offcanvas">
              {t("topbar.not_member")}
              <Link
                to={"/joinus"}
                style={{ textDecoration: "none", marginLeft: ".5rem" }}
              >
                {t("topbar.signup")}
              </Link>
            </p>
          </form>
        )}

        {user && (
          <li
            data-bs-dismiss="offcanvas"
            onClick={Logout}
            style={{
              fontWeight: "bold",
              border: "1px solid #000",
              padding: "0.5rem 1rem",
              borderRadius: "3rem",
              textAlign: "center",
              fontSize: "0.9rem",
              cursor: "pointer",
              listStyle: "none",
              margin: "1rem",
            }}
          >
            {t("topbar.logout")}
          </li>
        )}

        {user && (
          <Link
            to={"/profile"}
            style={{
              fontWeight: "bold",
              border: "1px solid #000",
              padding: "0.5rem 1rem",
              borderRadius: "3rem",
              textAlign: "center",
              fontSize: "0.9rem",
              cursor: "pointer",
              listStyle: "none",
              margin: "1rem",
              textDecoration: "none",
            }}
          >
            <span data-bs-dismiss="offcanvas">{t("topbar.view_profile")}</span>
          </Link>
        )}

        <div style={{ marginTop: "2rem" }}>
          <div style={{ color: "rgb(80, 93, 104)", textAlign: "center" }}>
            <h2 style={{ fontWeight: "bold" }}>{t("contact.heading1")}</h2>
            <h6>{t("contact.heading2")}</h6>
          </div>
          <hr style={{ width: "70%", margin: "2rem auto" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "2rem 3rem",
              padding: "0 1rem",
            }}
          >
            {contactInfo.map((item, index) => {
              return (
                <div
                  key={index}
                  className="contact-info"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <div
                    className="info-icons"
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      background: "rgb(242, 245, 247)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "30px",
                      }}
                      src={item.icon}
                      alt={item.icon}
                    />
                  </div>
                  <h5>{item.title}</h5>
                  <p style={{ color: "#b9c2c9", fontSize: "0.9rem" }}>
                    {item.info}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {showNotifications && (
        <Notifications
          handleShowNotifications={handleShowNotifications}
          notifications={notifications}
          getAllNotifications={getAllNotifications}
        />
      )}
    </nav>
  );
};

export default TopBar;

function isMemberManager(user) {
  let checkPass = user && user["member-manager"];
  return checkPass;
}
