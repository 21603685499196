import React from 'react';
import "./EcoHome.css";
import luxehomeimage1 from "../../Assets/Assets/EcoHome-image-2.png";
import luxehomeimage2 from "../../Assets/Assets/EcoHome-image-3.png";
import luxehomeimage3 from "../../Assets/Assets/EcoHome-image-4.png";
import luxehomeimage4 from "../../Assets/Assets/EcoHome-image-5.png";
import luxehomeimage5 from "../../Assets/SVG/SustainableMaterials.svg";
import luxehomeimage6 from "../../Assets/SVG/Energy-EfficientDesigns.svg";
import luxehomeimage7 from "../../Assets/SVG/WaterConservation.svg";
import luxehomeimage8 from "../../Assets/SVG/WaterConservation1.svg";
import luxehomeimage9 from "../../Assets/SVG/IndoorAirQuality.svg";
import luxehomeimage10 from "../../Assets/SVG/SustainableLandscaping.svg";
import { useTranslation } from 'react-i18next';
import HomeContact from './HomeContact';

const EcoHome = () => {

    const { t } = useTranslation();

    return (
        <>
            {/* section 1 */}
            <div className=" container-fluid main-luxeHome">
                <div className="row">
                    <div className="col-md-12">
                        <div className="EcoHome-section-1">
                            <div className="luxeHome-content-1">
                                <h2 className="luxeHome-heading-1">
                                    {t("EcohomePage.Ecohome")}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section 2 */}
            <div className="luxeHome-section-2">
                <div className=" container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="luxeHome-heading-2">
                                {t("EcohomePage.Ecohome")}
                            </h2>
                            <p className="luxeHome-para-2">
                                {t("EcohomePage.Ecohome-2")}
                            </p>
                            <h2 className="luxeHome-heading-3">
                                {t("EcohomePage.Ecohome-3")}
                            </h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage5} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-4")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-5")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage6} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-6")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-7")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage7} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-8")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-9")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage8} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-10")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-11")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage9} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-12")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-13")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage10} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-23")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-24")}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="luxeHome-heading-4">
                                {t("EcohomePage.Ecohome-14")}
                            </h2>
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-15")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-16")}
                            </p>
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-17")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-18")}
                            </p>
                            <p className="luxeHome-para-3">
                                {t("EcohomePage.Ecohome-19")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("EcohomePage.Ecohome-20")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="luxeHome-section-2">
                <div className=" container">
                    <div className="row">
                        <h2 className="luxeHome-heading-5">
                            <span className='luxe-span'>  {t("EcohomePage.Ecohome-21")} </span>{t("EcohomePage.Ecohome-22")}
                        </h2>
                        <div className="col-md-7">
                            <div className="luxeHome-gallery mb-4">
                                <img src={luxehomeimage1} alt="Value Home" className='img-fluid mb-4' />
                                <img src={luxehomeimage2} alt="Value Home" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="luxeHome-gallery mb-5">
                                <img src={luxehomeimage3} alt="Value Home" className='img-fluid mb-4' />
                                <img src={luxehomeimage4} alt="Value Home" className='img-fluid ' />
                            </div>
                        </div>
                    </div>
                </div>
                <HomeContact />
            </div>
        </>
    )
}

export default EcoHome;
