import React, { useEffect, useState } from "react";
import { apiCaller } from "../../../utils/api";
import EditProfileForm from "../../Profile/EditProfileForm";
import CompareForm from "./CompareForm";
import "./CompareForm.css";

const CompareApplications = ({ user, compare }) => {
  const [oldApplication, setOldApplication] = useState(null);
  const [newApplication, setNewApplication] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  //   console.log("oldApplication", oldApplication);
  //   console.log("newApplication", newApplication);

  const getOldApplication = async () => {
    setLoading(true);
    let result = await apiCaller(
      "get",
      `applications?filters[email][$eq]=${compare.email}`
    );
    if (result.err) console.log(null);
    else {
      setOldApplication(result.data[0]);
      setLoading(false);
    }
  };

  const getNewApplication = async () => {
    setLoading2(true);

    let result = await apiCaller(
      "get",
      `review-edits?filters[email][$eq]=${compare.email}`
    );
    if (result.err) console.log(null);
    else {
      setNewApplication(result.data[0]);
      setLoading2(false);
    }
  };

  useEffect(() => {
    getOldApplication();
    getNewApplication();
  }, []);

  const changes = {};

  for (const field in oldApplication?.attributes) {
    if (
      oldApplication?.attributes[field] !== newApplication?.attributes[field]
    ) {
      changes[field] = {
        old: oldApplication?.attributes[field],
        new: newApplication?.attributes[field],
      };
    }
  }

  return (
    <div className="compareApplications">
      <div>
        <h5>Old Application</h5>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <CompareForm application={oldApplication} />
        )}
      </div>
      <div>
        <h5>New Application</h5>
        {loading2 ? (
          <p>Loading...</p>
        ) : (
          <CompareForm application={newApplication} changes={changes} />
        )}
      </div>
    </div>
  );
};

export default CompareApplications;
