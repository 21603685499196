import React from 'react'

const Companyl = () => {
  return (
    <div>
        <svg width="25" height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_467_517)">
<path opacity="0.979" fill-rule="evenodd" clip-rule="evenodd" d="M17.9824 15.5918C17.9824 15.9785 17.9824 16.3652 17.9824 16.752C11.9824 16.752 5.98243 16.752 -0.0175781 16.752C-0.0175781 16.3652 -0.0175781 15.9785 -0.0175781 15.5918C0.380858 15.5918 0.779298 15.5918 1.17773 15.5918C1.17187 11.6074 1.17773 7.62304 1.19531 3.63867C1.27998 3.30548 1.49678 3.12384 1.8457 3.09375C4.22093 2.44842 6.59981 1.82147 8.98242 1.21289C9.26603 1.20981 9.45939 1.33872 9.5625 1.59961C9.58008 2.36124 9.58595 3.12296 9.58008 3.88477C9.19336 3.88477 8.80664 3.88477 8.41992 3.88477C8.41992 3.45117 8.41992 3.01758 8.41992 2.58398C6.40318 3.09842 4.39341 3.63748 2.39062 4.20117C2.37305 7.99801 2.36719 11.7949 2.37305 15.5918C4.38866 15.5918 6.40431 15.5918 8.41992 15.5918C8.41405 12.1933 8.41992 8.7949 8.4375 5.39648C8.47923 5.24851 8.56712 5.13717 8.70117 5.0625C9.16748 5.01757 9.63626 4.99412 10.1074 4.99219C12.1699 4.96874 14.2324 4.96874 16.2949 4.99219C16.5469 5.05663 16.7051 5.21483 16.7695 5.4668C16.7871 7.82223 16.793 10.1777 16.7871 12.5332C16.3887 12.5332 15.9902 12.5332 15.5918 12.5332C15.5918 10.4121 15.5918 8.291 15.5918 6.16992C13.5879 6.16992 11.584 6.16992 9.58008 6.16992C9.58008 9.31054 9.58008 12.4512 9.58008 15.5918C12.3809 15.5918 15.1816 15.5918 17.9824 15.5918Z" fill="black"/>
<path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M3.99023 5.00977C4.916 5.00977 5.84181 5.00977 6.76758 5.00977C6.76758 5.40819 6.76758 5.80665 6.76758 6.20508C5.84181 6.20508 4.916 6.20508 3.99023 6.20508C3.99023 5.80665 3.99023 5.40819 3.99023 5.00977Z" fill="black"/>
<path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M3.99023 7.64648C4.916 7.64648 5.84181 7.64648 6.76758 7.64648C6.76758 8.04491 6.76758 8.44337 6.76758 8.8418C5.84181 8.8418 4.916 8.8418 3.99023 8.8418C3.99023 8.44337 3.99023 8.04491 3.99023 7.64648Z" fill="black"/>
<path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M11.1973 7.64648C12.123 7.64648 13.0488 7.64648 13.9746 7.64648C13.9746 8.04491 13.9746 8.44337 13.9746 8.8418C13.0488 8.8418 12.123 8.8418 11.1973 8.8418C11.1973 8.44337 11.1973 8.04491 11.1973 7.64648Z" fill="black"/>
<path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M3.99023 10.248C4.916 10.248 5.84181 10.248 6.76758 10.248C6.76758 10.6465 6.76758 11.0449 6.76758 11.4434C5.84181 11.4434 4.916 11.4434 3.99023 11.4434C3.99023 11.0449 3.99023 10.6465 3.99023 10.248Z" fill="black"/>
<path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M11.1973 10.248C12.123 10.248 13.0488 10.248 13.9746 10.248C13.9746 10.6465 13.9746 11.0449 13.9746 11.4434C13.0488 11.4434 12.123 11.4434 11.1973 11.4434C11.1973 11.0449 11.1973 10.6465 11.1973 10.248Z" fill="black"/>
<path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M3.99023 12.8848C4.916 12.8848 5.84181 12.8848 6.76758 12.8848C6.76758 13.2832 6.76758 13.6817 6.76758 14.0801C5.84181 14.0801 4.916 14.0801 3.99023 14.0801C3.99023 13.6817 3.99023 13.2832 3.99023 12.8848Z" fill="black"/>
<path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M11.1973 12.8848C12.123 12.8848 13.0488 12.8848 13.9746 12.8848C13.9746 13.2832 13.9746 13.6817 13.9746 14.0801C13.0488 14.0801 12.123 14.0801 11.1973 14.0801C11.1973 13.6817 11.1973 13.2832 11.1973 12.8848Z" fill="black"/>
<path opacity="0.953" fill-rule="evenodd" clip-rule="evenodd" d="M15.5918 13.5527C15.9902 13.5527 16.3887 13.5527 16.7871 13.5527C16.7871 13.916 16.7871 14.2793 16.7871 14.6426C16.3887 14.6426 15.9902 14.6426 15.5918 14.6426C15.5918 14.2793 15.5918 13.916 15.5918 13.5527Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_467_517">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
    
  )
}

export default Companyl