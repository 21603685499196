// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";

// const ChartUi = (CardProps) => {
//   const userCreatedAtData = CardProps?.CardProps?.users?.map(
//     (user) => user?.createdAt
//   );
//   const applicationData = CardProps?.CardProps?.applications?.map(
//     (application) => application?.attributes?.createdAt
//   );
//   const invoiceData = CardProps?.CardProps?.invoices?.map(
//     (invoice) => invoice?.attributes?.createdAt
//   );

//   const [selectedData, setSelectedData] = useState("users");

//   const [chartData, setChartData] = useState({
//     options: {
//       chart: {
//         id: "bar-chart",
//       },
//       xaxis: {
//         categories: [],
//       },
//     },
//     series: [
//       {
//         name: "Users Created",
//         data: [],
//       },
//     ],
//   });

//   useEffect(() => {
//     const dataToDisplay =
//       selectedData === "users"
//         ? userCreatedAtData
//         : selectedData === "applications"
//         ? applicationData
//         : invoiceData;

//     const monthlyCounts = new Array(12).fill(0);

//     dataToDisplay.forEach((createdAt) => {
//       const userMonth = new Date(createdAt).getMonth();
//       monthlyCounts[userMonth]++;
//     });

//     setChartData({
//       ...chartData,
//       options: {
//         xaxis: {
//           categories: [
//             "Jan",
//             "Feb",
//             "Mar",
//             "Apr",
//             "May",
//             "Jun",
//             "Jul",
//             "Aug",
//             "Sep",
//             "Oct",
//             "Nov",
//             "Dec",
//           ],
//         },
//       },
//       series: [
//         {
//           data: monthlyCounts,
//         },
//       ],
//     });
//   }, [userCreatedAtData, applicationData, invoiceData, selectedData]);

//   const handleDataSelect = (event) => {
//     setSelectedData(event.target.value);
//   };

//   return (
//     <div className="bar-chart">
//       <div>
//         <select
//           value={selectedData}
//           onChange={handleDataSelect}
//           className="btn btn-primary btn-sm"
//           style={{ color: "white" }}
//         >
//           <option value="users">All Users</option>
//           <option value="applications">All Applications</option>
//           <option value="invoices">All Invoices</option>
//         </select>
//       </div>
//       <Chart
//         options={chartData.options}
//         series={chartData.series}
//         type="bar"
//         height={350}
//       />
//     </div>
//   );
// };

// export default ChartUi;

import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const ChartUi = (CardProps) => {
  console.log("CardProps", CardProps);
  const users = CardProps?.CardProps?.users;
  const userCreatedAtData = users?.map((user) => user?.createdAt);
  const deletedUsers = users?.filter((user) => user?.isDeleted);
  const deletedUserDeletedAtData = deletedUsers?.map(
    (user) => user?.deletedDate
  );
  // const applicationData = CardProps?.CardProps?.applications?.map(application => application?.attributes?.createdAt);
  // const invoiceData = CardProps?.CardProps?.invoices?.map(invoice => invoice?.attributes?.createdAt);

  const [selectedData, setSelectedData] = useState("users");

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "bar-chart",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "Created",
        data: [],
      },
      {
        name: "Deleted",
        data: [],
      },
    ],
  });

  useEffect(() => {
    const dataToDisplay =
      selectedData === "users"
        ? userCreatedAtData
        : selectedData === "applications"
        ? ""
        : selectedData === "invoices"
        ? ""
        : [];

    const monthlyCountsCreated = new Array(12).fill(0);
    const monthlyCountsDeleted = new Array(12).fill(0);

    userCreatedAtData.forEach((createdAt) => {
      const userMonth = new Date(createdAt).getMonth();
      monthlyCountsCreated[userMonth]++;
    });

    deletedUserDeletedAtData.forEach((deletedAt) => {
      const deletedMonth = new Date(deletedAt).getMonth();
      monthlyCountsDeleted[deletedMonth]++;
    });

    setChartData({
      ...chartData,
      options: {
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      series: [
        {
          name: "Created",
          data: monthlyCountsCreated,
        },
        {
          name: "Deleted",
          data: monthlyCountsDeleted,
        },
      ],
    });
  }, [userCreatedAtData, deletedUserDeletedAtData, selectedData]);

  const handleDataSelect = (event) => {
    setSelectedData(event.target.value);
  };

  return (
    <div className="bar-chart">
      <div>
        <select
          value={selectedData}
          onChange={handleDataSelect}
          className="btn btn-primary btn-sm"
          style={{ color: "white" }}
        >
          <option value="users">All Users</option>
          {/* <option value="applications">All Applications</option>
          <option value="invoices">All Invoices</option> */}
        </select>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ChartUi;
