import React, { useEffect, useState } from "react";
import "../Assets/BlogDetails.css";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useTranslation } from "react-i18next";
const BlogDetails = ({ eng, chinese, spanish }) => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const navigator = useHistory();

  const [singlePost, setSinglePost] = useState();
  const [recentPost, setRecentPost] = useState();
  const [categories, setCategories] = useState();

  const [search, setSearch] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;

  const getBlogData = async () => {
    const { data } = await axios.get(
      `${apiUrl}/blogs?filters[slug][$eq]=${slug}&populate=*`
    );
    setSinglePost(data.data[0]);
  };

  const getRecentBlogPosts = async () => {
    const { data } = await axios.get(
      `${apiUrl}/blogs?pagination[limit]=2&_start=0&sort=createdAt:DESC&populate=*`
    );
    setRecentPost(data.data);
  };

  const getBlogCategories = async () => {
    const { data } = await axios.get(`${apiUrl}/categories`);
    setCategories(data.data);
  };

  useEffect(() => {
    getBlogData();
    getBlogCategories();
    getRecentBlogPosts();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const sendQuery = (e) => {
    e.preventDefault();
    if (search === "") {
      return;
    }
    navigator.push(`/search/${search}`);
  };

  const publishedAt = new Date(singlePost?.attributes?.publishedAt);
  const publishedDate = publishedAt.toLocaleString("en-US", {
    timeZone: "Asia/Karachi",
    dateStyle: "full",
    // timeStyle: "medium",
  });

  return (
    <div id="main-content" className="blog-page" style={{ marginTop: "6rem" }}>
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-8 col-md-12 left-box">
            <div className="card single_post">
              <div className="body">
                <div className="img-post">
                  <img
                    className="d-block img-fluid"
                    src={`${imgUrl}${singlePost?.attributes?.coverImage?.data?.attributes?.url}`}
                    alt="First slide"
                  />
                </div>
                <h3>
                  {(eng && singlePost?.attributes?.title) ||
                    (chinese && singlePost?.attributes?.titleChinese) ||
                    (spanish && singlePost?.attributes?.titleSpanish) ||
                    singlePost?.attributes?.title}
                </h3>
                <p
                  style={{
                    color: "#999",
                    fontSize: "0.8rem",
                  }}
                >
                  {publishedDate}
                </p>

                <hr />
                <div className="blogContent">

                <ReactMarkdown>
                  {(eng && singlePost?.attributes?.content) ||
                    (chinese && singlePost?.attributes?.contentChinese) ||
                    (spanish && singlePost?.attributes?.contentSpanish) ||
                    singlePost?.attributes?.content}
                </ReactMarkdown>
              </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-12 right-box "
            style={{ marginTop: "7rem" }}
          >
            <div className="card">
              <div className="body search">
                <div className="input-group m-b-0">
                  <div className="input-group-prepend">
                    <button
                      type="submit"
                      style={{
                        outline: "none",
                        border: "none",
                        background: "none",
                      }}
                      onClick={sendQuery}
                    >
                      <span className="input-group-text">
                        <i className="bi bi-search"></i>
                      </span>
                    </button>
                  </div>
                  <form onSubmit={sendQuery}>
                    <input
                      type="text"
                      className="form-control"
                      style={{ height: "2.5rem" }}
                      placeholder="Search..."
                      onChange={handleSearch}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="header">
                <h2>{t("home.blogs.categories_clouds")}</h2>
              </div>
              <div className="body widget">
                <ul
                  className="list-unstyled categories-clouds m-b-0"
                  style={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {categories?.map((category) => {
                    return (
                      <li key={category?.id}>
                        <Link
                          to={`/category/${category?.attributes?.title}`}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          {category?.attributes?.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="header">
                <h2>{t("home.blogs.recent_posts")}</h2>
              </div>
              <div className="body widget popular-post">
                <div className="row">
                  <div className="col-lg-12">
                    {recentPost?.map((post) => {
                      return (
                        <div className="single_post" key={post?.id}>
                          <Link
                            to={`/blog/${post?.attributes?.slug}`}
                            style={{
                              textDecoration: "none",
                              color: "#0c3459",
                            }}
                          >
                            <p
                              className="m-b-0"
                              style={{
                                fontSize: "1.2rem",
                                marginBottom: "0.5rem",
                                fontWeight: "600",
                              }}
                            >
                              {/* {post?.attributes?.title} */}
                              {(eng && post?.attributes?.title) ||
                                (chinese && post?.attributes?.titleChinese) ||
                                post?.attributes?.titleSpanish ||
                                post?.attributes?.title}
                            </p>
                          </Link>
                          {/* <span>jun 22, 2018</span> */}
                          <div
                            className="img-post"
                            style={{ maxHeight: "13rem" }}
                          >
                            <img
                              src={`${imgUrl}${post?.attributes?.coverImage?.data?.attributes?.url}`}
                              alt="Awesome Cover"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
