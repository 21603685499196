import React from "react";
import "../Assets/Customtabs.css";
function Tab(props) {
  return <div className="">{props.content}</div>;
}

function Navigation(props) {
  const getId = (id) => {
    props.onNavClick(id);
    if (props.setCurrentTab) {
      props.setCurrentTab(id);
    }
  };
  return (
    <ul className="tabs__nav">
      {props.tabs.map((item) => (
        <li key={item.id} className="tabs__item">
          <button
            style={item.style}
            className={`tabs__button ${props.activeTabId === item.id ? "active" : ""
              }`}
            onClick={() => getId(item.id)}
          >
            {item.name}
          </button>
        </li>
      ))}
    </ul>
  );
}

function Tabs(props) {
  const [activeTabId, setActiveTab] = React.useState(props.tabs[0].id);

  const activeTab = React.useMemo(
    () => props.tabs.find((tab) => tab.id === activeTabId),
    [activeTabId, props.tabs]
  );

  return (
    <div className={`tabs`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <Navigation
              tabs={props.tabs}
              onNavClick={setActiveTab}
              activeTabId={activeTabId}
              setCurrentTab={props.setCurrentTab}
            />
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6">
            <Tab content={activeTab.content} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function TabsComponent(props) {
  return (
    <div className="">
      <Tabs tabs={props.tabs} setCurrentTab={props.setCurrentTab} />
    </div>
  );
}
