import React from "react";
import "./BenefitsContent.css";
import { useTranslation } from "react-i18next";
const BenefitsContent = () => {
    const { t, i18n } = useTranslation();
  return (
    <div className="content-area">
      <h2 className="benefit_title">{t('membership.benefits.main_heading')}</h2>
      <div className="content_main">
        <h3>{t('home.membership_benefits.partner_program.partner_program_main_heading')}</h3>
        <p>
          {t('home.membership_benefits.partner_program.partner_program_para')}
        </p>
      </div>
      <div className="content_main">
       <h3>{t('home.membership_benefits.insurance_coverage.insurance_coverage_main_heading')}</h3>
        <p>
          {t('home.membership_benefits.insurance_coverage.insurance_coverage_para')}
        </p>
      </div>
      <div className="content_main">
        <h3>{t('home.membership_benefits.repositioning.repositioning_main_heading')}</h3>
        <p>
          {t('home.membership_benefits.repositioning.repositioning_para')}
        </p>
      </div>
      <div className="content_main">
       <h3>{t('home.membership_benefits.tracking.tracking_main_heading')}</h3>
        <p>
          {t('home.membership_benefits.tracking.tracking_para')}
        </p>
      </div>
    </div>
  );
};

export default BenefitsContent;
