import { useState } from "react";
import "./DashboardManager.css";
import { useTranslation } from "react-i18next";
import Members from "../../Assets/Icons/Members";
import Dashboards from "../../Assets/Icons/Dashboards";
import UsersIcon from "../../Assets/Icons/UsersIcon";
import ApplicationsIcon from "../../Assets/Icons/ApplicationsIcon";
import InvoicesIcon from "../../Assets/Icons/InvoicesIcon";
import ReviewIcon from "../../Assets/Icons/ReviewIcon";
import SettingIcon from "../../Assets/Icons/SettingIcon";

const iconMapping = {
  analytics: <Dashboards />,
  users: <UsersIcon />,
  apps: <ApplicationsIcon />,
  invoices: <InvoicesIcon />,
  edit: <ReviewIcon />,
  settings: <SettingIcon />,
};

const DashboardTabs = (props) => {
  const { t, i18n } = useTranslation();
  const liItems = dashboardNavItems.map((item) => {
    const selected = item.id === props.selectedTab;
    const tabIcon = iconMapping[item.id];
    if (item.id === "settings") {
      return (
        <li
          key={item.id}
          className={`nav-item dashboard-tabs-item dropdown ${
            selected ? "dashboard-tabs-item-active active" : ""
          }`}
          // onClick={(e) => props.setTab(item.id)}
        >
          {/* <div
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              color: "black",
              fontSize: "1rem",
              display: "flex",

              alignItems: "center",
            }}
          >
            {tabIcon} {item.label}
          </div> */}
          {/* <ul className="dropdown-menu">
            {item?.tabs?.map((tab) => {
              return (
                <li
                  onClick={(e) => props.setTab(tab.id)}
                  className="dropdown-item"
                  key={tab.id}
                  // style={{ color: selected ? "rgba(3, 78, 162, 1)" : "black"}}
                >
                  {tab.label}
                </li>
              );
            })}
          </ul> */}
          <div class="c-dropdown">
            {/* <button class="dropbtn">Settings</button> */}
            <div
              className="nav-link dropdown-toggle"
              style={{
                color: "black",
                fontSize: "1rem",
                display: "flex",

                alignItems: "center",
              }}
            >
              {tabIcon} {item.label}
            </div>
            <div class="dropdown-content ">
              {item?.tabs?.map((tab) => {
                return (
                  <li
                    onClick={(e) => props.setTab(tab.id)}
                    className="dropdown-item"
                    key={tab.id}
                  >
                    {tab.label}
                  </li>
                );
              })}
            </div>
          </div>
        </li>
      );
    }
    return (
      <li
        key={item.id}
        className={`nav-item dashboard-tabs-item ${
          selected ? "dashboard-tabs-item-active active" : ""
        }`}
        onClick={(e) => props.setTab(item.id)}
      >
        <div
          className="nav-link"
          style={{
            color: selected ? "rgba(3, 78, 162, 1)" : "black",
            marginBottom: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {tabIcon}
          <span style={{ marginLeft: "0.3rem", fontSize: "1rem" }}>
            {item.label}
          </span>
          <span
            className="dot"
            style={{
              background: selected ? "blue" : "transparent",
              marginLeft: "0.5rem",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
            }}
          ></span>
        </div>
      </li>
    );
  });

  return (
    <div
      className="dashboard-tabs"
      style={{
        width: props.collapseMenu ? "0" : "200px",
        overflow: "hidden",
      }}
    >
      <ul className="nav nav-tabs">{liItems} </ul>
      <div
        className="dashboard-tabs-Collapse"
        style={{ marginLeft: "0.7rem" }}
        onClick={() => props.setCollapseMenu(true)}
      >
        <span>
          {t("members.collapse")}
          <svg
            width="1rem"
            height="1rem"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mx-5"
          >
            <path
              d="M9.88 14.12 3.773 8 9.88 1.88 8 0 0 8l8 8 1.88-1.88Z"
              fill="black"
            ></path>
          </svg>
        </span>
      </div>
      <div
        className="unCollapse-menu"
        onClick={() => props.setCollapseMenu(false)}
        style={{ display: props.collapseMenu ? "block" : "none" }}
      >
        <span
          style={{
            marginLeft: "3rem",
            cursor: "pointer",
            background: "#034ea2",
            padding: "0.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            position: "fixed",
            bottom: "2.8rem",
            zIndex: 1,
          }}
        >
          <svg
            width="1rem"
            height="1rem"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            class="sc-bdvvtL sc-gSQFLo dCzDuG hRlPBd"
          >
            <path
              d="M0 1.88 6.107 8 0 14.12 1.88 16l8-8-8-8L0 1.88Z"
              fill="#fff"
            ></path>
          </svg>
        </span>
      </div>
    </div>
  );
};
export default DashboardTabs;

const dashboardNavItems = [
  { label: "Analytics", id: "analytics" },
  { label: "Users", id: "users" },
  { label: "Applications", id: "apps" },
  { label: "Invoices", id: "invoices" },
  { label: "Review Edit", id: "edit" },
  {
    label: "Settings",
    id: "settings",
    tabs: [
      {
        label: "Billing",
        id: "billing",
      },
      // {
      //   label: "Nothing",
      //   id: "nothing",
      // },
    ],
  },
];
