import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import caretUp from "../Assets/SVG/caret-up-solid.svg";
import caretDown from "../Assets/SVG/caret-down-solid.svg";
import { useTranslation } from "react-i18next";
const Accordion = () => {
  const { t } = useTranslation();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const accordionData = [
    {
      title: t("accordion.benefits.networking.networking_main_heading"),
      description: t("accordion.benefits.networking.networking_desc"),
      content: t("accordion.benefits.networking.networking_contact"),
    },
    {
      title: t("accordion.benefits.boosting.boosting_main_heading"),
      description: t("accordion.benefits.boosting.boosting_desc"),
      content: t("accordion.benefits.boosting.boosting_contact"),
    },
    {
      title: t("accordion.benefits.training.training_main_heading"),
      description: t("accordion.benefits.training.training_desc"),
      content: t("accordion.benefits.training.training_contact"),
    },
    {
      title: t("accordion.benefits.representation.representation_main_heading"),
      description: t("accordion.benefits.representation.representation_desc"),
      content: t("accordion.benefits.representation.representation_contact"),
    },

    {
      title: t("accordion.benefits.innovation.innovation_main_heading"),
      description: t("accordion.benefits.innovation.innovation_desc"),
      content: t("accordion.benefits.innovation.innovation_contact"),
    },
    {
      title: t("accordion.benefits.manufactures.manufactures_heading"),
      description: t("accordion.benefits.innovation.innovation_desc"),
      content: t("accordion.benefits.manufactures.manufactures_contact"),
    },
    {
      title: t("accordion.benefits.depot.depot_heading"),
      description: t("accordion.benefits.innovation.innovation_desc"),
      content: t("accordion.benefits.depot.depot_contact"),
    },
    {
      title: t("accordion.benefits.insurance.insurance_heading"),
      description: t("accordion.benefits.innovation.innovation_desc"),
      content: t("accordion.benefits.insurance.insurance_contact"),
    },
    {
      title: t("accordion.benefits.tracking.tracking_heading"),
      description: t("accordion.benefits.innovation.innovation_desc"),
      content: t("accordion.benefits.tracking.tracking_contact"),
    },
    {
      title: t("accordion.benefits.matching.matching_heading"),
      description: t("accordion.benefits.innovation.innovation_desc"),
      content: t("accordion.benefits.matching.matching_contact"),
    },
    {
      title: t("accordion.benefits.financing.financing_heading"),
      description: t("accordion.benefits.innovation.innovation_desc"),
      content: t("accordion.benefits.financing.financing_contact"),
    },

    // ... (add more accordion items as needed)
  ];

  return (
    <div className="container">
      <div className="row">
        {/* Custom card */}
        <div className="col-12 col-md-5 col-lg-4  ">
          <div className=" mt-5" style={{ backgroundColor: "rgb(12, 52, 89)" }}>
            <div className="py-5 px-4">
              <h5
                className=" "
                style={{
                  color: "white",
                }}
              >
                {" "}
                {t("accordion.contact_heading_1")} <br />
                {t("accordion.contact_heading_2")} <br />
                {t("accordion.contact_heading_3")} <br />
              </h5>

              <h5
                className="card-title mt-5"
                style={{
                  color: "white",

                  fontWeight: "bold",
                }}
              >
                {t("accordion.contact_support")}
              </h5>

              <h5
                href="tel:Number"
                className="card-title mt-3"
                style={{
                  color: "rgb(0, 128, 206)",

                  fontWeight: "bold",
                }}
              >
                + 65 6242 3656 <br />+ 65 9633 3990
              </h5>

              <h5
                className="card-title mt-5"
                style={{
                  color: "rgb(250, 251, 251)",

                  fontWeight: "bold",
                }}
              >
                {t("accordion.contact_getInTouch")}
              </h5>

              <a
                href="mailto:Info@wnvoec.org"
                style={{ textDecoration: "none !important" }}
              >
                <h5
                  className="card-title mt-3"
                  style={{
                    color: "rgb(0, 128, 206)",

                    fontWeight: "bold",
                  }}
                >
                  Info@wnvoec.org
                </h5>
              </a>
            </div>
          </div>
        </div>

        {/* Accordion */}

        <div className="col-12 col-md-7 col-lg-8 ps-5 ">
          <h5
            className="mt-5 mb-3"
            style={{
              fontSize: "var(--h4-size)",
              fontWeight: "var(--h4-fontWeight)",
              color: "rgb(12, 59, 123)",
            }}
          >
            {t("accordion.main_heading")}
          </h5>

          <hr
            style={{ backgroundColor: "rgb(194, 209, 217)", height: "2px" }}
          />
          {accordionData.map((accordionItem, index) => (
            <div key={index}>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  backgroundColor: "white",
                }}
                type="button"
                onClick={() => toggleAccordion(index)}
              >
                <span
                  className="text-start"
                  style={{
                    color: "rgb(5, 170, 241)",
                    marginRight: "10px",
                    cursor: "pointer",
                    fontSize: "1.18rem",
                    fontWeight: "bold",
                  }}
                >
                  {accordionItem.title}
                </span>
                {/* <span
                  className="flex-grow-1 text-start p-2"
                  style={{
                    color: "rgb(180, 175, 183)",
                    fontSize: "15px",
                  }}
                >
                  {accordionItem.description}
                </span> */}
                <span
                  style={{
                    color: "rgb(112, 214, 255)",
                    // background: "rgb(12, 52, 89) round 50%",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    // fontSize: "1.5rem",
                  }}
                >
                  {openAccordionIndex === index ? (
                    <img
                      src={caretUp}
                      style={{ width: "15px" }}
                      alt="caret-up-solid"
                    />
                  ) : (
                    <img
                      src={caretDown}
                      style={{ width: "15px" }}
                      alt="caret-up-solid"
                    />
                  )}
                </span>
              </div>
              <div
                className={`accordion-collapse collapse ${openAccordionIndex === index ? "show" : ""
                  }`}
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <CSSTransition
                  in={openAccordionIndex === index}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <div
                    className="accordion-body qodef-m-text"
                    // style={{
                    //   color: "rgb(180, 175, 183)",
                    //   fontSize: "15px",
                    //   fontWeight: "bold",
                    // }}
                  >
                    {accordionItem.content}
                  </div>
                </CSSTransition>
              </div>
              <hr
                style={{ backgroundColor: "rgb(194, 209, 217)", height: "2px" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
