import React, { useState } from "react";
import "./BoardOfGovernorsMobile.css";
// import caretUp from "../Assets/SVG/caret-up-solid.svg";
import caretDown from "../Assets/SVG/caret-down-solid.svg";
import { CSSTransition } from "react-transition-group";

const BoardOfGovernorsMobile = ({ slides }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };
  return (
    <div>
      {slides?.map((accordionItem, index) => (
        <div key={index}>
          <div
            className="d-flex justify-content-between accordion-height align-items-center"
            style={
              {
                // backgroundColor: "white",
              }
            }
            type="button"
            onClick={() => toggleAccordion(index)}
          >
            <span
              className=" text-start"
              style={{
                color: "rgb(5, 170, 241)",
                fontSize: "17px",
                fontWeight: "600",
                marginRight: "10px",
              }}
            >
              <img className="img" src={accordionItem?.image} alt="" />
            </span>
            <span
              className="flex-grow-1 text-start p-2"
              style={{
                color: "rgb(180, 175, 183)",
                fontSize: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "rgb(5, 170, 241)",
                    fontWeight: "600",
                    fontSize: "1.2rem",
                  }}
                >
                  {accordionItem?.name}
                </span>
                <span style={{ fontSize: ".8rem" }}>
                  {accordionItem?.topTitle}
                </span>
              </div>
            </span>
            <span
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                // fontSize: "1.5rem",
                marginRight: "10px",
              }}
            >
              {openAccordionIndex === index ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  style={{ width: "12px", fill: "white" }}
                >
                  <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                </svg>
              ) : (
                <svg
                  style={{ width: "12px", fill: "white" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                </svg>
              )}
            </span>
          </div>
          <div
            className={`accordion-collapse collapse ${
              openAccordionIndex === index ? "show" : ""
            }`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <CSSTransition
              in={openAccordionIndex === index}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div
                className="accordion-body"
                style={{
                  color: "rgb(180, 175, 183)",
                  fontSize: "15px",
                }}
              >
                {accordionItem.description}
              </div>
            </CSSTransition>
          </div>
          <hr
            style={{ backgroundColor: "rgb(194, 209, 217)", height: "2px" }}
          />
        </div>
      ))}
    </div>
  );
};

export default BoardOfGovernorsMobile;
