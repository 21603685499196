import React from "react";
import "./PayScreen.css";
import { useTranslation } from "react-i18next";
const PayScreen = ({ invoiceData }) => {
  const { t, i18n } = useTranslation();
  const downloadUrl = `${process.env.REACT_APP_IMG_URL}${invoiceData?.attributes?.invoice?.data?.[0]?.attributes?.url}`;
  const fileUploaded =
    invoiceData?.attributes?.invoice?.data?.[0]?.attributes?.url;
  return (
    <div className="container pay-wrapper">
      {fileUploaded && (
        <>
          {" "}
          <p>
            {t('payScreen.dear_member')} <br /> <br />{t('payScreen.file_upload.congrat_1')}<br />
            <br /> {t('payScreen.file_upload.congrat_2')}{" "}
            <a href="#">info@wnvoec.org</a>. {t('payScreen.file_upload.congrat_3')}
          </p>
          <a href={downloadUrl} className="inv-btn">
            {t('payScreen.file_upload.download_invoice')}
          </a>
        </>
      )}
      {!fileUploaded && (
        <p>{t('payScreen.congrat')}
        </p>
      )}
    </div>
  );
};

export default PayScreen;
