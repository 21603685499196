import React from "react";

const IndustryIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.05859 10.877H0V13.3379H5.05859V10.877Z" fill="white" />
      <path d="M6.89062 10.877H5.60547V13.3379H6.89062V10.877Z" fill="white" />
      <path
        d="M8.47656 3.2207H8.20312V1.66211H2.46094V3.2207H2.1875C1.35825 3.2207 0.683594 3.89536 0.683594 4.72461V10.3301H5.05859V7.95117H2.35156V6.06445H5.05859V4.72461C5.05859 3.89536 4.38394 3.2207 3.55469 3.2207H3.28125V2.48242H7.38281V3.2207H7.10938C6.28012 3.2207 5.60547 3.89536 5.60547 4.72461V6.06445H9.98047V4.72461C9.98047 3.89536 9.30582 3.2207 8.47656 3.2207Z"
        fill="white"
      />
      <path
        d="M12.7207 8.14258L11.1621 6.58398H2.87109V7.4043H10.8223L11.5606 8.14258H7.71094V13.3379H14V8.14258H12.7207ZM9.89844 11.9707H9.07812V11.1504H9.89844V11.9707ZM9.89844 10.3301H9.07812V9.50977H9.89844V10.3301ZM11.2656 11.9707H10.4453V11.1504H11.2656V11.9707ZM11.2656 10.3301H10.4453V9.50977H11.2656V10.3301ZM12.6328 11.9707H11.8125V11.1504H12.6328V11.9707ZM12.6328 10.3301H11.8125V9.50977H12.6328V10.3301Z"
        fill="white"
      />
      <path
        d="M6.89062 7.95117H5.60547V10.3301H6.89062V7.95117Z"
        fill="white"
      />
    </svg>
  );
};

export default IndustryIcon;
