import React from "react";

const ApplicationsIcon = () => {
  return (
    <div>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.19999 0.399994C2.56347 0.399994 1.95302 0.65285 1.50293 1.10294C1.05284 1.55302 0.799988 2.16347 0.799988 2.79999V17.2C0.799988 17.8365 1.05284 18.447 1.50293 18.8971C1.95302 19.3471 2.56347 19.6 3.19999 19.6H12.8C13.4365 19.6 14.047 19.3471 14.497 18.8971C14.9471 18.447 15.2 17.8365 15.2 17.2V6.89679C15.1999 6.26033 14.9469 5.64998 14.4968 5.19999L10.4 1.10319C9.95 0.653075 9.33965 0.40013 8.70319 0.399994H3.19999ZM9.19999 7.59999C9.19999 7.28173 9.07356 6.97651 8.84852 6.75147C8.62347 6.52642 8.31825 6.39999 7.99999 6.39999C7.68173 6.39999 7.3765 6.52642 7.15146 6.75147C6.92642 6.97651 6.79999 7.28173 6.79999 7.59999V9.99999H4.39999C4.08173 9.99999 3.7765 10.1264 3.55146 10.3515C3.32642 10.5765 3.19999 10.8817 3.19999 11.2C3.19999 11.5183 3.32642 11.8235 3.55146 12.0485C3.7765 12.2736 4.08173 12.4 4.39999 12.4H6.79999V14.8C6.79999 15.1183 6.92642 15.4235 7.15146 15.6485C7.3765 15.8736 7.68173 16 7.99999 16C8.31825 16 8.62347 15.8736 8.84852 15.6485C9.07356 15.4235 9.19999 15.1183 9.19999 14.8V12.4H11.6C11.9182 12.4 12.2235 12.2736 12.4485 12.0485C12.6736 11.8235 12.8 11.5183 12.8 11.2C12.8 10.8817 12.6736 10.5765 12.4485 10.3515C12.2235 10.1264 11.9182 9.99999 11.6 9.99999H9.19999V7.59999Z"
          fill="black"
          fill-opacity="0.6"
        />
      </svg>
    </div>
  );
};

export default ApplicationsIcon;
