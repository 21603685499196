import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const SearchBlog = ({ eng, chinese, spanish }) => {
  const { t } = useTranslation();
  const [blogData, setBlogData] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;

  const { id } = useParams();

  const getAllBlogs = async () => {
    const { data } = await axios.get(`${apiUrl}/blogs?populate=*`);
    setBlogData(data.data);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <div className="container" style={{ marginTop: "8rem" }}>
      <h1 className="mb-5 section3 mt-5">{t('home.blogs.blog_posts')}</h1>

      <div className="blog_section row">
        {blogData
          ?.filter((post) => {
            if (id === "") {
              return post;
            } else if (
              post?.attributes?.title?.toLowerCase().includes(id?.toLowerCase())
            ) {
              return post;
            }
          })
          ?.map((post) => {
            return (
              <div className="col-lg-4 col-md-12 mb-4" key={post?.id}>
                <div className="card">
                  <div
                    className="bg-image hover-overlay ripple"
                    data-mdb-ripple-color="light"
                  >
                    <img
                      src={`${imgUrl}${post?.attributes?.coverImage?.data?.attributes?.url}`}
                      className="img-fluid"
                      alt="Blog Cover"
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <a href="#!">
                      <div
                        className="mask"
                        style={{
                          backgroundColor: "rgba(251, 251, 251, 0.15)",
                        }}
                      ></div>
                    </a>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{eng && post?.attributes?.title || chinese && post?.attributes?.titleChinese || post?.attributes?.titleSpanish || post?.attributes?.title}</h5>
                    <p className="card-text">
                      {eng && post?.attributes?.description?.slice(0, 190) || chinese && post?.attributes?.descriptionChinese?.slice(0, 190) || spanish && post?.attributes?.descriptionSpanish?.slice(0, 190) || post?.attributes?.description?.slice(0, 190)}...
                    </p>
                    <Link to={`/blog/${post?.attributes?.slug}`} className="btn btn-primary">
                      {t('btns.read')}
                    </Link>
                  </div>
                </div>
              </div>  
            );
          })}
      </div>
    </div>
  );
};

export default SearchBlog;
