import React from "react";
import LocationPin from "../../../Assets/Icons/LocationPin";
import Email from "../../../Assets/Icons/Email";
import Phone from "../../../Assets/Icons/Phone";
import countriesFlags from "../../../data/Countries";
import Container from "../../../Assets/Container";
import WebsiteAddress from "../../../Assets/WebsiteAddress";
import Companyl from "../../../Assets/Company1";

const MemberApplicationCard = ({ application, viewProfile }) => {
  const nameOfApplicantCompany =
    application.attributes.nameOfApplicantCompany || "N/A";
  const fontSize =
    nameOfApplicantCompany.length > 47
      ? "10px"
      : nameOfApplicantCompany.length > 14
      ? "12px"
      : "1.1rem";
  // console.log(nameOfApplicantCompany);

  return (
    <div className="card_wrapper-container">

    <div className="card_top">

      <div className="card_img">
        <img
          src={application.attributes.logoOfCompany}
          alt="logo"
          className="logo"
        />
      </div>
      <div className="card_top_info">
        <div
          style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}
        >
          <h3 style={{ fontSize }}>
            {application.attributes.nameOfApplicantCompany || "N/A"}
          </h3>
          {/* <h3>
          {application.attributes.nameOfApplicantCompany &&
          application.attributes.nameOfApplicantCompany.length > 20
            ? application.attributes.nameOfApplicantCompany.substring(0, 20) +
              "..."
            : application.attributes.nameOfApplicantCompany || "N/A"}
        </h3> */}

          <h6>
            {" "}
            {/* <LocationPin color={"black"} />{" "} */}
            {/* {application.attributes.country || "N/A"} */}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={application.attributes.country}
            >
              <img
                src={countriesFlags[application.attributes.country]?.mini}
                alt={application.attributes.country}
                className="countries-boxShadow"
                width="25"
                height="15"
              />
            </div>
          </h6>
        </div>
        <h6>
          {" "}
          <Phone color={"black"} />
          {/* {application.attributes.telephoneNumber || "N/A"} */}
          <a
            style={{ fontSize: "0.8rem" }}
            href={`tel:${application.attributes.telephoneNumber || "N/A"}`}
          >
            {application.attributes.telephoneNumber || "N/A"}
          </a>
        </h6>
        <h6>
          <Email color={"black"} />
          <a
            style={{
              textDecoration: "none",
              color: "#3590f8",
              fontSize: "0.8rem",
            }}
            href={"mailto:" + application.attributes.emailAddressOfCompany}
            target="_blank"
          >
            {application.attributes.emailAddressOfCompany || "N/A"}
          </a>
        </h6>
      </div>
      
    </div>


    <div className="card_bottom">

      <div className="card_bottom-row">
        <h5  title="Industry Classification" style={{ position: 'relative' }}>
       
    
  
          <Container />
        </h5>
        <span>{application.attributes.industryClassification || "N/A"}</span>
      </div>
      <div className="card_bottom-row">
        <h5  title="Website Address" style={{ position: 'relative' }}>
          <WebsiteAddress />
        </h5>
        <a style={{ wordBreak: 'break-all' }}
          href={`https://${application.attributes.websiteAddressOfCompany}`}
          target="_blank"
        >
          {/* {application.attributes.websiteAddressOfCompany || "N/A"} */}
          {application.attributes.websiteAddressOfCompany &&
          application.attributes.websiteAddressOfCompany.length > 100
            ? application.attributes.websiteAddressOfCompany.substring(
                0,
                100
              ) + "..."
            : application.attributes.websiteAddressOfCompany || "N/A"}
        </a>
      </div>
      <div className="card_bottom-row">
        <h5 title="Company Type" style={{ position: 'relative' }}>
          <Companyl />
        </h5>
        <span>{application.attributes.companyType || "N/A"}</span>
      </div>
    </div>

    <div className="card_button">
      <button onClick={() => viewProfile(application.attributes)}>
        VIEW
      </button>
    </div>
    </div>
  );
};

export default MemberApplicationCard;
