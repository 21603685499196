import React, { useEffect, useState } from "react";
import { apiCaller } from "../../../utils/api";

const DashboardBillings = () => {
  const [ordinaryAmount, setOrdinaryAmount] = useState(0);
  const [affiliateAmount, setAffiliateAmount] = useState(0);
  const [associateAmount, setAssociateAmount] = useState(0);
  const [foundingMemberAmount, setFoundingMemberAmount] = useState(0);

  const ordinaryAmountUpdate = async (amount) => {
    const response = await apiCaller("put", "setting", {
      data: {
        ordinaryAmount: amount,
      },
    });

    if (response.err) console.log(response.err);
    else {
      setOrdinaryAmount(response?.data?.attributes?.ordinaryAmount);
    }
  };

  const associateAmountUpdate = async (amount) => {
    const response = await apiCaller("put", "setting", {
      data: {
        assiciateAmount: amount,
      },
    });

    if (response.err) console.log(response.err);
    else {
      setAssociateAmount(response?.data?.attributes?.assiciateAmount);
    }
  };

  const foundingMemberAmountUpdate = async (amount) => {
    const response = await apiCaller("put", "setting", {
      data: {
        foundingMemberAmount: amount,
      },
    });

    if (response.err) console.log(response.err);
    else {
      setFoundingMemberAmount(response?.data?.attributes?.foundingMemberAmount);
    }
  };
  const affiliateAmountUpdate = async (amount) => {
    const response = await apiCaller("put", "setting", {
      data: {
        affiliateAmount: amount,
      },
    });

    if (response.err) console.log(response.err);
    else {
      setAffiliateAmount(response?.data?.attributes?.affiliateAmount);
    }
  };

  const getSettings = async () => {
    const response = await apiCaller("get", "setting");
    if (response.err) console.log(response.err);
    else {
      setOrdinaryAmount(response?.data?.attributes?.ordinaryAmount);
      setAssociateAmount(response?.data?.attributes?.assiciateAmount);
      setFoundingMemberAmount(response?.data?.attributes?.foundingMemberAmount);
      setAffiliateAmount(response?.data?.attributes?.affiliateAmount);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div>
      <div style={{ marginBottom: "3rem" }}>
        <h3>Billings</h3>
      </div>
      <div
        style={{
          paddingRight: "2rem",
        }}
      >
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Ordinary
          </label>
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <input
              value={ordinaryAmount}
              type="number"
              style={{ width: "30%" }}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Amount (1200)"
              onChange={(e) => {
                setOrdinaryAmount(e.target.value);
              }}
            />
            <button
              onClick={() => ordinaryAmountUpdate(ordinaryAmount)}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Associate
          </label>
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <input
              value={associateAmount}
              type="number"
              style={{ width: "30%" }}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Amount (1200)"
              onChange={(e) => {
                setAssociateAmount(e.target.value);
              }}
            />
            <button
              onClick={() => associateAmountUpdate(associateAmount)}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Founding Member
          </label>
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <input
              value={foundingMemberAmount}
              type="number"
              style={{ width: "30%" }}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Amount (1200)"
              onChange={(e) => {
                setFoundingMemberAmount(e.target.value);
              }}
            />
            <button
              onClick={() => foundingMemberAmountUpdate(foundingMemberAmount)}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Affiliate Member
          </label>
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <input
              value={affiliateAmount}
              type="number"
              style={{ width: "30%" }}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Amount (1200)"
              onChange={(e) => {
                setAffiliateAmount(e.target.value);
              }}
            />
            <button
              onClick={() => affiliateAmountUpdate(affiliateAmount)}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBillings;
