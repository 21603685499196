import React from 'react'

const Dashboards = () => {
  return (
    <div>
        <svg width="18" height="18" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
<path d="M21.5 10.9V4.1C21.5 2.6 20.86 2 19.27 2L15.23 2C13.64 2 13 2.6 13 4.1L13 10.9C13 12.4 13.64 13 15.23 13L19.27 13C20.86 13 21.5 12.4 21.5 10.9Z" fill="black" fill-opacity="0.6"/>
<path d="M11 13.1L11 19.9C11 21.4 10.36 22 8.77 22H4.73C3.14 22 2.5 21.4 2.5 19.9L2.5 13.1C2.5 11.6 3.14 11 4.73 11L8.77 11C10.36 11 11 11.6 11 13.1Z" fill="black" fill-opacity="0.6"/>
<path d="M21.5 19.9V17.1C21.5 15.6 20.86 15 19.27 15L15.23 15C13.64 15 13 15.6 13 17.1V19.9C13 21.4 13.64 22 15.23 22H19.27C20.86 22 21.5 21.4 21.5 19.9Z" fill="black" fill-opacity="0.6"/>
<path d="M11 6.9V4.1C11 2.6 10.36 2 8.77 2L4.73 2C3.14 2 2.5 2.6 2.5 4.1L2.5 6.9C2.5 8.4 3.14 9 4.73 9H8.77C10.36 9 11 8.4 11 6.9Z" fill="black" fill-opacity="0.6"/>
</svg>

    </div>
  )
}

export default Dashboards