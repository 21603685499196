import React from 'react'

const Search2 = () => {
  return (
    <div>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0003 17.3333H8.00033C7.20033 17.3333 6.66699 16.8 6.66699 16C6.66699 15.2 7.20033 14.6667 8.00033 14.6667H24.0003C24.8003 14.6667 25.3337 15.2 25.3337 16C25.3337 16.8 24.8003 17.3333 24.0003 17.3333Z" fill="black" fill-opacity="0.6"/>
<path d="M20.0003 25.3333H12.0003C11.2003 25.3333 10.667 24.8 10.667 24C10.667 23.2 11.2003 22.6667 12.0003 22.6667H20.0003C20.8003 22.6667 21.3337 23.2 21.3337 24C21.3337 24.8 20.8003 25.3333 20.0003 25.3333Z" fill="black" fill-opacity="0.6"/>
<path d="M28.0003 9.33332H4.00033C3.20033 9.33332 2.66699 8.79999 2.66699 7.99999C2.66699 7.19999 3.20033 6.66666 4.00033 6.66666H28.0003C28.8003 6.66666 29.3337 7.19999 29.3337 7.99999C29.3337 8.79999 28.8003 9.33332 28.0003 9.33332Z" fill="black" fill-opacity="0.6"/>
</svg>

    </div>
  )
}

export default Search2