import React, { useEffect, useState } from "react";
import "./AuthForm.css";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import { toast } from "react-toastify";
import axios from "axios";

const AuthForm = ({ getUsers, modalRef }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [listOfCountries, setListOfCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [listOfStates, setListOfStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [listOfCities, setListOfCities] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectedCountryIso = listOfCountries.find(
    (country) => country.value === selectedCountry
  )?.iso;

  const selectedStateIso = listOfStates.find(
    (state) => state.value === selectedState
  )?.iso;

  useEffect(() => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
    );

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data?.map((item) => ({
            value: item.name,
            iso: item.iso2,
          }))
        )
      )
      .catch((error) => {
        // console.log("error", error)
      });
  }, []);
  useEffect(() => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
    );

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      `https://api.countrystatecity.in/v1/countries/${selectedCountryIso}/states`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) =>
        setListOfStates(
          data?.map((item) => ({
            value: item.name,
            iso: item.iso2,
          }))
        )
      )
      .catch((error) => {
        // console.log("error", error)
      });
  }, [selectedCountry, selectedCountryIso]);

  useEffect(() => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
    );

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      `https://api.countrystatecity.in/v1/countries/${selectedCountryIso}/states/${selectedStateIso}/cities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) =>
        setListOfCities(
          data?.map((item) => ({
            value: item.name,
          }))
        )
      )
      .catch((error) => {
        // console.log("error", error)
      });
  }, [selectedCountry, selectedStateIso]);

  const methods = useForm({
    defaultValues: {
      username: "",
      name: "",
      email: "",
      companyName: "",
      companyEmail: "",
      countryCode: "+",
      phoneNumber: "",
      country: "",
      state: "",
      city: "",
      website: "",
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data) => {
    if (data?.password !== data?.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    data.username = data.email;
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/auth/local/register`,
        {
          ...data,
          invoiceGenerate: true,
          applicationGenerate: true,
          confirmed: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //   setError(null);
      if (response.data) {
        methods.reset();
        setLoading(false);
        getUsers();
        modalRef.current.className = "modal fade";
        document.getElementsByClassName("modal-backdrop")[0].remove();
      }
      //   toast.info("Verification Email Sent");

      //   setShowPopup(true);
      // console.log(response);
    } catch (error) {
      // setError(error.response.data.error.message);
      //   toast.error(error.response.data.error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="form form-custom"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          {/* <h4 className="formHeading">{t("signup.become_member")}</h4> */}
          <div className="diffSection">
            <h5 className="diffSection_heading">Personal Info</h5>
            <div className="country-city">

              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Name"
                  name="name"
                  style={{width:"15rem"}}
                  
                  {...methods.register("name", { required: true })}
                />
                <label htmlFor="floatingInput">
                  Your Name <span style={{ color: "red" }}>*</span>
                </label>
                

              </div>

              <div className="form-floating ">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
                style={{width:"14rem"}}
                {...methods.register("email", { required: true })}
              />
              <label htmlFor="floatingInput">
                {t("signup.email")} <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            </div>

           

            <div className="country-city">
              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Country Code"
                  maxLength={4}
                  name="countryCode"
                  {...methods.register("countryCode", {
                    required: true,
                    maxLength: 4,
                  })}
                  style={{ width: "9rem" }}
                />
                <label htmlFor="floatingInput">
                  {t("signup.country_code")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  style={{ width: "20rem" }}
                  {...methods.register("phoneNumber", { required: true })}
                />
                <label htmlFor="floatingInput">
                  {t("signup.number")} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
            </div>
          </div>
          
          <div className="diffSection">
            <h5 className="diffSection_heading">Company Info</h5>

            <div className="country-city">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Country"
                  name="country"
                  
                  list="Country"
                  autocomplete="off"
                  {...methods.register("country", { required: true })}
                  style={{ width: "15rem" }}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                />
                <label htmlFor="floatingInput">
                  {t("signup.country")} <span style={{ color: "red" }}>*</span>
                </label>
                <datalist id="Country">
                  {listOfCountries?.map((item) => {
                    return <option key={item.value} value={item.value} />;
                  })}
                </datalist>
              </div>
              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="State"
                  name="state"
                  list="State"
                  autocomplete="off"
                  {...methods.register("state", { required: true })}
                  style={{ width: "15rem" }}
                  onChange={(e) => setSelectedState(e.target.value)}
                />
                <label htmlFor="floatingInput">
                  {t("signup.state")} <span style={{ color: "red" }}>*</span>
                </label>
                <datalist id="State">
                  {listOfStates?.map((item) => {
                    return <option value={item.value} />;
                  })}
                </datalist>
              </div>

              </div>
              </div>

              <div className="diffSection">


            <div className="country-city">

              <div className="form-floating ">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  autocomplete="off"
                  placeholder="City"
                  name="city"
                  list="City"
                  {...methods.register("city", { required: true })}
                  style={{ width: "15rem" }}
                />
                <label htmlFor="floatingInput">
                  {t("signup.city")} <span style={{ color: "red" }}>*</span>
                </label>
                <datalist id="City">
                  {listOfCities?.map((item) => {
                    return <option value={item.value} />;
                  })}
                </datalist>
              </div>
           
            
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingPassword"
                placeholder="Website"
                name="website"
                style={{ width: "15rem" }}
                {...methods.register("website", { required: true })}
              />
              <label htmlFor="floatingPassword">
                {t("signup.website")} <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            </div>
            </div>

            <div className="diffSection">
          

            <div className="country-city">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingPassword"
                placeholder="Company Name"
                name="companyName"
                style={{ width: "15rem" }}
                {...methods.register("companyName", { required: true })}
              />
              <label htmlFor="floatingPassword">
                {
                  // t("signup.website")
                  "Company Name "
                }
                <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingPassword"
                placeholder="Company Email"
                name="companyEmail"
                style={{ width: "15rem" }}
                {...methods.register("companyEmail")}
              />
              <label htmlFor="floatingPassword">
                {
                  // t("signup.website")
                  "Company Email "
                }
              </label>
            </div>

            </div>
            </div>
          

          <div className="diffSection">
            <h5 className="diffSection_heading">Password</h5>
              <div className="country-city">
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                name="password"
                style={{width:"15rem"}}
                {...methods.register("password", { required: true })}
              />
              <label htmlFor="floatingPassword">{t("signup.password")}</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Confirm Password"
                name="confirmPassword"
                style={{width:"15rem"}}
                {...methods.register("confirmPassword", { required: true })}
              />
              <label htmlFor="floatingPassword">
                {t("signup.confirm_password")}
              </label>
            </div>
            </div>
          </div>
          {/* <button
            className="btn btn-purple small-btn btn-md py-2"
            disabled={loading}
            style={{
              fontWeight: "600",
              textTransform: "uppercase",
            }}
          >
            {t("signup.signup")}
          </button> */}
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >

                <button
             data-bs-dismiss="modal"
             aria-label="Close"
            
             
              className="invoice_file_label-2"
            >
             Cancel
            </button>
             
            <button
              type="submit"
              disabled={loading}
              aria-label="Close"
              className="invoice_file_label-3"
            >
              Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;
