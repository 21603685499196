import React from 'react'

const Members = () => {
  return (
    <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="24" fill="#F0C274"/>
<path opacity="0.982" fill-rule="evenodd" clip-rule="evenodd" d="M23.5781 11.1987C27.2006 11.337 29.3726 13.1807 30.0938 16.7299C30.2933 19.785 29.0276 21.9881 26.2969 23.3393C22.941 24.3877 20.3316 23.4345 18.4688 20.4799C17.1588 17.4476 17.7057 14.8069 20.1094 12.558C21.1467 11.7804 22.303 11.3273 23.5781 11.1987Z" fill="white"/>
<path opacity="0.973" fill-rule="evenodd" clip-rule="evenodd" d="M19.7344 23.7612C20.2114 23.9527 20.6801 24.1714 21.1406 24.4174C21.2187 26.5105 21.2656 28.6042 21.2812 30.6987C21.9375 31.605 22.5937 32.5111 23.25 33.4174C23.4438 33.6584 23.6781 33.8459 23.9531 33.9799C24.2281 33.8459 24.4625 33.6584 24.6562 33.4174C25.3125 32.5111 25.9687 31.605 26.625 30.6987C26.6406 28.6042 26.6875 26.5105 26.7656 24.4174C27.1982 24.2191 27.62 24.0004 28.0312 23.7612C31.4402 25.3881 33.4401 28.0756 34.0312 31.8237C34.1544 33.2582 34.2013 34.6958 34.1719 36.1362C32.8281 36.1362 31.4843 36.1362 30.1406 36.1362C30.1406 35.6362 30.1406 35.1361 30.1406 34.6362C29.7344 34.6362 29.3281 34.6362 28.9219 34.6362C28.9219 35.1361 28.9219 35.6362 28.9219 36.1362C25.6094 36.1362 22.2968 36.1362 18.9844 36.1362C18.9844 35.6362 18.9844 35.1361 18.9844 34.6362C18.5781 34.6362 18.1718 34.6362 17.7656 34.6362C17.7656 35.1361 17.7656 35.6362 17.7656 36.1362C16.4219 36.1362 15.0781 36.1362 13.7344 36.1362C13.6406 34.2105 13.7812 32.3041 14.1562 30.4174C15.0526 27.3813 16.9119 25.1625 19.7344 23.7612Z" fill="white"/>
<path opacity="0.953" fill-rule="evenodd" clip-rule="evenodd" d="M22.4531 24.8862C23.4532 25.0111 24.4531 25.0111 25.4531 24.8862C25.4688 26.7615 25.4531 28.6365 25.4063 30.5112C24.9368 31.1208 24.4525 31.7145 23.9531 32.2924C23.4538 31.7145 22.9695 31.1208 22.5 30.5112C22.4531 28.6365 22.4375 26.7615 22.4531 24.8862Z" fill="white"/>
</svg>

    </div>
  )
}

export default Members