import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import caretUp from "../Assets/SVG/caret-up-solid.svg";
import caretDown from "../Assets/SVG/caret-down-solid.svg";
import { useTranslation } from "react-i18next";
const Faq = () => {
  const { t } = useTranslation();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const accordionData = [
    {
      //   description: t('accordion.benefits.networking.networking_desc'),

      description: t("faq.Q1"),
      //   content: t('accordion.benefits.networking.networking_contact'),

      content: t("faq.A1"),
    },
    {
      //   description: t('accordion.benefits.boosting.boosting_desc'),
      description: t("faq.Q2"),
      //   content:t('accordion.benefits.boosting.boosting_contact'),
      content: t("faq.A2"),
    },

    {
      description: t("faq.Q3"),
      content: t("faq.A3"),
    },
    {
      description: t("faq.Q4"),
      content: t("faq.A4"),
    },

    {
      description: t("faq.Q5"),
      content: t("faq.A5"),
    },
    {
      description: t("faq.Q6"),
      content: t("faq.A6"),
    },
    {
      description: t("faq.Q7"),
      content: t("faq.A7"),
    },
    {
      description: t("faq.Q8"),
      content: t("faq.A8"),
    },
    {
      description: t("faq.Q9"),
      content: t("faq.A9"),
    },
    {
      description: t("faq.Q10"),
      content: t("faq.A10"),
    },
    {
      description: t("faq.Q11"),
      content: t("faq.A11"),
    },
    {
      description: t("faq.Q12"),
      content: t("faq.A12"),
    },
  ];

  return (
    <div className="container">
      <div className="row">
        {/* Accordion */}

        <div className="col-12 col-md-12">
          <h5
            className="mt-5 mb-3"
            style={{
              color: "rgb(12, 59, 123)",
              fontWeight: "700",
              fontSize: "2rem",
            }}
          >
            {/* {t('accordion.main_heading')} */}
            FAQ
          </h5>
          <hr
            style={{ backgroundColor: "rgb(194, 209, 217)", height: "2px" }}
          />
          {accordionData.map((accordionItem, index) => (
            <div key={index}>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  backgroundColor: "white",
                }}
                type="button"
                onClick={() => toggleAccordion(index)}
              >
                <span
                  className="flex-grow-1 text-start"
                  style={{
                    color: "rgb(180, 175, 183)",
                    fontSize: "1.28rem",
                    fontWeight: "500"
                  }}
                >
                  {accordionItem.description}
                </span>
                <span
                  style={{
                    color: "rgb(112, 214, 255)",
                    // background: "rgb(12, 52, 89) round 50%",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    // fontSize: "1.5rem",
                  }}
                >
                  {openAccordionIndex === index ? (
                    <img
                      src={caretUp}
                      style={{ width: "15px" }}
                      alt="caret-up-solid"
                    />
                  ) : (
                    <img
                      src={caretDown}
                      style={{ width: "15px" }}
                      alt="caret-up-solid"
                    />
                  )}
                </span>
              </div>
              <div
                className={`accordion-collapse collapse ${openAccordionIndex === index ? "show" : ""
                  }`}
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <CSSTransition
                  in={openAccordionIndex === index}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <div
                    className="accordion-body"
                    style={{
                      color: "rgb(180, 175, 183)",
                      fontSize: "1.188rem",
                      fontWeight: "500"
                    }}
                  >
                    {accordionItem.content}
                  </div>
                </CSSTransition>
              </div>
              <hr
                style={{ backgroundColor: "rgb(194, 209, 217)", height: "2px" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
