import React from "react";

const CompanyTypeIcons = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_104_1702)">
        <path
          d="M3.1377 4.42559H5.27635V5.32354H3.1377V4.42559Z"
          fill="white"
        />
        <path
          d="M3.1377 6.46747H5.27635V7.36541H3.1377V6.46747Z"
          fill="white"
        />
        <path
          d="M3.1377 8.50932H5.27635V9.40726H3.1377V8.50932Z"
          fill="white"
        />
        <path
          d="M3.1377 10.5512H5.27635V11.4491H3.1377V10.5512Z"
          fill="white"
        />
        <path
          d="M7.46248 12.6448V5.30774L12.1513 5.30227V10.2519H13.0487V4.85277C13.0487 4.60783 12.8441 4.40379 12.5995 4.40379L7.01322 4.41031C6.76562 4.41061 6.56504 4.61151 6.56504 4.85929V12.6448H1.84872V3.76029L6.56504 2.49153V3.51087H7.46248V1.90589C7.46248 1.61542 7.17796 1.39681 6.89724 1.47231L1.28348 2.98249C1.08751 3.03523 0.951282 3.21301 0.951282 3.41607V12.6448H0V13.5427H14V12.6448H7.46248Z"
          fill="white"
        />
        <path
          d="M12.1514 11.0601H13.0488V11.8965H12.1514V11.0601Z"
          fill="white"
        />
        <path
          d="M8.72363 6.46747H10.8623V7.36541H8.72363V6.46747Z"
          fill="white"
        />
        <path
          d="M8.72363 8.50932H10.8623V9.40726H8.72363V8.50932Z"
          fill="white"
        />
        <path
          d="M8.72363 10.5512H10.8623V11.4491H8.72363V10.5512Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_104_1702">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompanyTypeIcons;
