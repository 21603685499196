import React from "react";
import "./Fee.css";
import { useTranslation } from "react-i18next";

const Fee = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="content-area">
      <div className="grid_area">
        <div className="membership">
          <div className="main_title">
            <h2>
              {t('membership.fees.types')} <span>{t('membership.fees.membership')}</span>
            </h2>
          </div>
          <div className="main__content">
            <p>
              <strong>{t('membership.fees.ordinary_membership')}</strong>
              <br />
            {t('membership.fees.ordinary_membership_info')}
            </p>
            <p>
              <strong>{t('membership.fees.associate_membership')}</strong>
              <br />
             {t('membership.fees.associate_membership_info')}
            </p>
          </div>
        </div>
        <div className="structure">
          <div className="main_title">
            <h2>
              {t('membership.fees.fee')} <span>{t('membership.fees.structure')}</span>
            </h2>
          </div>
          <div className="main__content">
            <p>
              <strong>{t('membership.fees.ordinary')}</strong> {t('membership.fees.ordinary_info')} <strong>{t('membership.fees.ordinary_fee')}</strong>.
            </p>
            <p>
              <strong>{t('membership.fees.associate')}</strong>{t('membership.fees.associate_info')} <strong>{t('membership.fees.associate_fee')}</strong>.
            </p>
            <p style={{ marginTop: "1.6rem" }}>
             {t('membership.fees.fee_info')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fee;
