import React from "react";

const Fax = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5333 11.8364H10.984C10.9115 11.8364 10.8419 11.8076 10.7906 11.7563C10.7394 11.7051 10.7105 11.6355 10.7105 11.563C10.7105 11.4905 10.7394 11.4209 10.7906 11.3696C10.8419 11.3184 10.9115 11.2896 10.984 11.2896H12.5333C12.6704 11.2894 12.8018 11.2349 12.8987 11.138C12.9956 11.041 13.0502 10.9096 13.0503 10.7725V6.02972C13.0502 5.89264 12.9956 5.76123 12.8987 5.6643C12.8018 5.56738 12.6704 5.51285 12.5333 5.5127H1.46674C1.32967 5.51285 1.19825 5.56737 1.10132 5.6643C1.00439 5.76122 0.949867 5.89264 0.94971 6.02972V10.7725C0.949866 10.9096 1.00439 11.041 1.10132 11.138C1.19825 11.2349 1.32966 11.2894 1.46674 11.2896H3.01606C3.08858 11.2896 3.15813 11.3184 3.20941 11.3696C3.26069 11.4209 3.2895 11.4905 3.2895 11.563C3.2895 11.6355 3.26069 11.7051 3.20941 11.7563C3.15813 11.8076 3.08858 11.8364 3.01606 11.8364H1.46674C1.18468 11.8361 0.914252 11.7239 0.7148 11.5245C0.515347 11.325 0.403153 11.0546 0.402832 10.7725V6.02972C0.403153 5.74765 0.515347 5.47723 0.7148 5.27778C0.914252 5.07833 1.18468 4.96614 1.46674 4.96582H12.5333C12.8154 4.96614 13.0858 5.07833 13.2852 5.27778C13.4847 5.47723 13.5969 5.74765 13.5972 6.02972V10.7725C13.5969 11.0546 13.4847 11.325 13.2852 11.5245C13.0858 11.7239 12.8154 11.8361 12.5333 11.8364Z"
        fill="white"
      />
      <path
        d="M3.01611 5.51271C2.94359 5.51271 2.87404 5.4839 2.82276 5.43262C2.77148 5.38134 2.74268 5.31179 2.74268 5.23927V2.9311C2.74299 2.64903 2.85519 2.3786 3.05464 2.17915C3.2541 1.97969 3.52453 1.8675 3.8066 1.86719H9.24501C9.31753 1.86719 9.38708 1.896 9.43836 1.94728C9.48964 1.99855 9.51845 2.0681 9.51845 2.14062C9.51845 2.21315 9.48964 2.2827 9.43836 2.33397C9.38708 2.38525 9.31753 2.41406 9.24501 2.41406H3.8066C3.66952 2.41422 3.5381 2.46874 3.44117 2.56567C3.34424 2.6626 3.28972 2.79402 3.28956 2.9311V5.23927C3.28956 5.27518 3.28249 5.31073 3.26875 5.34391C3.25501 5.37709 3.23486 5.40723 3.20947 5.43262C3.18408 5.45801 3.15393 5.47815 3.12076 5.4919C3.08758 5.50564 3.05202 5.51271 3.01611 5.51271Z"
        fill="white"
      />
      <path
        d="M10.9839 5.51269C10.9114 5.51269 10.8418 5.48389 10.7905 5.43261C10.7393 5.38133 10.7104 5.31178 10.7104 5.23926V3.87964C10.7104 3.80712 10.7393 3.73757 10.7905 3.68629C10.8418 3.63501 10.9114 3.6062 10.9839 3.6062C11.0564 3.6062 11.126 3.63501 11.1772 3.68629C11.2285 3.73757 11.2573 3.80712 11.2573 3.87964V5.23926C11.2573 5.31178 11.2285 5.38133 11.1772 5.43261C11.126 5.48389 11.0564 5.51269 10.9839 5.51269Z"
        fill="white"
      />
      <path
        d="M10.1936 13.1328H3.80659C3.52452 13.1325 3.25409 13.0203 3.05464 12.8208C2.85519 12.6214 2.74299 12.351 2.74268 12.0689V9.03351C2.74268 8.96099 2.77148 8.89144 2.82276 8.84016C2.87404 8.78888 2.94359 8.76007 3.01611 8.76007C3.08863 8.76007 3.15818 8.78888 3.20946 8.84016C3.26074 8.89144 3.28955 8.96099 3.28955 9.03351V12.0689C3.28971 12.206 3.34423 12.3374 3.44116 12.4343C3.53809 12.5313 3.66951 12.5858 3.80659 12.5859H10.1936C10.3306 12.5858 10.4621 12.5312 10.559 12.4343C10.6559 12.3374 10.7104 12.206 10.7106 12.0689V9.03351C10.7106 8.96099 10.7394 8.89144 10.7907 8.84016C10.842 8.78888 10.9115 8.76007 10.984 8.76007C11.0565 8.76007 11.1261 8.78888 11.1774 8.84016C11.2287 8.89144 11.2575 8.96099 11.2575 9.03351V12.0689C11.2571 12.351 11.145 12.6214 10.9455 12.8208C10.7461 13.0203 10.4756 13.1325 10.1936 13.1328Z"
        fill="white"
      />
      <path
        d="M10.9841 4.15309H9.87749C9.63734 4.15282 9.4071 4.0573 9.23729 3.88749C9.06747 3.71768 8.97195 3.48744 8.97168 3.24729V2.14062C8.97168 2.0681 9.00049 1.99855 9.05177 1.94728C9.10305 1.896 9.1726 1.86719 9.24512 1.86719C9.31764 1.86719 9.38719 1.896 9.43847 1.94728C9.48975 1.99855 9.51855 2.0681 9.51855 2.14062V3.24729C9.51866 3.34245 9.55652 3.43368 9.62381 3.50097C9.6911 3.56826 9.78233 3.60611 9.87749 3.60621H10.9841C11.0567 3.60621 11.1262 3.63502 11.1775 3.6863C11.2288 3.73758 11.2576 3.80713 11.2576 3.87965C11.2576 3.95217 11.2288 4.02172 11.1775 4.073C11.1262 4.12428 11.0567 4.15309 10.9841 4.15309Z"
        fill="white"
      />
      <path
        d="M10.9841 4.1531C10.9482 4.15314 10.9127 4.14609 10.8795 4.13235C10.8463 4.11861 10.8162 4.09844 10.7908 4.07301L9.05177 2.33399C9.00049 2.28271 8.97168 2.21316 8.97168 2.14063C8.97168 2.06811 9.00049 1.99856 9.05177 1.94728C9.10305 1.896 9.1726 1.86719 9.24513 1.86719C9.31765 1.86719 9.3872 1.896 9.43848 1.94728L11.1775 3.6863C11.2157 3.72454 11.2418 3.77327 11.2523 3.82631C11.2629 3.87935 11.2575 3.93433 11.2368 3.9843C11.2161 4.03427 11.181 4.07697 11.1361 4.10702C11.0911 4.13706 11.0382 4.1531 10.9841 4.1531Z"
        fill="white"
      />
      <path
        d="M11.711 9.30695H2.28857C2.21605 9.30695 2.1465 9.27814 2.09522 9.22686C2.04395 9.17558 2.01514 9.10603 2.01514 9.03351C2.01514 8.96099 2.04395 8.89144 2.09522 8.84016C2.1465 8.78888 2.21605 8.76007 2.28857 8.76007H11.711C11.7835 8.76007 11.853 8.78888 11.9043 8.84016C11.9556 8.89144 11.9844 8.96099 11.9844 9.03351C11.9844 9.10603 11.9556 9.17558 11.9043 9.22686C11.853 9.27814 11.7835 9.30695 11.711 9.30695Z"
        fill="white"
      />
      <path
        d="M9.62973 10.6191H4.37012C4.2976 10.6191 4.22805 10.5903 4.17677 10.539C4.12549 10.4877 4.09668 10.4182 4.09668 10.3457C4.09668 10.2732 4.12549 10.2036 4.17677 10.1523C4.22805 10.101 4.2976 10.0722 4.37012 10.0722H9.62973C9.70225 10.0722 9.7718 10.101 9.82308 10.1523C9.87436 10.2036 9.90317 10.2732 9.90317 10.3457C9.90317 10.4182 9.87436 10.4877 9.82308 10.539C9.7718 10.5903 9.70225 10.6191 9.62973 10.6191Z"
        fill="white"
      />
      <path
        d="M9.62973 11.8206H4.37012C4.2976 11.8206 4.22805 11.7918 4.17677 11.7405C4.12549 11.6893 4.09668 11.6197 4.09668 11.5472C4.09668 11.4747 4.12549 11.4051 4.17677 11.3538C4.22805 11.3026 4.2976 11.2737 4.37012 11.2737H9.62973C9.70225 11.2737 9.7718 11.3026 9.82308 11.3538C9.87436 11.4051 9.90317 11.4747 9.90317 11.5472C9.90317 11.6197 9.87436 11.6893 9.82308 11.7405C9.7718 11.7918 9.70225 11.8206 9.62973 11.8206Z"
        fill="white"
      />
      <path
        d="M11.5533 7.78924C11.3991 7.78924 11.2485 7.74354 11.1203 7.6579C10.9921 7.57227 10.8922 7.45055 10.8333 7.30815C10.7743 7.16574 10.7588 7.00904 10.7889 6.85787C10.819 6.70669 10.8932 6.56782 11.0022 6.45883C11.1112 6.34983 11.25 6.27561 11.4012 6.24554C11.5524 6.21546 11.7091 6.2309 11.8515 6.28988C11.9939 6.34887 12.1156 6.44876 12.2013 6.57692C12.2869 6.70508 12.3326 6.85576 12.3326 7.0099C12.3324 7.21652 12.2502 7.41461 12.1041 7.56071C11.958 7.70681 11.7599 7.789 11.5533 7.78924ZM11.5533 6.77744C11.5073 6.77743 11.4624 6.79107 11.4241 6.81661C11.3859 6.84215 11.3561 6.87845 11.3385 6.92093C11.3209 6.96341 11.3163 7.01015 11.3253 7.05524C11.3342 7.10034 11.3564 7.14176 11.3889 7.17427C11.4214 7.20678 11.4628 7.22892 11.5079 7.2379C11.553 7.24687 11.5997 7.24226 11.6422 7.22467C11.6847 7.20708 11.721 7.17728 11.7465 7.13905C11.7721 7.10082 11.7857 7.05588 11.7857 7.0099C11.7857 6.94827 11.7611 6.88919 11.7176 6.84561C11.674 6.80203 11.6149 6.77751 11.5533 6.77744Z"
        fill="white"
      />
    </svg>
  );
};

export default Fax;
