// import React from "react";
// import CounterCard from "./CounterCard";
// import Counter from "../Counter";
// import OverviewCard from "./OverviewCard";

// const AnalyticsUi = (props) => {
//   return (
//     <div className="analyticsWrapper">
//       <div className="mainWrapperForCards">
//         <OverviewCard title={"All Users"} count={props?.users?.length} />

//         {/* <div className="cardWrapper"> */}
//         <CounterCard title={"Verified"} count={props?.verifiedUsers?.length} />
//         <CounterCard
//           title={"Non-Verified"}
//           count={props?.nonVerifiedUsers?.length}
//         />
//         {/* </div> */}
//       </div>
//       <div className="mainWrapperForCards">
//         <OverviewCard
//           title={"All Applications"}
//           count={props?.applications?.length}
//         />

//         {/* <div className="cardWrapper"> */}
//         <CounterCard
//           title={"Approved"}
//           count={props?.approvedApplications?.length}
//         />
//         <CounterCard
//           title={"Non-Approved"}
//           count={props?.nonApprovedApplications?.length}
//         />
//         {/* </div> */}
//       </div>
//       <div className="mainWrapperForCardsInvoice">
//         <OverviewCard title={"All Invoices"} count={props?.invoices?.length} />

//         {/* <div className="cardWrapper"> */}
//         <CounterCard title={"Paid"} count={props?.paidInvoices?.length} />
//         <CounterCard title={"Unpaid"} count={props?.unpaidInvoices?.length} />
//         <CounterCard
//           title={"Not Issued"}
//           count={props?.notIssuedInvoices?.length}
//         />
//         {/* </div> */}
//       </div>
//       <div className="mainWrapperForCards">
//         <OverviewCard
//           title={"Active Members"}
//           count={
//             props?.foundingMembers?.length + props?.ordinaryMembers?.length
//           }
//         />

//         {/* <div className="cardWrapper"> */}
//         <CounterCard
//           title={"Founding Members"}
//           count={props?.foundingMembers?.length}
//         />
//         <CounterCard
//           title={"Ordinary Members"}
//           count={props?.ordinaryMembers?.length}
//         />
//         {/* </div> */}
//       </div>
//     </div>
//   );
// };

// export default AnalyticsUi;


import React from 'react';
import Users from '../../Assets/Icons/Users';
import Applications from '../../Assets/Icons/Applications';
import Members from '../../Assets/Icons/Members';
import ChartUi from './ChartUi';
import "./AnalyticsUi.css";
const Card = (props) => {
  // console.log({props});
  return (
    <div className="container " >
      
      <div className='card pt-5 pb-5 ps-5 pe-5'>
        <h4 className='mb-3'>Overview</h4>
      <div className="row">
          
         

        <div className="col-lg-4 col-md-6 col-sm-12 col-xl-3">

          <div className="card shadow">
            <div className="card-body">
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <div className='mt-2 mb-3'>
                  <Users />
                </div>
                <h5 className="card-title mt-2 mb-3" style={{fontSize:"1.1rem"}} >ALL USERS</h5>
                <button className='mb-3 button-users'
                 
                  disabled
                >
                  {props?.users?.length}
                </button>
              </div>

              <div className='d-flex justify-content-between fw-bold '>
                <p className="card-text" style={{fontSize:"0.9rem"}}>Verified</p>
                <p style={{fontSize:"0.9rem"}}>Non Verified</p>

              </div>

              <div className='d-flex justify-content-between mb-5 mt-2'>
                <button className='button-users-2' style={{ backgroundColor: "rgb(46, 212, 122)", color: "white", }}
                  disabled>{props?.verifiedUsers?.length}</button>

                <button className='button-users-2' style={{ backgroundColor: "rgba(221, 216, 216, 1)", color: "white"}}
                  disabled>{props?.nonVerifiedUsers?.length}</button>
              </div>
            </div>
          </div>


        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-xl-3">
          <div className='card shadow'>
            <div className="card-body">
              <div className='d-flex flex-column align-items-center justify-content-center'>

                <div className='mt-2 mb-3'>
                  <Applications />
                </div>
                <h5 className="card-title  mt-2 mb-3" style={{fontSize:"1.1rem"}}>ALL APPLICATIONS</h5>
                <button className='mb-3  button-users' 
                  disabled>{props?.applications?.length}</button>
              </div>

              <div className='d-flex justify-content-between fw-bold'>
                <p className="card-text" style={{fontSize:"0.9rem"}}>Approved</p>
                <p style={{fontSize:"0.85rem"}}>Non Approved</p>
              </div>

              <div className='d-flex justify-content-between mb-5 mt-2'>
                <button  className='button-users-2'style={{ backgroundColor: "rgba(247, 104, 91, 1)", }}
                  disabled> {props?.approvedApplications?.length}</button>



                <button className='button-users-2' style={{  backgroundColor: "rgba(221, 216, 216, 1)", color: "white", }}
                  disabled>{props?.nonApprovedApplications?.length}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-xl-3">
          <div className='card shadow'>
            <div className="card-body">
            <div className='d-flex flex-column align-items-center justify-content-center'>

              <div className='mt-2 mb-2'>
                <Users />
              </div>
              <h5 className="card-title  mt-1 mb-3" style={{fontSize:"1.1rem"}}>ALL INVOICES</h5>
              <button  className='mb-3 button-users' 
                disabled>{props?.invoices?.length}</button>
                 </div>
               
                 <div className='d-flex justify-content-between fw-bold'>
              <p className="card-text" style={{fontSize:"0.9rem"}}>Paid</p>
              <p style={{fontSize:"0.9rem"}}> Un Paid</p>
              </div>
               
              <div className='d-flex justify-content-between'>
              <button className='button-users-2' style={{  backgroundColor: "rgba(112, 161, 229, 1)", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", }}
                disabled>{props?.paidInvoices?.length}</button>
              
              <button className='button-users-2' style={{  backgroundColor: "rgba(221, 216, 216, 1)", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", }}
                disabled>{props?.unpaidInvoices?.length}</button>

    </div>

                <div className='d-flex flex-column align-items-center justify-content-center fw-bold'>
                <p className="card-text">Not Issued</p>
                <button className='button-users-2' style={{  backgroundColor: "rgba(221, 216, 216, 1)", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", }}
                disabled>{props?.notIssuedInvoices?.length}</button>
                </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-xl-3">
          <div className='card shadow'>
            <div className="card-body">
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <div className='mt-1 mb-2'>
                <Members />
              </div>
              <h5 className="card-title mt-1 mb-2" style={{fontSize:"1.1rem"}}>ACTIVE MEMBERS</h5>
              <button className='mb-3 button-users' 
                disabled>{ props?.foundingMembers?.length + props?.ordinaryMembers?.length + props?.associateMembers?.length + props.affiliateMembers?.length}</button>

                      </ div>
                  
                      <div className='d-flex justify-content-between fw-bold '>
              <p className="card-text" style={{fontSize:"0.9rem"}} >Founding </p>
              <p style={{fontSize:"0.9rem"}}>Ordinary </p>
              </ div>

              
              <div className='d-flex justify-content-between mb-3 '>
              <button className='button-users-2' style={{ backgroundColor: "rgba(240, 194, 116, 1)", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", }}
                disabled>{props?.foundingMembers?.length}</button>
          
              <button className='button-users-2'  style={{  backgroundColor: "rgba(221, 216, 216, 1)", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", }}
                disabled>{props?.ordinaryMembers?.length}</button>
                </ div>
                    
                <div className='d-flex justify-content-between fw-bold '>
              <p className="card-text" style={{fontSize:"0.9rem"}} >Associate </p>
              <p style={{fontSize:"0.9rem"}}>Affiliate </p>
              </ div>

              <div className='d-flex justify-content-between  '>
                  <button className='button-users-2'  style={{  backgroundColor: "rgba(221, 216, 216, 1)", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", }}
                disabled>{props?.associateMembers?.length}</button>

                 <button className='button-users-2'  style={{  backgroundColor: "rgba(221, 216, 216, 1)", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", }}
                disabled>{props?.affiliateMembers?.length}</button>
                </ div>
                   
            </div>
          </div>
        </div>
      </div>
      </div>

       <ChartUi CardProps={props} />
      
    </div>
  );
}

export default Card;
