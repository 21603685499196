import React, { useEffect, useState } from "react";
import { apiCaller } from "../../utils/api";
import { getUser } from "../../utils/helperFunctions";
import DashboardTabs from "./DashboardTabs";
import "./DashboardManager.css";
import DashboardUsers from "./DashboardUsers/DashboardUsers";
import DashboardApplications from "./DashboardApplications/DashboardApplications";
import DashboardInvoices from "./DashboardInvoices/DashboardInvoice";
import DashboardReviewEdit from "./DashboardReviewEdit/DashboardReviewEdit";
import Analytics from "../../Components/Analytics/Analytics";
import { set } from "react-hook-form";
import DashboardSettings from "./DashboardSettings/DashboardBillings";
import DashboardBillings from "./DashboardSettings/DashboardBillings";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DashboardTab from "./DashboardTab";
// import Analytics from "./Analytics/Analytics1";

const Dashboard = (props) => {
  const user = getUser();
  const [isManager, setManager] = useState(
    user && user["member-manager"] ? true : false
  );

  const [collapseMenu, setCollapseMenu] = useState(false);

  const [userList, setUserList] = useState([]);
  const [applications, setApplications] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [reviewEditData, setReviewEditData] = useState([]);

  const [selectedTab, setTab] = useState("analytics");

  const [applicationConfirmed, setApplicationConfirmed] = useState([]);
  const [editConfirmed, setEditConfirmed] = useState([]);
  const [roleConfirmed, setRoleConfirmed] = useState([]);
  const [invoiceConfirmed, setInvoiceConfirmed] = useState([]);
  // const [grantAccess, setGrantAccess] = useState([]);
  const [invoiceYes, setInvoiceYes] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [membershipTypeValue, setMembershipTypeValue] = useState({});
  const [isEdit, setIsEdit] = useState({});
  const [membershipEdit, setMembershipEdit] = useState({});
  const [verifyEdit, setVerifyEdit] = useState({});
  const [commentsEdit, setCommentsEdit] = useState({});
  const [commentValue, setCommentValue] = useState({});
  const [receiveEdit, setReceiveEdit] = useState({});

  const [reason, setReason] = useState("");

  const [editApp, setEditApp] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();

  // const getUsers = async () => {
  //   let result = await apiCaller("get", "users");
  //   if (result.err) console.log(null);
  //   else {
  //     // console.log("getUsers");
  //     // console.log(result);
  //     setUserList(result.data ? result.data : result);
  //   }
  // };
  //  console.log("applications",applications);

  const getUsers = async () => {
    let result = await apiCaller("get", "users");
    if (result.err) console.log(null);
    else {
      // console.log("getUsers");
      // console.log(result);
      const sortedUsers = result?.sort((a, b) => {
        const dateA = new Date(a?.createdAt);
        const dateB = new Date(b?.createdAt);
        return dateA - dateB;
      });
      // console.log("su are",result);
      setUserList(sortedUsers);
    }
  };

  // const getApplications = async () => {
  //   let result = await apiCaller("get", "applications");
  //   if (result.err) console.log(null);
  //   else {
  //     // console.log("getApplications");
  //     // console.log(result);

  //     setApplications(result.data);
  //   }
  // };

  const getApplications = async () => {
    let result = await apiCaller("get", "applications");
    // console.log("result",result);
    if (result.err) console.log(null);
    else {
      // console.log("getApplications");
      // console.log(result);
      const sortedUsers2 = result?.data?.sort((a, b) => {
        const dateA = new Date(a?.attributes?.createdAt);
        const dateB = new Date(b?.attributes?.createdAt);
        return dateA - dateB;
      });

      setApplications(sortedUsers2);
    }
  };

  const getReviewEditData = async () => {
    let result = await apiCaller("get", "review-edits");
    if (result.err) console.log(null);
    else {
      setReviewEditData(result.data);
    }
  };

  const getInvoices = async () => {
    let result = await apiCaller("get", "invoices?populate=*");
    if (result.err) console.log(null);
    else {
      // console.log("getInvoices");
      // console.log(result);

      setInvoices(result.data);
    }
  };

  useEffect(() => {
    if (isManager) {
      getUsers();
      // getApplications();
      // getInvoices();
    }
  }, []);

  const loadTabAndData = (tabId) => {
    setTab(tabId);
    const methods = {
      users: getUsers,
      apps: getApplications,
      invoices: getInvoices,
      edit: getReviewEditData,
    };

    methods[tabId] && methods[tabId]();
  };

  const onAppApproveChange = async (app, val) => {
    if (val) {
      setApplicationConfirmed([
        ...applicationConfirmed,
        { val: val, appId: app.id },
      ]);
    }
  };

  const [sendReason, setSendReason] = useState("");

  const submitReason = () => {
    setSendReason(reason);
    setShowModal(false);
  };

  const onEditApproveChange = async (edit, val) => {
    if (val === "yes") {
      setEditConfirmed([...editConfirmed, { val: val, editId: edit.id }]);
    }
    if (val === "no") {
      setEditConfirmed([...editConfirmed, { val: val, editId: edit.id }]);
      setShowModal(true);
    }
  };

  const onUserRoleChange = async (user, val) => {
    if (val) {
      setRoleConfirmed([...roleConfirmed, { val: val, userId: user.id }]);
      // console.log(user.id, val);
    }
  };

  const onCommentChange = async (user, val) => {
    if (val) {
      setCommentValue({
        user: user,
        val: val,
      });

      // console.log(user.id, val);
    }
  };

  const onUserMembershipTypeChange = async (user, val, applicationsData) => {
    // const membershiptType = applicationsData?.filter((app) => app.attributes.email == user?.email)

    //  await apiCaller("put", applications/${membershiptType?.id}, body);
    setMembershipTypeValue({
      val: val,
      user: user,
    });
  };
  const onReceiveChange = async (receive, val) => {
    if (val) {
      setInvoiceConfirmed([
        ...roleConfirmed,
        { val: val, receiveId: receive.id },
      ]);
    }
  };

  // const onRoleChange = async (user, val) => {
  //   if (val === "yes") {
  //     // setGrantAccess([...grantAccess, { val: val, userId: user.id }]);
  //     console.log(user.id);
  //   }
  // };

  // const roleAccess = async () => {
  //   console.log("roleAccess");
  // };

  const AppApproveServerCall = async () => {
    if (applicationConfirmed && applicationConfirmed.length > 0) {
      let copyApplications = [...applications];
      for (let appChange of applicationConfirmed) {
        const { val, appId } = appChange;

        let result;

        if (val === "yes") {
          // let fmVal = val === "fm" ? { directAccess: true } : {};
          const body = {
            data: {
              Approved: true,
              mailTrigger: true,
              // ...fmVal,
            },
          };
          result = await apiCaller("put", `applications/${appId}`, body);
          // console.log("result", result);
          props.storeNotification(
            "admin",
            result?.data?.attributes?.email,
            " Application approved",
            ` User ${result?.data?.attributes?.name} from ${result?.data?.attributes?.nameOfApplicantCompany} organization has had their application approved`
          );
        }

        if (val === "foundingMember" || val === "ordinary") {
          const body = {
            data: {
              directAccess: val === "foundingMember" ? true : false,
              Approved: true,
              mailTrigger: false,
            },
          };
          result = await apiCaller("put", `applications/${appId}`, body);
        }

        if (
          val == "ordinary" ||
          val == "associate" ||
          val == "affiliate" ||
          val == "foundingMember"
        ) {
          const body = {
            data: {
              membershipType: val,
              mailTrigger: false,
            },
          };
          result = await apiCaller("put", `applications/${appId}`, body);
        }

        if (result) {
          setIsEdit({});
        }

        if (result.err) console.log(null);
        else {
          let aIndex = copyApplications.findIndex(
            (app) => app.id === result.data.id
          );
          if (aIndex > -1) copyApplications[aIndex] = result.data;
          // console.log("updatedApplication");
          // console.log(result);
        }
      }
      setApplications(copyApplications);
      setApplicationConfirmed([]);
    }
  };

  const editApproveServerCall = async () => {
    // console.log(editConfirmed);
    if (editConfirmed && editConfirmed.length > 0) {
      let copyReviewEditData = [...reviewEditData];
      for (let editChange of editConfirmed) {
        const { val, editId } = editChange;
        // let fmVal = val === "fm" ? { directAccess: true } : {};
        const body = {
          data: {
            approved: val === "yes" ? true : false,
            rejectionReason: sendReason,
            // ...fmVal,
          },
        };
        let result = await apiCaller("put", `review-edits/${editId}`, body);
        if (result) {
          getReviewEditData();
          if (result?.data?.attributes?.approved == true) {
            props.storeNotification(
              "admin",
              result?.data?.attributes?.email,
              "Profile edit approved",
              ` Congratulations! Your profile edit has been approved by the admin. Your changes are now live.`
            );
          }
          if (result?.data?.attributes?.approved == false) {
            props.storeNotification(
              "admin",
              result?.data?.attributes?.email,
              "Profile edit rejected",
              `Dear ${result?.data?.attributes?.name} We're sorry, but your profile edit has been rejected by the admin. Please review the feedback provided and make the necessary changes.`
            );
          }
        }
        if (result.err) console.log(null);
        else {
          let aIndex = copyReviewEditData.findIndex(
            (app) => app.id === result.data.id
          );
          if (aIndex > -1) copyReviewEditData[aIndex] = result.data;
          // console.log("updatedReviewEditData");
          // console.log(result);
        }
      }
      setReviewEditData(copyReviewEditData);
      setEditConfirmed([]);
    }
  };

  const roleApproveServerCall = async () => {
    // console.log(editConfirmed);

    if (commentValue && commentValue.val) {
      let result;

      const body = {
        comments: commentValue.val,
      };

      result = await apiCaller("put", `users/${commentValue.user.id}`, body);
      if (result) {
        getUsers();
        setCommentsEdit({});
        setCommentValue({});
      }
    }

    if (membershipTypeValue && membershipTypeValue.val) {
      let result;
      const { data } = await apiCaller(
        "get",
        `applications?filters[email][$eq]=${membershipTypeValue?.user?.email}`
      );
      const body = {
        data: {
          membershipType: membershipTypeValue.val,
          mailTrigger: false,
        },
      };
      result = await apiCaller("put", `applications/${data[0]?.id}`, body);
      if (result) {
        getUsers();
        setMembershipEdit({});
        setMembershipTypeValue({});
      }
    }
    if (roleConfirmed && roleConfirmed.length > 0) {
      // console.log("hello");
      let copyRoleEditData = [...userList];
      for (let roleChange of roleConfirmed) {
        const { val, userId } = roleChange;

        let result;

        if (val === "admin" || val === "governor" || val === "regular") {
          const body = {
            "member-manager": val === "admin" ? true : false,
            governor: val === "governor" ? true : false,
            regular: val === "regular" ? true : false,
          };
          result = await apiCaller("put", `users/${userId}`, body);
        }

        if (val === "foundingMember" || val === "ordinary") {
          const selectedUser = await apiCaller("get", `users/${userId}`);
          const { data } = await apiCaller(
            "get",
            `applications?filters[email][$eq]=${selectedUser?.email}`
          );
          const body = {
            data: {
              directAccess: val === "foundingMember" ? true : false,
              Approved: true,
              mailTrigger: false,
            },
          };
          result = await apiCaller("put", `applications/${data[0]?.id}`, body);
        }

        if (val === "yes" || val === "no") {
          const body = {
            confirmed: val === "yes" ? true : false,
          };
          result = await apiCaller("put", `users/${userId}`, body);
        }

        // if (val === "ordinary") {
        //   const selectedUser = await apiCaller("get", users/${userId});
        //   const { data } = await apiCaller(
        //     "get",
        //     invoices?filters[email][$eq]=${selectedUser?.email}
        //   );
        //   const body = {
        //     data: {
        //       received: true,
        //     },
        //   };
        //   result = await apiCaller("put", invoices/${data[0]?.id}, body);
        // }

        if (result) {
          getUsers();
          setIsEdit({});
          setMembershipEdit({});
          setVerifyEdit({});
        }
        if (result.err) console.log(null);
        // else {
        //   let aIndex = copyRoleEditData.findIndex(
        //     (app) => app?.id === result?.data?.id
        //   );
        //   if (aIndex > -1) copyRoleEditData[aIndex] = result.data;
        //   // console.log("updatedReviewEditData");
        //   // console.log(result);
        // }
      }
      // setUserList(copyRoleEditData);
      setRoleConfirmed([]);
    }
  };

  const onInvoiceReceived = (invoice, val) => {
    // if (val === "yes") {
    setInvoiceYes([...invoiceYes, { val: val, id: invoice.id }]);
    //   upateInvoice(invoice.id, { received: true });
    // }
  };
  const uploadInvoice = async (invoice, file) => {
    const formData = {
      files: file,
      ref: "api::invoice.invoice",
      refId: invoice.id,
      field: "invoice",
    };
    /*
<input type="text" name="ref" value="api::restaurant.restaurant" />
  <input type="text" name="refId" value="5c126648c7415f0c0ef1bccd" />
  <input type="text" name="field" value="cover" />
    */

    let result = await apiCaller("post", "upload", formData, {
      "Content-Type": "multipart/form-data",
    });
    if (result.err) console.log(null);
    else {
      let invoiceSingle = await apiCaller(
        "get",
        `invoices?filters[id][$eq]=${invoice.id}&populate=*`
      );

      if (invoiceSingle.err) console.log(invoiceSingle);

      const body = {
        data: {
          received: false,
          invoiceUrl:
            invoiceSingle.data[0].attributes.invoice.data[
              invoiceSingle.data[0].attributes.invoice.data.length - 1
            ].attributes.url,
        },
      };
      let result = await apiCaller("put", `invoices/${invoice.id}`, body);

      props.storeNotification(
        "admin",
        result?.data?.attributes?.email,
        "Invoice issued",
        `An invoice has been issued for your membership application. Please review the invoice details and proceed with the payment.`
      );

      let newInvoices = invoices.map((invce) =>
        invce.id === invoiceSingle.data[0].id ? invoiceSingle.data[0] : invce
      );
      setInvoices(newInvoices);
    }
  };
  const upateInvoice = async () => {
    if (invoiceYes && invoiceYes.length > 0) {
      let copyInvoices = [...invoices];
      for (let invData of invoiceYes) {
        const { id, val } = invData;

        let result;

        if (val === "yes") {
          const body = {
            data: { received: true, mcMailTrigger: true },
          };

          result = await apiCaller("put", `invoices/${id}`, body);
          props.storeNotification(
            "admin",
            result?.data?.attributes?.email,
            "Payment verified",
            `Dear ${result?.data?.attributes?.name} Your payment for the membership application has been successfully verified. You are now a full member. Enjoy the benefits!
            `
          );
        }

        if (val === "no") {
          const body = {
            data: { received: false },
          };

          result = await apiCaller("put", `invoices/${id}`, body);
        }

        if (result.err) console.log(null);
        else {
          let invIndex = copyInvoices.findIndex(
            (invce) => invce.id === result.data.id
          );
          if (invIndex > -1) copyInvoices[invIndex] = result.data;
          // console.log("updatedInvoice");
          // console.log(result);
        }
      }
      setInvoices(copyInvoices);

      setInvoiceYes([]);
    }
  };

  const deleteInvoice = async (invoice) => {
    if (window.confirm("Are you sure you want to delete this invoice?")) {
      let result = await apiCaller("delete", `invoices/${invoice.id}`);
      if (result.err) console.log(null);
      else {
        let newInvoices = invoices.filter((invce) => invce.id !== invoice.id);
        setInvoices(newInvoices);
      }
    }
  };

  // const deleteUser = async (user) => {
  //   if (window.confirm("Are you sure you want to delete this user?")) {
  //     let result = await apiCaller("delete", `users/${user.id}`);
  //     if (result.err) console.log(null);
  //     else {
  //       let newUsers = userList.filter((usr) => usr.id !== user.id);
  //       setUserList(newUsers);
  //     }
  //   }
  // };

  const deleteUser = async (user) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      let result = await apiCaller("put", `users/${user.id}`, {
        isDeleted: true,
        deletedDate: new Date(),
      });
      if (result.err) console.log(null);
      else {
        let newUsers = userList.filter((usr) => usr.id !== user.id);
        setUserList(newUsers);
      }
    }
  };

  const uploadUrl = process.env.REACT_APP_IMG_URL;
  const [logo, setLogo] = useState("");
  const [comCert, setComCert] = useState("");

  const onSubmit = async (data) => {
    try {
      const body = {
        data: {
          ...data,
          // username: user.userName,
          // email: user.email,
          // name: user.name,
          logoOfCompany: `${uploadUrl}${logo}`,
          companyRegistrationCertificate: `${uploadUrl}${comCert}`,
          mailTrigger: false,
        },
      };
      let result = await apiCaller("put", `applications/${editApp?.id}`, body);
      // console.log(result);
      if (result) {
        // setShowPopup(true);
        setEditApp(null);
      }
      if (result.err) return;
    } catch (error) {
      console.log(null);
    }
  };

  const renderMethod = () => {
    const rMethods = {
      analytics: <Analytics user={user} />,
      users: (
        <DashboardUsers
          tableData={userList}
          onUserRoleChange={onUserRoleChange}
          onCommentChange={onCommentChange}
          onUserMembershipTypeChange={onUserMembershipTypeChange}
          onSave={roleApproveServerCall}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          membershipEdit={membershipEdit}
          setMembershipEdit={setMembershipEdit}
          verifyEdit={verifyEdit}
          commentsEdit={commentsEdit}
          setVerifyEdit={setVerifyEdit}
          setCommentsEdit={setCommentsEdit}
          getUsers={getUsers}
          deleteUser={deleteUser}
        />
      ),
      apps: (
        <DashboardApplications
          tableData={applications}
          userList={userList}
          user={user}
          onAppApproveChange={onAppApproveChange}
          onSave={AppApproveServerCall}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setEditApp={setEditApp}
          editApp={editApp}
          getApplications={getApplications}
          onSubmit={onSubmit}
          setLogo={setLogo}
          setComCert={setComCert}
        />
      ),
      invoices: (
        <DashboardInvoices
          tableData={invoices}
          onInvoiceReceived={onInvoiceReceived}
          uploadInvoice={uploadInvoice}
          onSave={upateInvoice}
          user={props.user}
          setReceiveEdit={setReceiveEdit}
          receiveEdit={receiveEdit}
          deleteInvoice={deleteInvoice}
          getInvoices={getInvoices}
        />
      ),
      edit: (
        <DashboardReviewEdit
          tableData={reviewEditData}
          onEditApproveChange={onEditApproveChange}
          onSave={editApproveServerCall}
          user={props.user}
        />
      ),
      billing: <DashboardBillings />,
      // nothing: <h1>Nothing</h1>,
    };

    if (!rMethods[selectedTab]) return null;
    return rMethods[selectedTab];
  };

  if (!isManager)
    return (
      <div className="emp-profile wrapper">
        Access is available only to manager currently
      </div>
    );

  return (
    <div>
      <div className="dashboard_container dashboard_container-1">
        <DashboardTabs
          selectedTab={selectedTab}
          setTab={loadTabAndData}
          setCollapseMenu={setCollapseMenu}
          collapseMenu={collapseMenu}
        />
        <div
          className="table_container"
          style={{
            marginLeft: collapseMenu ? "0px " : "200px",
            width: collapseMenu ? "calc(100% - 0px)" : "calc(100% - 200px)",
          }}
        >
          {renderMethod()}
        </div>
        {showModal && (
          <div className="popup_container">
            <div className="popup">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  onClick={() => setShowModal(false)}
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.2em"
                    viewBox="0 0 384 512"
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </span>
              </div>
              <p className="" style={{ textAlign: "center" }}>
                <b>Reason For Rejection</b>
                <br />
                <br />
                <textarea
                  className="popup_textarea"
                  placeholder="Enter Reason"
                  style={{
                    width: "100%",
                    padding: "10px",
                    height: "16vh",
                    resize: "none",
                  }}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </p>
              <button
                className="popup_btn "
                style={{
                  backgroundColor: "#034ea2",
                  border: "none",
                  padding: ".4rem 1rem",
                  color: "white",
                  borderRadius: "5px",
                }}
                onClick={submitReason}
              >
                SUBMIT
              </button>
            </div>
          </div>
        )}
        {/* {showPopup && (
        <div className="popup_container">
          <div className="popup" style={{ height: "auto" }}>
            <p className="">
              <b>{t("editProfile.profile_update_submit")}</b>
              <br />
              <br />
              {t("editProfile.update_submit_info_1")}
              <br />
              <br />
              {t("editProfile.update_submit_info_2")}
              <br />
              <br />
              {t("editProfile.thanks")}
              <br />
              <br />
              <b>{t("editProfile.management")}</b>
            </p>
            <button
              style={{
                backgroundColor: "#034ea2",
                border: "none",
                padding: ".4rem 1rem",
                color: "white",
                borderRadius: "5px",
              }}
              onClick={() => (setEditApp(null), setShowPopup(false))}
            >
              {t("editProfile.view_profile")}
            </button>
          </div>
        </div>
      )} */}
      </div>
    </div>
  );
};

export default Dashboard;
