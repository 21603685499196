import React from "react";
import Login from "./Login";
import "../Assets/LoginPage.css";

const LoginPage = ({ setUser, socket }) => {
  return (
    <div className="main-container">
      <div className="transparentBackground form-wrapper">
        <Login setUser={setUser} socket={socket} />
      </div>
    </div>
  );
};

export default LoginPage;
