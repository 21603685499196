import React from "react";
import "../Assets/ResetPassword.css";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
const ResetPassword = () => {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigator = useHistory();
  const { search } = useLocation();
  const code = search.split("=")[1];

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/reset-password`, {
        code,
        password: data?.password,
        passwordConfirmation: data?.confirmPassword,
      });
      if (response.data) {
        toast.info("Password reset successful");
        navigator.push("/login");
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  return (
    <div className="resetContainer">
      <div className="transparentBackground form-wrapper">
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="form"
          style={{
            padding: "2rem",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <h4 className="formHeading">{t('signup.reset_password')}</h4>
          <div className="form-floating ">
            <input
              type="password"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              name="password"
              {...methods.register("password", { required: true })}
            />
            <label htmlFor="floatingInput">{t('signup.password')}</label>
          </div>
          <div className="form-floating ">
            <input
              type="password"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              name="confirmPassword"
              {...methods.register("confirmPassword", { required: true })}
            />
            <label htmlFor="floatingInput">{t('signup.confirm_password')}</label>
          </div>

          <button
            className="btn btn-purple small-btn btn-md py-2"
            style={{
              fontWeight: "600",
              textTransform: "uppercase",
            }}
          >
            {/* {forgotPassword ? "Send reset link" : "Login"} */}
            {t('signup.reset')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
