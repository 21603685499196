import React from 'react';
import "./WnTracker.css";
import ReactPlayer from 'react-player';
import { Link } from "react-router-dom";
import WnTrackerImage3 from "../../Assets/Assets/wnTracker-image-3.png"
import WnTrackerImage5 from "../../Assets/SVG/wnTracker-image-7.svg"
import WnTrackerVideo from "../../Assets/Assets/wnTracker-video.mp4"
import WnTrackerImage6 from "../../Assets/SVG/WnTracker-Logo-1.svg";
import WnTrackerImage7 from "../../Assets/SVG/WnTracker-Logo-2.svg";
import WnTrackerImage8 from "../../Assets/SVG/WnTracker-Logo-3.svg";
import WnTrackerImage9 from "../../Assets/SVG/WnTracker-Logo-4.svg";
import WnTrackerImage10 from "../../Assets/SVG/WnTracker-Logo-5.svg";
import WnTrackerImage11 from "../../Assets/SVG/WnTracker-Logo-6.svg";
import WnTrackerImage12 from "../../Assets/SVG/WnTracker-Logo-7.svg";
import WnTrackerImage13 from "../../Assets/SVG/WnTracker-Logo-8.svg";

const WnTracker = () => {

    return (
        <>
            {/* section 1 */}
            <div className=" container-fluid main-wnTracker">
                <div className="row">
                    <div className="col-md-12 wnTracker-section-1">
                        <ReactPlayer
                            url={WnTrackerVideo}
                            playing={true}
                            loop={true}
                            controls={false}
                            volume={0}
                            muted={true}
                            className='react-player'
                            width='100%'
                            height='100%'
                        />
                    </div>
                </div>

                {/* section 2 */}
                <div className="container wnTracker-section-2" style={{ marginBottom: "0.5rem", marginTop: "2rem" }}>
                    <div className="row">
                        <div className=" col-lg-1">  </div>
                        <div className=" col-lg-10">
                            <h2 className="wnTracker-heading-2">
                                SYSTEM OVERVIEW
                            </h2>
                            <p className="wnTracker-para-2 ">
                                The Wntracker utilizes various sensor & communication
                                technologies (such as GNSS/GPS, 4G/LTE) and software
                                applications to enable the asset manager to monitor
                                his assets - improving asset visibility & utilization.
                                The system is suitable for applications where Location
                                tracking is required for non-powered assets  such
                                as rental equipment, construction equipment,
                                trailers and shipping containers.
                            </p>

                        </div>
                    </div>
                    <div className=" col-lg-1"> </div>
                </div>

                {/* section 3 */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="wnTracker-heading-3">
                                WnTracker <span style={{ color: "rgba(0, 0, 0, 1)" }}>System</span>
                            </p>
                        </div>
                    </div>
                </div>

                {/* section 4 */}
                <div className="container wnTracker-section-4">
                    <div className="row">
                        <div className="col-md-12">
                            <img src={WnTrackerImage3} alt='' className='img-fluid' />
                        </div>
                    </div>
                </div>

                {/* section 5 */}
                <div className="container-fluid wnTracker-section-6">
                    <p className="wnTracker-heading-4">
                        WnTracker SETUP
                    </p>
                    <div className="container ">
                        <div className="row wnTracker-section-7">
                            <div className='wnTracker-Card mx-auto'>
                                <p className='wnTracker-para-5'>
                                    Tracker ID Number
                                </p>
                                <p className='wnTracker-para-6'>
                                    Tracker IMEI Number e.g. 912345678901234 (15 digits) <br />
                                    Note: IMEI - International Mobile <br />
                                    Equipment Identity <br />
                                    <br />
                                    Tracker ID Number e.g. 12345678901234 (14 digits) <br />
                                    Note: Last 14 digits of the IMEI
                                </p>
                            </div>
                            <div className='wnTracker-Card mx-auto'>
                                <p className='wnTracker-para-5'>
                                    Insert SIM Card
                                </p>
                                <p className='wnTracker-para-6'>
                                    To insert or change SIM card
                                </p>
                                <div className='d-flex'>
                                    <img src={WnTrackerImage5} alt='' className='img-fluid Tracker-logo-Image' />
                                    <p className='wnTracker-para-10'>
                                        Power off Device
                                    </p>
                                </div>
                                <div className='d-flex'>
                                    <img src={WnTrackerImage5} alt='' className='img-fluid Tracker-logo-Image' />
                                    <p className='wnTracker-para-10'>
                                        Insert SIM card <br />
                                        (in the correct orientation)
                                    </p>
                                </div>
                                <div className='d-flex'>
                                    <img src={WnTrackerImage5} alt='' className='img-fluid Tracker-logo-Image' />
                                    <p className='wnTracker-para-10'>
                                        Power off Device
                                    </p>
                                </div>
                            </div>
                            <div className='wnTracker-Card mx-auto'>
                                <p className='wnTracker-para-5'>
                                    Charge Tracker
                                </p>
                                <p className='wnTracker-para-6'>
                                    To charge the tracker, connect AC power <br />
                                    adapter/charger (output 5VDC, 1.5A) to  <br />
                                    tracker using USB charging cable. Note: <br />
                                    Charger is similar to one used for <br />
                                    charging mobile phone.
                                </p>
                            </div>
                            <div className='wnTracker-Card mx-auto'>
                                <p className='wnTracker-para-5'>
                                    LED Indication
                                </p>
                                <p className='wnTracker-para-12'>
                                    Power LED (Green) <br />
                                    <span className='wnTracker-para-13'>is on when charging is in progress.</span>
                                </p>
                                <p className='wnTracker-para-12'>
                                    GPS LED (Blue) <br />
                                    <span className='wnTracker-para-13'>Signal valid: Flash every 0.5 second </span>
                                    <span className='wnTracker-para-13'>Signal invalid: Flash every 2 second  </span>
                                </p>
                                <p className='wnTracker-para-12'>
                                    GSM/4G LED (Red) <br />
                                    <span className='wnTracker-para-13'>Signal valid: Flash every 0.5 second </span>
                                    <span className='wnTracker-para-13'>Signal invalid: Flash every 2 second  </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* section 6 */}
                <div className="container">
                    <p className="wnTracker-heading-5">
                        SYSTEM FUNCTIONS & FEATURES
                    </p>
                    <div className='row'>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage6} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">Easy installation</h5>
                                            <p className="card-text wnTracker-para-15">Attach tracker (with magnetic base) onto container exterior.<br />
                                                Note: No metal cover above the tracker</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage7} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">Water-resistant</h5>
                                            <p className="card-text wnTracker-para-15">Tracker can operate in wet weather Conditions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage8} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">WnTracker GPS location</h5>
                                            <p className="card-text wnTracker-para-15">of asset on a digital map</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage9} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">WnTracker API</h5>
                                            <p className="card-text wnTracker-para-15">can be API  integrated into you ERP, Other System</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage10} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">Wntracker Security</h5>
                                            <p className="card-text wnTracker-para-15">for further protection can be secured on top right hand corner with brackets(optional) and can be supplied on request</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage11} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">Geofencing</h5>
                                            <p className="card-text wnTracker-para-15">capability as required by customer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage12} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">Battery-Powered</h5>
                                            <p className="card-text wnTracker-para-15">Tracker is not connected to external power source during operation. It has an internal (rechargeable) battery which enables it to operate for a period of time</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 wnTracker-Features-Upper'>
                            <div className="mb-4">
                                <div className="row wnTracker-log-Back">
                                    <div className="col-2 my-auto wnTracker-Card-img">
                                        <img src={WnTrackerImage13} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="col-10 my-auto wnTracker-Body-Outline">
                                        <div className="card-body">
                                            <h5 className="card-title wnTracker-para-14">WnTracker</h5>
                                            <p className="card-text wnTracker-para-15">has built- in sensors which can detect vibration and movement. If WnTracker is forcibly removed, it will send a notification / alert message</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* section 7 */}
                <div className="wnTracker-section-8" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="wnTracker-content-5">
                                    <div className="productConatctBtn">
                                        <p className="wnTracker-heading-6">
                                            Log in now to manage your containers with WnTracker and access real-time tracking,
                                            detailed insights, and comprehensive updates. Not a member? Join now to revolutionize
                                            your logistics operations with our state-of-the-art tracking solution.
                                        </p>
                                    </div>
                                    <div>
                                        <Link className="text-decoration-none" to="/joinus">
                                            <button className="wnTracker-Contact-Btn">
                                                Join Us
                                            </button>
                                        </Link>
                                        <Link className="text-decoration-none" to="/login">
                                            <button className="wnTracker-Contact-Btn2">
                                                Login
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WnTracker;
