import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
const VerifyMemberLogin = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const methods = useForm({
    defaultValues: {
      identifier: "",
      password: "",
    },
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const callbackUrlEnv = process.env.REACT_APP_CALLBACK_URL;
  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    // Extract query parameters from the URL
    const params = new URLSearchParams(window.location.search);
    const gamaId = params.get("gamaId") || "";
    const membershipId = params.get("membershipId") || "";
    const networkId = params.get("networkId") || "";
    if (!gamaId || !membershipId || !networkId) {
      setLoading(false);
      setDisableForm(true);
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/loginVerifyMember`,
        {
          ...data,
          gamaId,
          membershipId,
          networkId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { token } = response.data;
      // Build the callback URL with relevant information
      const callbackUrl = `${callbackUrlEnv}?membershipId=${membershipId}&networkId=${networkId}&gamaId=${gamaId}&email=${data.identifier}&token=${token}`;
      // Redirect to the callback URL
      window.location.href = callbackUrl; // Use window.location for external redirect
    } catch (err) {
      setError("Failed to verify user. Please check your credentials.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="main-container">
      <div className="transparentBackground form-wrapper">
        {!disableForm && (
          <div>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="form"
              style={{
                padding: "2rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <h4 className="formHeading">Verify Login</h4>
              <div className="form-floating ">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  name="identifier"
                  {...methods.register("identifier", { required: true })}
                />
                <label htmlFor="floatingInput">{t("signup.email")}</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  name="password"
                  {...methods.register("password", { required: true })}
                />
                <label htmlFor="floatingPassword">{t("signup.password")}</label>
              </div>
              <button
                disabled={loading}
                className="btn btn-purple small-btn btn-md py-2"
                style={{
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {t("signup.login")}
              </button>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </form>
          </div>
        )}
        {disableForm && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              color: "#fff",
            }}
          >
            Invalid parameters. Please close this page and try again.
          </div>
        )}
      </div>
    </div>
  );
};
export default VerifyMemberLogin;
