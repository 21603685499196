import React, { useState } from "react";
import "./WnDepot.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getUser, storeUser } from "../../utils/helperFunctions";
import wnDepotImage2 from "../../Assets/Assets/wnDepot-image-2.png";

const userList = [
  {
    id: 1,
    Country: "BELGIUM",
    location: "ANTWERP",
    DepotName: "DR DEPOT",
    POC: "Fred Groenendijk",
  },
  {
    id: 2,
    Country: "DENMARK",
    location: "ARHUS",
    DepotName: "CONTAINER CARE",
    POC: "Linda Andersen",
  },
  {
    id: 3,
    Country: "GERMANY",
    location: "BREMEN",
    DepotName: "REMAIN GMBH",
    POC: "Jan Wagner",
  },
  {
    id: 4,
    Country: "NETHERLAND",
    location: "ROTTERDAM",
    DepotName: "CETEM CONTAINER BV",
    POC: "Chris De Rijke",
  },
  {
    id: 5,
    Country: "NORWAY",
    location: "LARVIK",
    DepotName: "GREENCARRIER",
    POC: "Reidunn Myklebust",
  },
  {
    id: 6,
    Country: "POLAND",
    location: "WARSAW",
    DepotName: "BALTICON",
    POC: "Daniel Wojtaszek",
  },
  {
    id: 7,
    Country: "SWEDEN",
    location: "GOTHENBURG",
    DepotName: "MEDLOG",
    POC: "Aleksandar Simic",
  },
  {
    id: 8,
    Country: "SWITZERLAND",
    location: "BASEL",
    DepotName: "CONTARGO",
    POC: "Blattner Sarah",
  },
];

const WnDepot = ({ setUser }) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      identifier: "",
      password: "",
    },
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigator = useHistory();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/auth/local`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      methods.reset();
      if (response.data) {
        storeUser(response.data);
        setUser(getUser());
        navigator.push("/members");
      }
      setLoading(false);
      toast.info(`Welcome ${response.data.user.name}`);
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.parentNode.removeChild(backdrop);
      }
      // Reset body styles to ensure interaction is enabled
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
      // console.log(response);
    } catch (error) {
      toast.error(error.response.data.error.message);
      setLoading(false);
    }
  };

  const sendResetLink = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/auth/forgot-password`, {
        email: data?.identifier,
      });
      if (response.data) {
        toast.info("Reset link sent to your email");
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  // Calculate the indexes for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = userList.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(userList.length / itemsPerPage);

  // Handler for navigating to a specific page
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handler for navigating to the next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handler for navigating to the previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {/* section 1 */}
      <div className=" container-fluid main-wnDepot">
        <div className="row">
          <div className="col-md-12">
            <div className="wnDepot-section-1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="wnDepot-content-1">
                      <h2 className="wnDepot-heading-1">WnDepot</h2>
                      <p className="wnDepot-para-1">
                        A list of all alliance depots, including Rates, Tariffs,
                        and SOPs for container movements, is available. Members
                        can contact depots directly to access these services.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 2 */}
      <div className="wnDepot-section-2">
        <div className="container">
          <div className="row ">
            <div className="col-lg-5">
              <div className="wnDepot-content-2">
                <p className="wnDepot-heading-2">About WnDepot</p>
                <p className="wnDepot-para-2">
                  WnDepot is your comprehensive solution for efficient container
                  management. Our platform offers a detailed directory of depots
                  within our alliance, providing essential information on rates,
                  tariffs, and Standard Operating Procedures (SOPs) for seamless
                  container movement. By enabling direct contact with depots, we
                  ensure that our members can easily access the services they
                  need, streamlining logistics and enhancing operational
                  efficiency. WnDepot is dedicated to supporting your business
                  with reliable and transparent depot information.
                </p>
              </div>
            </div>
            <div className="col-lg-1"> </div>
            <div className="col-lg-6">
              <div className="wnDepot-content-3">
                <img src={wnDepotImage2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 3 */}
      <div className="wnDepot-section-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="wnDepot-heading-3">WnDepot list</p>
            </div>
          </div>
          <div className="row wnDepot-section-5">
            <p className="wnDepot-para-4">All Depots</p>
            <p className="wnDepot-para-5">
              A comprehensive list of Global Alliance Depots
            </p>
            <div className="wnDepot-section-6 responsive-container">
              <div className="row wnDepot-section-7">
                <div className="col-md-3 col-sm-3">
                  <p className="wnDepot-para-6">Country Name</p>
                </div>
                <div className="col-md-3 col-sm-3">
                  <p className="wnDepot-para-6">Location</p>
                </div>
                <div className="col-md-3 col-sm-3">
                  <p className="wnDepot-para-6">Depot Name</p>
                </div>
                <div className="col-md-3 col-sm-3">
                  <p className="wnDepot-para-6">POC</p>
                </div>
              </div>
              <div className="row">
                {currentItems?.map((user) => (
                  <div key={user.id} className="wnDepot-section-7">
                    <div className="col-md-3 col-sm-3">
                      <p className="wnDepot-para-7">{user?.Country}</p>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <p className="wnDepot-para-7">{user?.location || "-"}</p>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <p className="wnDepot-para-7">{user?.DepotName}</p>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <p className="wnDepot-para-7">{user?.POC}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="btn-toolbar wnDepot-Btn">
              <button type="button" className="btn wnDepot-btn-style">
                Prev
              </button>
              <button type="button" className="btn wnDepot-btn-style1">
                1
              </button>
              <button
                type="button"
                className="btn wnDepot-btn-style"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                2
              </button>
              {/* <button type="button" className="btn wnDepot-btn-style" onClick={handlePrevPage}>Prev</button>
                            <button type="button" className="btn wnDepot-btn-style1" onClick={() => handlePageClick(1)}>1</button> */}
              {/* <button type="button" className="btn wnDepot-btn-style" onClick={() => handlePageClick(2)}>2</button> */}
              <button
                type="button"
                className="btn wnDepot-btn-style"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                ...
              </button>
              {/* <button type="button" className="btn wnDepot-btn-style" onClick={() => handlePageClick(totalPages)}>{totalPages}</button> */}
              {/* <button type="button" className="btn wnDepot-btn-style" data-bs-toggle="modal" data-bs-target="#exampleModal">{totalPages}</button> */}
              <button
                type="button"
                className="btn wnDepot-btn-style"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Next
              </button>
              {/* <button type="button" className="btn wnDepot-btn-style" onClick={handleNextPage}>Next</button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form
              onSubmit={methods.handleSubmit(
                forgotPassword ? sendResetLink : onSubmit
              )}
            >
              <h1 className="wnDepot-Modal-Heading " id="exampleModalLabel">
                Log in
              </h1>
              <div className="p-4">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control mb-4 wnDepoT-Email"
                    id="floatingInput"
                    placeholder="Email Address"
                    name="identifier"
                    {...methods.register("identifier", { required: true })}
                  />
                  <label htmlFor="floatingInput">Email Address</label>
                </div>
                {forgotPassword ? null : (
                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control wnDepoT-Email"
                      id="floatingPassword"
                      placeholder="Password"
                      name="identifier"
                      {...methods.register("password", { required: true })}
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                )}
              </div>
              <button
                className="btn mx-auto wnDepot-login-Btn"
                disabled={loading}
              >
                {forgotPassword ? "Reset Password" : "Log in"}
              </button>
              <p
                className="wnDepot-login-para1"
                onClick={() => setForgotPassword(true)}
              >
                Forgot Password?
              </p>
              <p className="wnDepot-login-para2">
                Not a member? &nbsp;
                <Link
                  to="/joinus"
                  onClick={() => {
                    const backdrop = document.querySelector(".modal-backdrop");
                    if (backdrop) {
                      backdrop.parentNode.removeChild(backdrop);
                    }
                    // Reset body styles to ensure interaction is enabled
                    document.body.classList.remove("modal-open");
                    document.body.style.overflow = "";
                    document.body.style.paddingRight = "";
                  }}
                >
                  <span
                    style={{
                      color: "rgba(3, 78, 162, 1)",
                      fontWeight: "bold",
                      textDecorationLine: "none",
                    }}
                  >
                    Join Now
                  </span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default WnDepot;
