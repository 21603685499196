import React from 'react'

const Applications = () => {
  return (
    <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="24" fill="#F7685B"/>
<g clip-path="url(#clip0_33_698)">
<path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M16.4766 11.9766C19.6797 11.9766 22.8828 11.9766 26.0859 11.9766C26.0781 13.6173 26.0859 15.2579 26.1094 16.8984C26.1825 17.9715 26.7372 18.6512 27.7734 18.9375C29.5535 19.0051 31.3348 19.0286 33.1172 19.0078C33.125 24.1172 33.1172 29.2266 33.0938 34.3359C32.8586 35.1882 32.3196 35.7351 31.4766 35.9766C26.4765 35.9766 21.4766 35.9766 16.4766 35.9766C15.6331 35.7347 15.0941 35.1878 14.8594 34.3359C14.8281 27.4297 14.8281 20.5234 14.8594 13.6172C15.0945 12.7649 15.6335 12.218 16.4766 11.9766ZM19.7578 21.8672C22.5704 21.8594 25.3829 21.8672 28.1953 21.8906C28.6144 21.9269 28.8332 22.1535 28.8516 22.5703C28.8332 22.9872 28.6144 23.2138 28.1953 23.25C25.3828 23.2813 22.5703 23.2813 19.7578 23.25C19.135 23.106 18.9554 22.7388 19.2188 22.1484C19.3738 21.9968 19.5535 21.903 19.7578 21.8672ZM19.7578 24.6797C22.5704 24.6719 25.3829 24.6797 28.1953 24.7031C28.6144 24.7394 28.8332 24.966 28.8516 25.3828C28.8332 25.7997 28.6144 26.0263 28.1953 26.0625C25.3828 26.0938 22.5703 26.0938 19.7578 26.0625C19.135 25.9185 18.9554 25.5513 19.2188 24.9609C19.3738 24.8093 19.5535 24.7155 19.7578 24.6797ZM19.7578 27.4922C22.5704 27.4844 25.3829 27.4922 28.1953 27.5156C28.6144 27.5519 28.8332 27.7785 28.8516 28.1953C28.8332 28.6122 28.6144 28.8388 28.1953 28.875C25.3828 28.9063 22.5703 28.9063 19.7578 28.875C19.135 28.731 18.9554 28.3638 19.2188 27.7734C19.3738 27.6218 19.5535 27.528 19.7578 27.4922ZM19.7578 30.3047C21.6329 30.2969 23.5079 30.3047 25.3828 30.3281C25.6893 30.3443 25.9002 30.4928 26.0156 30.7734C26.0995 31.2859 25.8886 31.5906 25.3828 31.6875C23.5078 31.7188 21.6328 31.7188 19.7578 31.6875C19.135 31.5435 18.9554 31.1763 19.2188 30.5859C19.3738 30.4343 19.5535 30.3405 19.7578 30.3047Z" fill="white"/>
<path opacity="0.975" fill-rule="evenodd" clip-rule="evenodd" d="M27.4922 12.3516C29.2484 14.0843 30.9984 15.8265 32.7422 17.5781C31.1328 17.6094 29.5234 17.6094 27.9141 17.5781C27.7187 17.5078 27.5859 17.375 27.5156 17.1797C27.4922 15.5704 27.4844 13.961 27.4922 12.3516Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_33_698">
<rect width="24" height="24" fill="white" transform="translate(12 12)"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Applications