import React from 'react'

const SearchIconm = () => {
  return (
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_323_257)">
<path d="M8.93442 18.6455C8.24364 19.3349 7.3233 19.7166 6.3431 19.7166C5.36291 19.7166 4.44468 19.337 3.75319 18.6455C3.59053 18.4829 3.44547 18.3047 3.31872 18.116V16.6049C3.31872 15.2649 4.47355 13.6157 5.8861 12.946L6.92615 12.4524C7.68312 12.5721 8.38236 12.9144 8.93442 13.4657C10.3611 14.8931 10.3611 17.2168 8.93442 18.6455ZM12.9876 11.4025C15.7486 11.4025 17.9843 7.96831 17.9843 4.8207C17.9843 1.67098 15.7486 0 12.9876 0C10.2287 0 7.99225 1.67098 7.99225 4.8207C7.99507 7.96831 10.2287 11.4025 12.9876 11.4025ZM20.0919 12.946L16.8506 11.4053L14.7135 15.85L13.6544 12.9721C13.434 13.0151 13.2171 13.07 12.9883 13.07C12.7601 13.07 12.5404 13.0151 12.3214 12.9721L11.7159 14.6163C12.0968 16.0451 11.9109 17.6034 11.111 18.9293C11.2307 18.9948 11.342 19.0751 11.4398 19.1737L12.482 20.2158H22.66V16.6077C22.66 15.2649 21.5037 13.6157 20.0919 12.946ZM14.041 22.572C14.1995 22.7318 14.2861 22.9395 14.2861 23.1635C14.2861 23.386 14.1995 23.5958 14.041 23.755C13.8812 23.9141 13.6734 24.0007 13.4495 24.0007C13.227 24.0007 13.0165 23.9141 12.858 23.755L9.85688 20.7538C9.74351 20.6397 9.66605 20.4954 9.63154 20.3341L9.54493 19.9201L9.19707 20.1616C8.35701 20.7468 7.36625 21.0566 6.33606 21.0566C5.00097 21.0566 3.74544 20.539 2.80327 19.5969C0.852035 17.6456 0.852035 14.4691 2.80327 12.5179C3.74755 11.5729 5.00378 11.054 6.34169 11.054C7.68031 11.054 8.93724 11.5729 9.88082 12.5179C11.5955 14.2318 11.8328 16.9196 10.4477 18.9117L10.2054 19.2596L10.6202 19.3462C10.7814 19.3807 10.9258 19.4574 11.0399 19.5715L14.041 22.572ZM9.33227 13.0672C8.53446 12.2693 7.47328 11.8306 6.3431 11.8306C5.21362 11.8306 4.15174 12.2693 3.35534 13.0672C1.70618 14.7149 1.70618 17.3957 3.35534 19.0441C4.15174 19.8412 5.21433 20.2806 6.3431 20.2806C7.47328 20.2806 8.53446 19.8412 9.33227 19.0441C10.98 17.3957 10.98 14.7142 9.33227 13.0672Z" fill="rgb(102, 102, 102)"/>
</g>
<defs>
<clipPath id="clip0_323_257">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default SearchIconm