import React from 'react';
import "./LuxeHome.css";
import luxehomeimage1 from "../../Assets/Assets/luxehome-image-2.png";
import luxehomeimage2 from "../../Assets/Assets/luxehome-image-3.png";
import luxehomeimage3 from "../../Assets/Assets/luxehome-image-4.png";
import luxehomeimage4 from "../../Assets/Assets/luxehome-image-5.png";
import luxehomeimage5 from "../../Assets/SVG/PremiumMaterials.svg";
import luxehomeimage6 from "../../Assets/SVG/CustomizationOptions.svg";
import luxehomeimage7 from "../../Assets/SVG/SmartHomeTechnology.svg";
import luxehomeimage8 from "../../Assets/SVG/LuxuriousAmenities.svg";
import luxehomeimage9 from "../../Assets/SVG/ExceptionalService.svg";
import luxehomeimage10 from "../../Assets/SVG/Sustainability.svg";
import { useTranslation } from 'react-i18next';
import HomeContact from './HomeContact';

const LuxeHome = () => {

    const { t } = useTranslation();

    return (
        <>
            {/* section 1 */}
            <div className=" container-fluid main-luxeHome">
                <div className="row">
                    <div className="col-md-12">
                        <div className="luxeHome-section-1">
                            <div className="luxeHome-content-1">
                                <h2 className="luxeHome-heading-1">
                                    {t("luxehomePage.luxehome")}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section 2 */}
            <div className="luxeHome-section-2">
                <div className=" container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="luxeHome-heading-2">
                                {t("luxehomePage.luxehome")}
                            </h2>
                            <p className="luxeHome-para-2">
                                {t("luxehomePage.luxehome-2")}
                            </p>
                            <h2 className="luxeHome-heading-3">
                                {t("luxehomePage.luxehome-3")}
                            </h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage5} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-4")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-5")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage6} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-6")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-7")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage7} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-8")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-9")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage8} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-10")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-11")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage9} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-12")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-13")}
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 luxehomekey'>
                            <img src={luxehomeimage10} alt="Value Home" className='img-fluid' />
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-23")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-24")}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="luxeHome-heading-4">
                                {t("luxehomePage.luxehome-14")}
                            </h2>
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-15")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-16")}
                            </p>
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-17")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-18")}
                            </p>
                            <p className="luxeHome-para-3">
                                {t("luxehomePage.luxehome-19")}
                            </p>
                            <p className="luxeHome-para-4">
                                {t("luxehomePage.luxehome-20")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="luxeHome-section-2">
                <div className=" container">
                    <div className="row">
                        <h2 className="luxeHome-heading-5">
                            <span className='luxe-span'>  {t("luxehomePage.luxehome-21")} </span>    {t("luxehomePage.luxehome-22")}
                        </h2>
                        <div className="col-md-7">
                            <div className="luxeHome-gallery mb-4">
                                <img src={luxehomeimage1} alt="Value Home" className='img-fluid mb-4' />
                                <img src={luxehomeimage2} alt="Value Home" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="luxeHome-gallery mb-5">
                                <img src={luxehomeimage3} alt="Value Home" className='img-fluid mb-4' />
                                <img src={luxehomeimage4} alt="Value Home" className='img-fluid ' />
                            </div>
                        </div>
                    </div>
                </div>
                <HomeContact />
            </div>
        </>
    )
}

export default LuxeHome
