import React from 'react'

const ViewIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_100_450)">
<path d="M10 4C6.17879 4 2.71351 6.09062 0.15649 9.48635C-0.0521632 9.76455 -0.0521632 10.1532 0.15649 10.4314C2.71351 13.8312 6.17879 15.9219 10 15.9219C13.8212 15.9219 17.2865 13.8312 19.8435 10.4355C20.0522 10.1573 20.0522 9.76864 19.8435 9.49044C17.2865 6.09062 13.8212 4 10 4ZM10.2741 14.1585C7.73755 14.3181 5.64284 12.2275 5.80239 9.68682C5.93331 7.5921 7.63118 5.89424 9.72589 5.76332C12.2625 5.60376 14.3572 7.69438 14.1976 10.235C14.0626 12.3257 12.3647 14.0235 10.2741 14.1585ZM10.1473 12.2193C8.78081 12.3052 7.65163 11.1801 7.74164 9.81364C7.81119 8.68446 8.72763 7.77212 9.85681 7.69848C11.2233 7.61256 12.3525 8.73765 12.2625 10.1041C12.1888 11.2374 11.2724 12.1497 10.1473 12.2193Z" fill="#034EA2"/>
</g>
<defs>
<clipPath id="clip0_100_450">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default ViewIcon