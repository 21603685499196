import React from 'react'

const Users = () => {
  return (
    <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="24" fill="#2ED47A"/>
<g clip-path="url(#clip0_30_533)">
<path opacity="0.987" fill-rule="evenodd" clip-rule="evenodd" d="M23.8359 13.7578C25.6988 13.8097 27.0347 14.6691 27.8438 16.3359C28.5164 18.2784 28.0867 19.9268 26.5547 21.2812C25.0352 22.3249 23.4571 22.4186 21.8203 21.5625C20.1063 20.3748 19.4891 18.7576 19.9688 16.7109C20.6446 14.9174 21.9336 13.933 23.8359 13.7578Z" fill="white"/>
<path opacity="0.982" fill-rule="evenodd" clip-rule="evenodd" d="M16.3828 17.6484C18.2852 17.665 19.4181 18.6025 19.7813 20.4609C19.8379 22.0755 19.1192 23.1849 17.625 23.7891C16.0849 24.177 14.8661 23.7239 13.9688 22.4297C13.2021 20.8813 13.4442 19.5141 14.6953 18.3281C15.2006 17.949 15.7631 17.7225 16.3828 17.6484Z" fill="white"/>
<path opacity="0.982" fill-rule="evenodd" clip-rule="evenodd" d="M31.0078 17.6484C32.8923 17.6504 34.0252 18.5723 34.4063 20.4141C34.5097 21.966 33.8612 23.0675 32.4609 23.7188C30.8064 24.2307 29.5017 23.7698 28.5469 22.3359C27.9335 21.0782 28.0429 19.8907 28.875 18.7734C29.4557 18.1434 30.1666 17.7684 31.0078 17.6484Z" fill="white"/>
<path opacity="0.988" fill-rule="evenodd" clip-rule="evenodd" d="M21.6797 22.9453C23.211 22.9375 24.7423 22.9453 26.2734 22.9688C27.7531 23.105 28.7844 23.855 29.3672 25.2188C29.4856 25.5606 29.5559 25.9121 29.5781 26.2734C29.6094 28.6953 29.6094 31.1172 29.5781 33.5391C29.5234 33.9062 29.3125 34.1172 28.9453 34.1719C25.6328 34.2031 22.3203 34.2031 19.0078 34.1719C18.6407 34.1172 18.4297 33.9062 18.375 33.5391C18.3437 31.1172 18.3437 28.6953 18.375 26.2734C18.4997 24.8209 19.2263 23.7975 20.5547 23.2031C20.9273 23.0772 21.3023 22.9913 21.6797 22.9453Z" fill="white"/>
<path opacity="0.985" fill-rule="evenodd" clip-rule="evenodd" d="M11.9766 32.5078C11.9766 30.8985 11.9766 29.2891 11.9766 27.6797C12.3382 26.0603 13.3382 25.0994 14.9766 24.7969C15.7583 24.7578 16.5395 24.7657 17.3203 24.8203C17.1975 25.1707 17.1116 25.5301 17.0625 25.8985C17.0312 28.5391 17.0312 31.1797 17.0625 33.8203C17.101 33.943 17.1245 34.068 17.1328 34.1953C15.944 34.2145 14.7565 34.1911 13.5703 34.125C12.7289 33.8846 12.1977 33.3455 11.9766 32.5078Z" fill="white"/>
<path opacity="0.985" fill-rule="evenodd" clip-rule="evenodd" d="M35.9766 27.6797C35.9766 29.2891 35.9766 30.8985 35.9766 32.5078C35.7555 33.3455 35.2242 33.8846 34.3828 34.125C33.1966 34.1911 32.0092 34.2145 30.8203 34.1953C30.8287 34.068 30.8521 33.943 30.8906 33.8203C30.9219 31.1797 30.9219 28.5391 30.8906 25.8985C30.8415 25.5301 30.7556 25.1707 30.6328 24.8203C31.4136 24.7657 32.1948 24.7578 32.9766 24.7969C34.6149 25.0994 35.6149 26.0603 35.9766 27.6797Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_30_533">
<rect width="24" height="24" fill="white" transform="translate(12 12)"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Users