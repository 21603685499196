/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home";
import Membership from "./Pages/Membership";
import Services from "./Pages/Services";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import ScrollTop from "./Components/ScrollTop";
import SignUP from "./Pages/SignUP";
import TopBar from "./Components/TopBar/TopBar";
import Members from "./Pages/Members";
import Footer from "./Components/Footer";
import BlogDetails from "./Pages/BlogDetails";
import { getUser } from "./utils/helperFunctions";
import AllBlogs from "./Pages/Blog/AllBlogs";
import SearchBlog from "./Pages/Blog/SearchBlog";
import ListByCategory from "./Pages/Blog/ListByCategory";
import Profile from "./Pages/Profile/Profile";
import MemberProfiles from "./Pages/MemberProfiles/MemberProfiles";
import ProfileDetails from "./Pages/Profile/ProfileDetails";
import MemberProfile from "./Pages/MemberProfiles/MemberProfile";
import ResetPassword from "./Pages/ResetPassword";
import LoginPage from "./Pages/LoginPage";
import DashboarManager from "./Pages/Dashboard/Dashoard";
import EditProfile from "./Pages/Profile/EditProfile";
import { apiCaller } from "./utils/api";
import socketIO from "socket.io-client";
import axios from "axios";
import PayScreen from "./Components/MembersArea/PayScreen";
import PopUp from "./Components/PopUp";
import Products from "./Pages/Products";
import WnHome from "./Components/WnHomes/WnHome";
import LuxeHome from "./Components/WnHomes/LuxeHome";
import WnDepot from "./Components/WnDepot/WnDepot";
import WnTracker from "./Components/WnTracker/WnTracker";
import WnPay from "./Components/WnPay/WnPay";
import EcoHome from "./Components/WnHomes/EcoHome";
import ValueHome from "./Components/WnHomes/ValueHome";
import VerifyMemberLogin from "./Pages/VerifyMember/VerifyMemberLogin";
const App = () => {
  const [user, setUser] = React.useState(getUser());
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState("");

  const [socket, setSocket] = useState(null);
  // console.log({ user });
  useEffect(() => {
    const newSocket = socketIO(process.env.REACT_APP_BACKEND_URL);
    setSocket(newSocket);
    return () => newSocket.disconnect();
  }, []);

  useEffect(() => {
    socket?.emit("addNewUser", user?.email);
  }, [socket, user]);

  // useEffect(() => {
  //   socket?.emit("sendNotification", {
  //     senderEmail: user?.email,
  //     receiverEmail: "waleed.a@blurredego.com",
  //     message: "User just logged in",
  //     subject: "New Message",
  //   });
  // }, [socket, user]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [invoiceData, setInvoiceData] = useState({});
  const [applicationData, setApplicationData] = useState({});

  const getInvoiceData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/invoices?filters[email][$eq]=${user.email}&populate=*`,
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      setInvoiceData(response.data.data[0]);
    } catch (error) {
      console.log(null);
    }
  };

  const getUserData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/applications?filters[email][$eq]=${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      const data = response.data.data[0];
      setApplicationData(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const storeNotification = async (sender, receiver, subject, message) => {
    // eve.lee@wnvoec.org
    const body = {
      data: {
        sender: sender == "admin" ? "eve.lee@wnvoec.org" : sender,
        receiver: receiver == "admin" ? "eve.lee@wnvoec.org" : receiver,
        subject,
        message,
        isRead: false,
      },
    };
    // const response = await apiCaller("post", `notifications`, body);
    const response = await axios.post(`${apiUrl}/notifications`, body);
    if (response.err) console.log(null);
    else {
      setNewNotification(response.data.data);
    }
  };
  // console.log(newNotification);
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotifications([...notifications, data]);
    });

    return () => {
      socket?.off("getNotification");
    };
  }, [notifications, socket]);

  useEffect(() => {
    socket?.emit("sendNotification", {
      newNotification,
    });

    return () => {
      socket?.off("sendNotification");
    };
  }, [socket, newNotification]);

  const getAllNotifications = async () => {
    const response = await apiCaller(
      "get",
      `notifications?filters[receiver][$eq]=${user.email}`
    );
    if (response) {
      setNotifications(response?.data);
    } else {
      console.error("Failed to fetch notifications");
    }
  };

  useEffect(() => {
    getAllNotifications();
    getInvoiceData();
    getUserData();
  }, []);

  const [eng, setEng] = useState(true);
  const [chinese, setChinese] = useState(false);
  const [spanish, setSpanish] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const doNotShowAgain = localStorage.getItem("doNotShowPopup");

    if (doNotShowAgain === "true") {
      setShowPopup(false);
    } else {
      setTimeout(() => {
        setShowPopup(true);
      }, 60000);
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);

    setTimeout(() => {
      setShowPopup(true);
    }, 60000);
  };

  const handleDoNotShowAgain = (e) => {
    if (e.target.checked) {
      localStorage.setItem("doNotShowPopup", "true");
      setShowPopup(false);
    } else {
      localStorage.removeItem("doNotShowPopup");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      setShowPopup(false);
    } else {
      setTimeout(() => {
        setShowPopup(true);
      }, 60000);
    }
  }, []);

  return (
    <>
      <div>
        <Router>
          {/* <Navbar /> */}
          <TopBar
            user={user}
            setUser={setUser}
            setEng={setEng}
            setChinese={setChinese}
            setSpanish={setSpanish}
            notifications={notifications}
            getAllNotifications={getAllNotifications}
          />
          <ScrollTop />
          <Switch>
            <Route exact path="/">
              <Home eng={eng} chinese={chinese} spanish={spanish} />
            </Route>

            <Route path="/membership">
              <Membership />
            </Route>

          <Route path="/products">
              <Products />
            </Route>

            <Route path="/services">
              <Services />
            </Route>

            <Route path="/about">
              <About />
            </Route>
               <Route path="/wnhome">
              <WnHome />
            </Route>
            <Route path="/wnhomeluxe">
              <LuxeHome />
            </Route>

               <Route path="/wnhomeEco">
              <EcoHome />
            </Route>

            <Route path="/wnhomeValue">
              <ValueHome />
            </Route>

         <Route path="/wndepot">
              <WnDepot setUser={setUser} socket={socket} />
            </Route>
            <Route path="/wnTracker">
              <WnTracker />
            </Route>

                <Route path="/wnPay">
              <WnPay />
            </Route>

            <Route path="/contact">
              <Contact user={user} />
            </Route>
            <Route path="/joinus">
              <SignUP
                setUser={setUser}
                socket={socket}
                newNotification={newNotification}
                storeNotification={storeNotification}
              />
            </Route>
            <Route path="/signup">
              <SignUP
                setUser={setUser}
                socket={socket}
                newNotification={newNotification}
                storeNotification={storeNotification}
              />
            </Route>
            <Route path="/login">
              <LoginPage setUser={setUser} socket={socket} />
            </Route>
             <Route path="/verifyMember">
            <VerifyMemberLogin />
          </Route>

            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            {user?.jwt && (
              <Route path="/members">
                <Members user={user} storeNotification={storeNotification} />
              </Route>
            )}
            <Route path="/blogs">
              <AllBlogs eng={eng} chinese={chinese} spanish={spanish} />
            </Route>

            <Route path="/search/:id">
              <SearchBlog eng={eng} chinese={chinese} spanish={spanish} />
            </Route>

            <Route path="/category/:id">
              <ListByCategory eng={eng} chinese={chinese} spanish={spanish} />
            </Route>

            <Route path="/blog/:slug">
              <BlogDetails eng={eng} chinese={chinese} spanish={spanish} />
            </Route>

            <Route path="/profile">
              <div>
                {invoiceData?.attributes?.received === true &&
                  applicationData?.attributes?.Approved === true &&
                  applicationData?.attributes?.directAccess === false && (
                    <Profile />
                  )}

                {applicationData?.attributes?.Approved === true &&
                  invoiceData?.attributes?.received === false &&
                  applicationData?.attributes?.directAccess === false && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        padding: "0 2rem",
                      }}
                    >
                      <h4>Payment is not received yet</h4>
                    </div>
                  )}

                {applicationData?.attributes?.Approved === false && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                      padding: "0 2rem",
                    }}
                  >
                    <h4>Your application is not approved yet</h4>
                  </div>
                )}

                {!applicationData && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                      padding: "0 2rem",
                    }}
                  >
                    <h4>Please create your application first</h4>
                  </div>
                )}

                {applicationData?.attributes?.directAccess === true && (
                  <Profile />
                )}
              </div>
            </Route>

            <Route exact path="/member-profiles">
              <MemberProfiles />
            </Route>

            <Route path="/member-profiles/:id">
              <MemberProfile />
            </Route>
            {user?.jwt && (
              <Route path="/edit">
                <EditProfile
                  user={user}
                  storeNotification={storeNotification}
                />
              </Route>
            )}
            <Route path="/dashboardmanager">
              <DashboarManager
                user={user}
                storeNotification={storeNotification}
              />
            </Route>

            <Route path="*">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  flexDirection: "column",
                }}
              >
                <h1 style={{ fontSize: "4rem" }}>404 Not Found</h1>
              </div>
            </Route>
          </Switch>

          <Footer user={user} />
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            theme="dark"
          />
        </Router>
      </div>
    </>
  );
};

export default App;
