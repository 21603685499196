import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { apiCaller } from "../../../utils/api";
import AuthForm from "./AuthForm/AuthForm";
import UserEdit from "../../../Assets/Icons/UserEdit";
import TrashIcon from "../../../Assets/Icons/TrashLcon";
import Search2 from "../../../Assets/Icons/Search2";

const DashboardUsers = (props) => {
  // console.log(props);
  const [applicationsData, setApplicationsData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [subFilter, setSubFilter] = useState("all");
  const modalRef = useRef(null);
  const [showSelects, setShowSelects] = useState(false);

  function formatString(inputString) {
    // If the string is empty or undefined, return an empty string
    if (!inputString) {
      return "";
    }
    if (inputString == "foundingMember") {
      return "Founding Member";
    } else {
      const words = inputString.split(" ");

      // Capitalize the first letter of each word
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );

      // Join the words back together
      const formattedString = capitalizedWords.join(" ");

      return formattedString;
    }
    // Split the string into words
  }

  const toggleSelects = () => {
    setShowSelects(!showSelects);
  };

  const filteredData = props?.tableData
    ?.filter((row) => row.isDeleted == null || row.isDeleted == false)
    .filter((row) => {
      if (selectedFilter === "all") {
        return true;
      } else if (selectedFilter === "verified") {
        return row.confirmed;
      } else if (selectedFilter === "non_verified") {
        return !row.confirmed;
      } else if (selectedFilter === "all") {
        return true;
      } else if (selectedFilter === "sub") {
        return applicationsData.find(
          (app) => app?.attributes?.email === row?.email
        );
      } else if (selectedFilter === "non_sub") {
        return !applicationsData.find(
          (app) => app?.attributes?.email === row?.email
        );
      }
    });

  const getApplications = async () => {
    let result = await apiCaller("get", "applications");
    if (result.err) console.log(result.err);
    else {
      setApplicationsData(result.data);
    }
  };

  const getInvoices = async () => {
    let result = await apiCaller("get", "invoices");
    if (result.err) console.log(result.err);
    else {
      setInvoiceData(result.data);
    }
  };

  useEffect(() => {
    getApplications();
    getInvoices();
  }, [props.tableData]);

  const showRole = (role) => {
    if (role === "admin") return "Admin";
    else if (role === "governor") return "Governor";
    else if (role === "ordinary") return "Ordinary";
    else if (role === "foundingMember") return "Founding Member";
  };
  const editCols = [
    {
      field: "verified",
      headerName: "Verified",
      width: 200,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          {props.verifyEdit?.id === params.row?.id ? (
            <select
              style={{
                width: "10rem",
              }}
              name=""
              defaultValue={"choose"}
              className="selection_box"
              onChange={(e) =>
                props.onUserRoleChange(params.row, e.target.value)
              }
            >
              <option value="choose">Choose</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <span> {params.row.confirmed ? "Yes" : "No"}</span>
            </div>
          )}
          {props.verifyEdit?.id !== params.row?.id ? (
            <span
              onClick={() => props.setVerifyEdit({ id: params.row?.id })}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <UserEdit />
            </span>
          ) : (
            <span
              onClick={() => props.setVerifyEdit({})}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.1em"
                viewBox="0 0 384 512"
                fill="#034ea2"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </span>
          )}
        </div>
      ),
    },

    {
      field: "member-manager",
      headerName: "Role",
      width: 200,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          {props.isEdit?.id === params.row?.id ? (
            <select
              style={{
                width: "10rem",
              }}
              name=""
              defaultValue={"choose"}
              className="selection_box"
              onChange={(e) =>
                props.onUserRoleChange(params.row, e.target.value)
              }
            >
              <option value="choose">Choose</option>
              <option value="admin">Admin</option>
              <option value="governor">Governor</option>
              <option value="regular">Regular</option>
            </select>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              {params.row?.["member-manager"] && <span>Admin</span>}
              {params.row?.governor && <span>Governor</span>}
              {!params.row?.["member-manager"] && !params.row?.governor && (
                <span>Regular</span>
              )}
            </div>
          )}
          {props.isEdit?.id !== params.row?.id ? (
            <span
              onClick={() => props.setIsEdit({ id: params.row?.id })}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <UserEdit />
            </span>
          ) : (
            <span
              onClick={() => props.setIsEdit({})}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.1em"
                viewBox="0 0 384 512"
                fill="#034ea2"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </span>
          )}
        </div>
      ),
    },

    {
      field: "ordinary",
      headerName: "Membership Type",
      width: 200,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {props?.membershipEdit?.id === params?.row?.id ? (
            <select
              style={{
                width: "10rem",
              }}
              name=""
              defaultValue={"choose"}
              className="selection_box"
              onChange={(e) =>
                props?.onUserMembershipTypeChange(
                  params?.row,
                  e?.target?.value,
                  applicationsData
                )
              }
            >
              <option value="choose">Choose</option>
              <option value="ordinary">Ordinary</option>
              <option value="associate">Associate</option>
              <option value="affiliate">Affiliate</option>
              <option value="foundingMember">Founding Member</option>
            </select>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              {formatString(
                applicationsData?.find(
                  (application) =>
                    application?.attributes?.email === params?.row?.email
                )?.attributes?.membershipType
              )}

              {/* {invoiceData?.find(
                (invoice) =>
                  invoice?.attributes?.email === params?.row?.email
              )?.attributes?.received === true &&
              applicationsData?.find(
                (application) =>
                  application?.attributes?.email === params?.row?.email
              )?.attributes?.directAccess !== true ? (
                <span>Ordinary</span>
              ) : (
                <span></span>
              )} */}
              {/* {applicationsData?.find(
                (application) =>
                  application?.attributes?.email === params?.row?.email
              ) ? (
                <span></span>
              ) : (
                <span>N/A</span>
              )} */}
            </div>
          )}
          {props?.membershipEdit?.id !== params?.row?.id ? (
            <span
              onClick={() => props.setMembershipEdit({ id: params?.row?.id })}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <UserEdit />
            </span>
          ) : (
            <span
              onClick={() => props?.setMembershipEdit({})}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.1em"
                viewBox="0 0 384 512"
                fill="#034ea2"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </span>
          )}
        </div>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 350,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          {props.commentsEdit?.id === params.row?.id ? (
            // <select
            //   style={{
            //     width: "10rem",
            //   }}
            //   name=""
            //   defaultValue={"choose"}
            //   className="selection_box"
            //   onChange={(e) => props.onUserRoleChange(params.row, e.target.value)}
            // >
            //   <option value="choose">Choose</option>
            //   <option value="yes">Yes</option>
            //   <option value="no">No</option>
            // </select>

            <input
              type="text"
              placeholder="Enter text"
              defaultValue={params?.row?.comments}
              onChange={(e) =>
                props.onCommentChange(params.row, e.target.value)
              }
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <span> {params.row.comments}</span>
            </div>
          )}
          {props.commentsEdit?.id !== params.row?.id ? (
            <span
              onClick={() => props.setCommentsEdit({ id: params.row?.id })}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <UserEdit />
            </span>
          ) : (
            <span
              onClick={() => props.setCommentsEdit({})}
              style={{
                cursor: "pointer",
                paddingRight: "50px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.1em"
                viewBox="0 0 384 512"
                fill="#034ea2"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </span>
          )}
        </div>
      ),
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phoneNumber", headerName: "Number", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    { field: "country", headerName: "Country", width: 150 },
     {
      field: "referMemberId",
      headerName: "Referred By ",
      width: 150,
      renderCell: (params) => {
        // console.log("params", params);
        return (
          <span>{params?.row?.referMemberId === "159" ? "Josh" : "N.A"}</span>
        );
      },
      valueGetter: (params) => params?.row?.referMemberId,
    },
    {
      field: "appSub",
      headerName: "App Sub",
      width: 150,
      renderCell: (params) => (
        <span>
          {applicationsData.find(
            (app) => app?.attributes?.email === params.row?.email
          )
            ? "Yes"
            : "No"}
        </span>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <span
          onClick={() => props.deleteUser(params.row)}
          style={{
            cursor: "pointer",
          }}
        >
          <TrashIcon />
        </span>
      ),
    },
  ];

  return (
    <>
      <div
        className="p-2 "
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          {showSelects && (
            <>
              <select
                name=""
                id=""
                className="form-select"
                style={{ maxWidth: "12rem", paddingTop: "0.2rem" }}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <option value="all">All Users</option>
                <option value="verified">Verified</option>
                <option value="non_verified">Non Verified</option>
              </select>
              <select
                name=""
                id=""
                className="form-select"
                style={{
                  maxWidth: "14rem",
                  width: "14rem",
                  paddingTop: "0.2rem",
                }}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <option value="all">Submitted / Non sub</option>
                <option value="sub">Submitted</option>
                <option value="non_sub">Non submitted</option>
              </select>
            </>
          )}
        </div>

        {!props.noEdit && (
          <div
            style={{
              marginBottom: "15px",
              marginRight: "50px",
              textAlign: "right",
              display: "flex",
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                marginTop: "0.3rem",
                cursor: "pointer",
              }}
              onClick={() => toggleSelects()}
            >
              <Search2 />
            </div>

            <button
              className="invoice_file_label"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              style={{
                marginRight: "1rem",
              }}
            >
              Create
            </button>

            <button className="invoice_file_label" onClick={props.onSave}>
              Save
            </button>
          </div>
        )}
      </div>

      <div
        className="modal fade"
        ref={modalRef}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-content-custom">
            <div className="modal-header">
              <div className="modal-heading">
                <h5>Create New User</h5>
              </div>
            </div>
            <div className="modal-body">
              <AuthForm getUsers={props.getUsers} modalRef={modalRef} />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={filteredData}
          columns={props.noEdit ? columns : [...columns, ...editCols]}
          pageSize={10}
          disableSelectionOnClick
        />
      </div>
    </>
  );
};

export default DashboardUsers;
