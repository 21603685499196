import React, { useEffect, useState } from "react";
import "./CreateNewInvoice.css";
import { useForm } from "react-hook-form";
import axios from "axios";

const CreateNewInvoice = ({ modalRef, user, getInvoices }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [applicationData, setApplicationData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  // console.log(applicationData);

  const methods = useForm({
    defaultValues: {
      companyName: "",
      amount: "",
      description: "",
    },
  });

  const singleCompany = applicationData?.find(
    (item) => item?.attributes?.nameOfApplicantCompany === selectedCompany
  );

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${apiUrl}/invoices`,
        {
          data: {
            description: data.description,
            name: singleCompany?.attributes.nameOfApplicantCompany,
            email: singleCompany?.attributes.emailAddressOfCompany,
            companyEmail: singleCompany?.attributes.emailAddressOfCompany,
            companyName: data.companyName,
            amount: data.amount,
          },
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      if (response.status === 200) {
        methods.reset();
        getInvoices();
        modalRef.current.className = "modal fade";
        document.getElementsByClassName("modal-backdrop")[0].remove();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/applications`, {
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
      });
      const data = response.data.data;
      setApplicationData(data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="form-floating ">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Company Name"
            name="companyName"
            list="CompanyName"
            autocomplete="off"
            {...methods.register("companyName", { required: true })}
            // style={{ width: "9.3rem" }}
            onChange={(e) => setSelectedCompany(e.target.value)}
          />
          <label htmlFor="floatingInput">Company Name</label>
          <datalist id="CompanyName">
            {applicationData?.map((item) => {
              return (
                <option
                  key={item?.attributes?.nameOfApplicantCompany}
                  value={item?.attributes?.nameOfApplicantCompany}
                />
              );
            })}
          </datalist>
        </div>

        <div className="form-floating ">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Amount"
            name="amount"
            {...methods.register("amount", { required: true })}
            style={{ margin: "1rem 0" }}
          />
          <label htmlFor="floatingInput">Amount</label>
        </div>

        <div className="form-floating ">
          <textarea
            className="form-control"
            id="floatingInput"
            placeholder="Description"
            name="description"
            {...methods.register("description", { required: true })}
            style={{ height: "10rem" }}
          ></textarea>
          <label htmlFor="floatingInput">Description</label>
        </div>

        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <button
            type="button"
            className="invoice_file_label-2"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="submit" className="invoice_file_label-3">
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateNewInvoice;
