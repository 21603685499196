import axios from "axios";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { getUser, storeUser } from "../utils/helperFunctions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const Login = ({ setError, setUser, socket }) => {
  const { t } = useTranslation();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      identifier: "",
      password: "",
    },
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigator = useHistory();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/auth/local`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // setError(null);
      methods.reset();
      if (response.data) {
        storeUser(response.data);
        setUser(getUser());
        // if (socket == null) return;

        navigator.push("/members");
      }
      setLoading(false);
      toast.info(`Welcome ${response.data.user.name}`);
      // console.log(response);
    } catch (error) {
      toast.error(error.response.data.error.message);
      setLoading(false);
    }
  };

  const sendResetLink = async (data) => {
    try {
      setLoading(true);

      const response = await axios.post(`${apiUrl}/auth/forgot-password`, {
        email: data?.identifier,
      });
      if (response.data) {
        toast.info("Reset link sent to your email");
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <form
        onSubmit={methods.handleSubmit(
          forgotPassword ? sendResetLink : onSubmit
        )}
        className="form"
        style={{
          padding: "2rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <h4 className="formHeading">{t("signup.login")}</h4>
        <div className="form-floating ">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            name="identifier"
            {...methods.register("identifier", { required: true })}
          />
          <label htmlFor="floatingInput">{t("signup.email")}</label>
        </div>
        {forgotPassword ? null : (
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              name="password"
              {...methods.register("password", { required: true })}
            />
            <label htmlFor="floatingPassword">{t("signup.password")}</label>
          </div>
        )}
        <button
          disabled={loading}
          className="btn btn-purple small-btn btn-md py-2"
          style={{
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          {forgotPassword ? t("signup.reset_link") : t("signup.login")}
        </button>
        <span
          className="forgot-password"
          onClick={() => setForgotPassword(true)}
        >
          {t("signup.forgot_password")}
        </span>
      </form>
    </div>
  );
};

export default Login;
