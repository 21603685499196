import React from 'react'

const UserEdit = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_69_362)">
<path d="M15.545 12.8943C15.0077 12.8943 14.5735 13.3286 14.5735 13.8658V16.9175C14.5735 17.4538 14.1382 17.8891 13.6019 17.8891H2.91468C2.37836 17.8891 1.94313 17.4538 1.94313 16.9175V4.2872C1.94313 3.75088 2.3784 3.31565 2.91468 3.31565H8.55946C9.09672 3.31565 9.531 2.88136 9.531 2.3441C9.531 1.80685 9.09672 1.37256 8.55946 1.37256H2.91468C1.30771 1.37248 0 2.68023 0 4.2872V16.9175C0 18.5245 1.30771 19.8322 2.91468 19.8322H13.6019C15.2088 19.8322 16.5165 18.5255 16.5165 16.9175V13.8659C16.5166 13.3286 16.0823 12.8943 15.545 12.8943Z" fill="#034EA2"/>
<path d="M18.9135 1.24909C18.2081 0.543746 17.287 0.188127 16.3592 0.168714C15.3828 0.147347 14.4005 0.500974 13.6621 1.24034L7.08464 7.81784C6.12474 8.78165 5.59619 10.0602 5.59619 11.4184V13.5947C5.59619 14.132 6.03048 14.5663 6.56774 14.5663H8.74403C10.1023 14.5663 11.3809 14.0377 12.3456 13.0768L18.9144 6.50809C20.362 5.05957 20.362 2.69867 18.9135 1.24909ZM10.9729 11.7022C10.3773 12.2958 9.58448 12.6232 8.7441 12.6232H7.53932V11.4185C7.53932 10.5781 7.86674 9.78526 8.45938 9.19067L12.7955 4.85457L15.3079 7.36703L10.9729 11.7022ZM17.5397 5.13534L16.6817 5.99326L14.1693 3.4808L15.0272 2.62293C15.7209 1.92923 16.8479 1.93118 17.5396 2.62293C18.2314 3.31561 18.2314 4.44262 17.5397 5.13534Z" fill="#034EA2"/>
</g>
<defs>
<clipPath id="clip0_69_362">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default UserEdit