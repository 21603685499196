import React, { useEffect, useRef, useState } from "react";
import "./WnDepotCollection.css";
import DepotImage3 from "../Assets/SVG/Depot-Facebook-Label.svg";
import DepotImage5 from "../Assets/SVG/Depot-linkdin-Label.svg";
import DepotImage6 from "../Assets/SVG/Depot-Country-label.svg";
import DepotImage7 from "../Assets/SVG/Depot-Call-label.svg";
import DepotImage8 from "../Assets/SVG/Depot-Email-label.svg";
import Search2 from "../Assets/Icons/Search2";
import { useTranslation } from "react-i18next";

const WnDepotCollection = (WndepotData) => {
  const [screenshotDisabled, setscreenshotDisabled] = useState(false);
  const [showSelects, setShowSelects] = useState(false);
  const [searchOpt, setSearchOpt] = useState("searchByCountry");
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { t } = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.metaKey) {
        setscreenshotDisabled(true);
        if (modalRef.current) {
          const modal = new window.bootstrap.Modal(modalRef.current);
          modal.show();
        }
      }
    };

    const handleModalHide = () => {
      setscreenshotDisabled(false);
      // Ensure the backdrop is removed
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.parentNode.removeChild(backdrop);
      }
      // Reset body styles to ensure interaction is enabled
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };

    window.addEventListener("keydown", handleKeyDown);

    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleModalHide);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleModalHide);
      }
    };
  }, []);

  const toggleSelects = () => {
    setShowSelects(!showSelects);
  };

  const filterDepots = () => {
    if (!WndepotData?.WndepotData) return [];
    return WndepotData?.WndepotData?.filter((depot) => {
      const value =
        depot?.attributes[
          searchOpt === "searchByCountry" ? "Country" : "DepotName"
        ]?.toLowerCase() || "";
      return value?.includes(query?.toLowerCase());
    });
  };

  const filteredDepots = filterDepots();

  const applicationsPerPage = 12;
  const indexOfLastApplication = currentPage * applicationsPerPage;
  const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;
  const currentDepots = filteredDepots?.slice(
    indexOfFirstApplication,
    indexOfLastApplication
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math?.ceil(filteredDepots?.length / applicationsPerPage);
    i++
  ) {
    pageNumbers?.push(i);
  }

  const nextPage = () => {
    if (currentPage < pageNumbers?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleOkayClick = () => {
    setscreenshotDisabled(false);
    if (modalRef.current) {
      const modal = window.bootstrap.Modal.getInstance(modalRef.current);
      modal.hide();
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        () => {
          // Remove the backdrop element
          const backdrop = document.querySelector(".modal-backdrop");
          if (backdrop) {
            backdrop.parentNode.removeChild(backdrop);
          }
          // Reset body styles to ensure interaction is enabled
          document.body.classList.remove("modal-open");
          document.body.style.overflow = "";
          document.body.style.paddingRight = "";
        },
        { once: true }
      );
    }
  };

  return (
    <>
      {/* section 1 */}
      <div className="container-fluid">
        <div className="search_container">
          <div className="d-flex align-items-center">
            <input
              type="text"
              className="search_input form-control"
              placeholder={t("members.search")}
              onChange={(e) => setQuery(e?.target?.value)}
              style={{ minWidth: "12rem", paddingTop: "0.2rem" }}
            />

            <div
              className="toggle-icon"
              onClick={() => toggleSelects()}
              style={{ cursor: "pointer" }}
            >
              <Search2 />
            </div>
          </div>

          {showSelects && (
            <>
              <select
                className="form-select"
                style={{ maxWidth: "14rem", paddingTop: "0.2rem" }}
                name=""
                id=""
                onChange={(e) => setSearchOpt(e?.target?.value)}
              >
                <option value="searchByCountry">Search By Country</option>
                <option value="searchByDepotName">Search By Depot Name</option>
              </select>
            </>
          )}
        </div>

        <hr />

        <div className="DepotCollection-section-2">
          {currentDepots?.map((depot, index) => (
            <div key={index}>
              <div className="DepotCollection-Card mx-auto">
                <div
                  className={`Depot-Card-Tag  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  {depot?.attributes?.Facebook && (
                    <a
                      href={depot?.attributes?.Facebook || "N/A"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={DepotImage3}
                        alt="Facebook"
                        className="img-fluid me-1"
                      />
                    </a>
                  )}

                  {depot?.attributes?.LinkedIn && (
                    <a
                      href={depot?.attributes?.LinkedIn || "N/A"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={DepotImage5}
                        alt="LinkedIn"
                        className="img-fluid"
                      />
                    </a>
                  )}
                </div>
                <p
                  className={`DepotCollection-para-1  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  {depot?.attributes?.DepotName || "N/A"}
                </p>
                <div
                  className={`Depot-Information-Tag  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  <img
                    src={DepotImage6}
                    alt="Country"
                    className="img-fluid Tag1"
                  />
                  <p
                    className={`DepotCollection-para-2  ${
                      screenshotDisabled ? "screenshot-disabled" : ""
                    }`}
                  >
                    {depot?.attributes?.Country || "N/A"}
                  </p>
                </div>
                <div
                  className={`Depot-Information-Tag  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  <img
                    src={DepotImage7}
                    alt="Phone"
                    className="img-fluid Tag1"
                  />
                  <p
                    className={`DepotCollection-para-2  ${
                      screenshotDisabled ? "screenshot-disabled" : ""
                    }`}
                  >
                    {depot?.attributes?.Phone ? (
                      <a href={`tel:${depot?.attributes?.Phone}`}>
                        {depot?.attributes?.Phone}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>
                <div
                  className={`Depot-Information-Tag  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  <img
                    src={DepotImage8}
                    alt="Email"
                    className="img-fluid Tag1"
                  />
                  <p
                    className={`DepotCollection-para-5  ${
                      screenshotDisabled ? "screenshot-disabled" : ""
                    }`}
                  >
                    {depot?.attributes?.Email ? (
                      <a href={`mailto:${depot?.attributes?.Email}`}>
                        {depot?.attributes?.Email}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>

                <hr />

                <div
                  className={`Depot-location-Tag  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  <p className="DepotCollection-para-3">Location</p>
                  <p className="DepotCollection-para-4">
                    {depot?.attributes?.Location || "N/A"}
                  </p>
                </div>
                <div
                  className={`Depot-POC-Tag  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  <p className="DepotCollection-para-3">POC</p>
                  <p className="DepotCollection-para-4">
                    {depot?.attributes?.POC || "N/A"}
                  </p>
                </div>
                <div
                  className={`Depot-location-Tag  ${
                    screenshotDisabled ? "screenshot-disabled" : ""
                  }`}
                >
                  <p className="DepotCollection-para-3">Website</p>
                  <p className="DepotCollection-para-6">
                    <a
                      href={depot?.attributes?.Website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <small>{depot?.attributes?.Website || "N/A"}</small>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="WnDepot-Pagination">
          <div className="pagination">
            <button
              className="WnDepot-Pagination_Btn"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              {t("btns.prev")}
            </button>
            {pageNumbers?.map((number) => (
              <button
                className={`WnDepot-Pagination_Btn ${
                  currentPage === number ? "bg-primary " : ""
                }`}
                key={number}
                onClick={() => paginate(number)}
              >
                {number}
              </button>
            ))}
            <button
              className="WnDepot-Pagination_Btn"
              onClick={nextPage}
              disabled={currentPage === pageNumbers.length}
            >
              {t("btns.next")}
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={modalRef}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <h1 className="Screen-Modal-Para" id="exampleModalLabel">
              Screenshots not Allowed.
            </h1>
            <div className="Screen-Btn-Div">
              <button className="btn Screen-Btn" onClick={handleOkayClick}>
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WnDepotCollection;
