import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import countriesFlags from "../../data/Countries";
import image from "../../Assets/Png/WNVOECfav (2).webp";
import { ImLocation2 } from "react-icons/im";
import SendingMessage from "./SendingMessage";
import ChatScreen from "./Chat/ChatScreen";
import socketIO from "socket.io-client";
import { apiCaller } from "../../utils/api";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import { set } from "react-hook-form";
import "./ProfileDetails.css";
import LocationPin from "../../Assets/Icons/LocationPin";
import IndustryIcon from "../../Assets/Icons/IndustryIcon";
import CompanyTypeIcons from "../../Assets/Icons/CompanyTypeIcons";
import ComAddress from "../../Assets/Icons/ComAddress";
import Website from "../../Assets/Icons/website";
import Email from "../../Assets/Icons/Email";
import Phone from "../../Assets/Icons/Phone";
import Staff from "../../Assets/Icons/Staff";
import Fax from "../../Assets/Icons/Fax";

const ProfileDetails = ({ applicationData, user }) => {
  console.log("ad", applicationData);
  const { t, i18n } = useTranslation();
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [latestMessage, setLatestMessage] = useState(null);
  const [inboxOpen, setInboxOpen] = useState(false);
  const [allApplications, setAllApplications] = useState([]);
  const [inboxList, setInboxList] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const [newChat, setNewChat] = useState(false);

  const allOtherApplications = allApplications?.filter(
    (application) => application?.attributes?.email !== user?.email
  );

  useEffect(() => {
    const getAllApplications = async () => {
      const response = await apiCaller("get", `applications`);
      if (response.err) console.log(response.err);
      else {
        setAllApplications(response.data);
      }
    };
    getAllApplications();
  }, []);

  useEffect(() => {
    const newSocket = socketIO(process.env.REACT_APP_BACKEND_URL);
    setSocket(newSocket);
    return () => newSocket.disconnect();
  }, []);

  useEffect(() => {
    if (socket === null) return;
    socket.emit("addNewUser", user.email);
    socket.on("getOnlineUsers", (res) => {
      setOnlineUsers(res);
    });

    return () => socket.off("getOnlineUsers");
  }, [socket, user.email]);

  // Send Message
  useEffect(() => {
    if (socket === null) return;

    socket.emit("sendMessage", {
      receiver:
        selectedChat?.attributes?.firstId === user.email
          ? selectedChat?.attributes?.secondId
          : selectedChat?.attributes?.firstId,
      message: newMessage,
    });

    setNewMessage("");

    return () => socket.off("sendMessage");
  }, [messages, newMessage, selectedChat, socket, user.email]);

  // console.log("latestMessage", latestMessage);
  // console.log("newMessage", newMessage);
  // console.log("messages", messages);
  // receive message
  useEffect(() => {
    if (socket === null) return;

    socket.on("getMessage", (data) => {
      // setMessages((prev) => [...prev, data.message]);
      // const newSelectedChat =
      //   selectedChat?.attributes?.firstId === user.email
      //     ? selectedChat?.attributes?.secondId
      //     : selectedChat?.attributes?.firstId;

      if (selectedChat?.id != data?.message?.attributes?.chatId) return;
      else {
        console.log("data", data.message);
        setLatestMessage(data.message);
      }
    });

    return () => socket.off("getMessage");
  }, [socket, selectedChat, latestMessage, newMessage]);

  useEffect(() => {
    if (latestMessage) {
      const newMessages = [...messages, latestMessage];
      setMessages(newMessages);
    }
  }, [latestMessage, messages]);

  const getInboxList = async () => {
    // ?filters[firstId][$eq]=${user.email}
    // const response = await apiCaller("get", `chats`);
    // const chats = response.data.filter((chat) => {
    //   if (
    //     chat?.attributes?.firstId === user.email ||
    //     chat?.attributes?.secondId === user.email
    //   ) {
    //     return chat;
    //   }
    // });
    // const inbox = response?.data?.filter((chat) => {
    //   if (
    //     chat?.attributes?.firstId === user?.email ||
    //     chat?.attributes?.secondId === user?.email
    //   ) {
    //     return chat;
    //   }
    // });
    // if (response.err) console.log(response.err);
    // else {
    //   setInboxList(inbox);
    //   // console.log("getInboxList", response.data);
    // }
  };

  const getMessages = async (chatId) => {
    const response = await apiCaller(
      "get",
      `chat-messages?filters[chatId][$eq]=${chatId}`
    );
    if (response.err) console.log(response.err);
    else {
      setMessages(response.data);
    }
  };

  useEffect(() => {
    if (selectedChat) {
      getMessages(selectedChat.id);
    }
  }, [selectedChat]);

  const findChat = async (firstId, secondId) => {
    const response = await apiCaller(
      "get",
      `chats?filters[firstId][$eq]=${firstId}&filters[secondId][$eq]=${secondId}`
    );
    if (response.err) console.log(response.err);
    else {
      setSelectedChat(response.data[0]);
      // getMessages(response.data[0].id);
    }
  };

  const createChat = useCallback(
    async (
      firstId,
      secondId,
      companyLogo,
      companyLogo2,
      companyName,
      companyName2
    ) => {
      // const response = await apiCaller("get", `chats`);
      // const chatExist = await apiCaller(
      //   "get",
      //   `chats?filters[firstId][$eq]=${firstId}&filters[secondId][$eq]=${secondId}`
      // );

      const allChats = await apiCaller("get", `chats`);

      const inbox = allChats?.data?.filter((chat) => {
        if (
          chat?.attributes?.firstId === user?.email ||
          chat?.attributes?.secondId === user?.email
        ) {
          return chat;
        }
      });

      const chatExists = inbox.some(
        (obj) =>
          obj.attributes.firstId === secondId ||
          obj.attributes.secondId === secondId
      );

      // const chatExist = response?.data?.filter((chat) => {
      //   if (
      //     chat?.attributes?.firstId === applicationData?.email ||
      //     chat?.attributes?.secondId === applicationData?.email
      //   ) {
      //     return chat;
      //   }
      // });

      // console.log("chatExist", chatExist);

      if (chatExists) {
        setInboxOpen(true);
        setNewChat(false);
      } else {
        const response = await apiCaller("post", "chats", {
          data: {
            firstId,
            secondId,
            companyLogo,
            companyLogo2,
            companyName,
            companyName2,
          },
        });
        if (response.err) console.log(response.err);
        if (response) {
          getInboxList();
          setInboxOpen(true);
          setNewChat(false);
        }
      }
    },
    []
  );

  const createMessage = async (chatId, text, senderId) => {
    const response = await apiCaller("post", "chat-messages", {
      data: {
        chatId,
        senderId,
        text,
        name: user?.name,
      },
    });
    if (response.err) console.log(response.err);
    else {
      // getMessages(chatId);
      // console.log("createMessage", response.data);
      setMessages((prev) => [
        ...prev,
        {
          ...response.data,
        },
      ]);
      setNewMessage(response.data);
    }
  };

  useEffect(() => {
    getInboxList();
  }, []);

  // function downloadURLGet(file) {
  //   const downloadUrl = `${process.env.REACT_APP_IMG_URL}${file.attributes?.url}`;
  //   return downloadUrl;
  // }

  // console.log("applicationData", applicationData);
  return (
    <div className="profileWrapper">
      <div className="profileLeft">
        <div className="profileLeft-top">
          <div className="profileLeft-topLogo">
            <img src={applicationData?.logoOfCompany || image} alt="logo" />
          </div>
          <div className="profileLeft-topInfo">
            <h2>{applicationData?.nameOfApplicantCompany || "N/A"}</h2>
            {/* <h4>
              {" "}
              <LocationPin color={"white"} /> {applicationData?.country || "N/A"} 
            </h4> */}

            <div
              style={{ cursor: "pointer" }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={applicationData?.country}
            >
              <img
                src={countriesFlags[applicationData?.country]?.mini}
                alt={applicationData?.country}
                width="25"
                height="15"
              />
            </div>
          </div>
        </div>
        <div className="profileLeft-bottom">
          <h4>About Company</h4>
          <p>{applicationData?.companyInfo || "N/A"}</p>
          <div className="profileLeft-bottomInfo">
            <div>
              <h5>
                <IndustryIcon /> Industry Classification
              </h5>
              <h6>{applicationData?.industryClassification}</h6>
            </div>
            <div>
              <h5>
                <CompanyTypeIcons /> Company Type
              </h5>
              <h6>{applicationData?.companyType || "N/A"}</h6>
            </div>
            <div>
              <h5>
                <ComAddress /> Company Address
              </h5>
              <h6>{applicationData?.addressOfMainOffice || "N/A"}</h6>
            </div>
            <div>
              <h5>
                <Website /> Website
              </h5>
              {/* <h6>{applicationData?.websiteAddressOfCompany || "N/A"}</h6> */}
              <h6>
                <a
                  href={"https://" + applicationData?.websiteAddressOfCompany}
                  target="_blank"
                  className="company_detail_ans"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  {applicationData?.websiteAddressOfCompany || "N/A"}
                </a>
              </h6>
            </div>
            <div>
              <h5>
                <Email /> Email
              </h5>
              <h6>
                {/* {applicationData?.emailAddressOfCompany || "N/A"} */}
                <a
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    borderBottom: "1px solid white",
                  }}
                  href={"mailto:" + applicationData?.emailAddressOfCompany}
                  target="_blank"
                >
                  {applicationData?.emailAddressOfCompany || "N/A"}
                </a>
              </h6>
            </div>
            <div>
              <h5>
                <Phone /> Telephone Number
              </h5>
              <h6>
                {/* {applicationData?.telephoneNumber || "N/A"} */}
                <a
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    borderBottom: "1px solid white",
                  }}
                  href={`tel:${applicationData?.telephoneNumber || "N/A"}`}
                >
                  {applicationData?.telephoneNumber || "N/A"}
                </a>
              </h6>
            </div>
            <div>
              <h5>
                <Staff /> Staff Strength
              </h5>
              <h6>{applicationData?.staffStrengthOfCompany || "N/A"}</h6>
            </div>
            <div>
              <h5>
                <Fax /> Fax
              </h5>
              <h6>{applicationData?.fax || "N/A"}</h6>
            </div>
            <div>
              {user?.email !== applicationData?.email && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    createChat(
                      user.email,
                      applicationData?.email,
                      applicationData?.logoOfCompany,
                      allApplications?.filter(
                        (app) => app?.attributes?.email === user?.email
                      )[0]?.attributes?.logoOfCompany,

                      applicationData?.nameOfApplicantCompany,
                      allApplications?.filter(
                        (app) => app?.attributes?.email === user?.email
                      )[0]?.attributes?.nameOfApplicantCompany
                    )
                  }
                >
          
                </div>
              )}
            </div>
            <div>
              {user?.email == applicationData?.email && (
                <Link to={`/edit`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.4em"
                    viewBox="0 0 512 512"
                    fill="white"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="profileRight">
        <h2>Primary Contact</h2>
        <div className="profileRight-info">
          <div className="profileRight-info_row">
            <h6>Contact Person 1</h6>
            <span>{applicationData?.contactPerson1 || "N/A"}</span>
          </div>
          <div className="profileRight-info_row">
            <h6>Email Address 1</h6>
            <span>
              {/* {applicationData?.emailAddress1 || "N/A"} */}
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  borderBottom: "1px solid black",
                }}
                href={"mailto:" + applicationData?.emailAddress1}
                target="_blank"
              >
                {applicationData?.emailAddress1 || "N/A"}
              </a>
            </span>
          </div>
          <div className="profileRight-info_row">
            <h6>Office 1</h6>
            <span>{applicationData?.office1 || "N/A"}</span>
          </div>
          <div className="profileRight-info_row">
            <h6>Designation</h6>
            <span>{applicationData?.designation1 || "N/A"}</span>
          </div>
          <div className="profileRight-info_row">
            <h6>Mobile 1</h6>
            <span>{applicationData?.mobile1 || "N/A"}</span>
          </div>
        </div>
        <h2>Secondary Contact</h2>
        <div className="profileRight-info">
          <div className="profileRight-info_row">
            <h6>Contact Person 2</h6>
            <span>{applicationData?.contactPerson2 || "N/A"}</span>
          </div>
          <div className="profileRight-info_row">
            <h6>Email Address 2</h6>
            <span>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  borderBottom: "1px solid black",
                }}
                href={"mailto:" + applicationData?.emailAddress2}
                target="_blank"
              >
                {applicationData?.emailAddress2 || "N/A"}
              </a>
            </span>
          </div>
          <div className="profileRight-info_row">
            <h6>Designation</h6>
            <span>{applicationData?.designation2 || "N/A"}</span>
          </div>
        </div>
        {user?.["member-manager"] && (
          <div>
            <h2>Download Files</h2>
            <div className="profileRight-info">
              <div className="profileRight-info_row">
                <h6>Company Registration Certificate</h6>
                <div
                  style={{
                    display: "flex",
                    marginTop: "0.5em",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {
                      applicationData?.companyRegistrationCertificate
                        ?.substring(
                          applicationData?.companyRegistrationCertificate?.lastIndexOf(
                            "/"
                          ) + 1
                        )
                        ?.split("_")[0]
                    }
                  </span>
                  <a
                    href={applicationData?.companyRegistrationCertificate}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.2em"
                      fill="#034ea2"
                      viewBox="0 0 512 512"
                    >
                      <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="profileRight-info_row">
                <h6>ID Of The Beneficial Owner</h6>
                <div
                  style={{
                    display: "flex",
                    marginTop: "0.5em",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  {applicationData?.idOfTheBeneficialOwner ===
                  "http://localhost:1337undefined" ? (
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      No File
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {
                        applicationData?.idOfTheBeneficialOwner
                          ?.substring(
                            applicationData?.idOfTheBeneficialOwner?.lastIndexOf(
                              "/"
                            ) + 1
                          )
                          ?.split("_")[0]
                      }
                    </span>
                  )}
                  {applicationData?.idOfTheBeneficialOwner ===
                  "http://localhost:1337undefined" ? (
                    ""
                  ) : (
                    <a
                      href={applicationData?.idOfTheBeneficialOwner}
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.2em"
                        fill="#034ea2"
                        viewBox="0 0 512 512"
                      >
                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
  
    </div>
  );
};

export default ProfileDetails;
