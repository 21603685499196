import React from "react";
import "./FormComponent.css";
import {
  FormProvider,
  Controller,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { get } from "react-scroll/modules/mixins/scroller";
import { useTranslation } from "react-i18next";

const FormComponent = ({
  schema,
  onSubmit,
  customInputs,
  onCancel,
  customButtons,
  watchFields,
  currentCountry,
  loading,
  isEdit,
}) => {
  const { t, i18n } = useTranslation();

  const fields = schema?.reduce((acc, field) => {
    acc[field.name] = "";
    return acc;
  }, {});

  const methods = useForm({
    defaultValues: {
      ...fields,
    },
  });

  let watchValues = methods.watch(watchFields);

  currentCountry(watchValues[1]);

  const renderFields = (schema) => {
    return (
      <div>
        <div className="column">
          {schema
            ?.filter((field) => field.id <= 12)
            .map((field) => {
              const {
                name,
                type,
                label,
                options,
                title,
                subTitle,
                rules,
                styles,
                dynamic,
                id,
              } = field;
              // let showField = dynamic ? watchValues[0] === dynamic.value : true;
              let setDisable = dynamic
                ? watchValues[2] === dynamic.value
                : false;
              if (setDisable) return;
              switch (type) {
                case "text":
                case "email":
                case "password":
                case "number":
                case "file":
                  return (
                    <div className="singleField" key={id}>
                      {title && <h3 className="title">{title}</h3>}
                      {subTitle && <h6 className="subTitle">{subTitle}</h6>}
                      <div style={styles}>
                        <label htmlFor={label} className="form-label">
                          {label}{" "}
                          {rules?.required ? (
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          defaultValue={""}
                          type={type}
                          id={label}
                          className="form-control"
                          placeholder={label}
                          {...methods.register(name, rules && rules && rules)}
                          // style={styles}
                        />
                      </div>
                    </div>
                  );
                case "checkbox":
                  return (
                    <div className="singleField" key={id}>
                      {title && <h3 className="title">{title}</h3>}
                      {subTitle && <h6 className="subTitle">{subTitle}</h6>}

                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <select
                        style={{ height: "2.5rem", width: "100%" }}
                        className="form-select"
                        aria-label="Default select example"
                        {...methods.register(name, rules && rules)}
                      >
                        <option value="" hidden>
                          Choose
                        </option>
                        {options?.map((option) => {
                          return (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                case "radio":
                  return (
                    <div className="singleField" key={id}>
                      <label
                        style={styles}
                        htmlFor={label}
                        className="form-label"
                      >
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        type="checkbox"
                        className=""
                        {...methods.register(name, rules && rules)}
                      />
                    </div>
                  );
                case "textarea":
                  return (
                    <div className="singleField" key={id}>
                      {title && <h3 className="title">{title}</h3>}
                      {subTitle && <h6 className="subTitle">{subTitle}</h6>}

                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <textarea
                        type={type}
                        id={label}
                        className="form-control"
                        placeholder={label}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                    </div>
                  );
                case "autocomplete":
                  return (
                    <div className="singleField" key={id}>
                      {title && <h3 className="title">{title}</h3>}
                      {subTitle && <h6 className="subTitle">{subTitle}</h6>}

                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        defaultValue={" "}
                        className="form-control"
                        list={label}
                        htmlFor={label}
                        autocomplete="off"
                        placeholder={label || "Type to search..."}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                      <datalist id={label}>
                        {options?.map((option) => {
                          return <option value={option?.value} />;
                        })}
                      </datalist>
                    </div>
                  );
                default:
                  return <h1>Nothing</h1>;
              }
            })}
        </div>
        <div className="column">
          {schema
            ?.filter((field) => field.id > 12 && field.id <= 23)
            .map((field) => {
              const {
                name,
                type,
                label,
                options,
                title,
                rules,
                styles,
                dynamic,
                id,
              } = field;
              let showField = dynamic ? watchValues[0] === dynamic.value : true;
              if (!showField) return;
              switch (type) {
                case "text":
                case "email":
                case "password":
                case "radio":
                case "file":
                  return (
                    <div className="singleField" key={id}>
                      {title && <h3 className="title">{title}</h3>}
                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        defaultValue={" "}
                        type={type}
                        id={label}
                        className="form-control"
                        placeholder={label}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                    </div>
                  );
                case "checkbox":
                  return (
                    <div className="singleField" key={id}>
                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <select
                        style={{ height: "2.5rem", width: "100%" }}
                        className="form-select"
                        aria-label="Default select example"
                        {...methods.register(name, rules && rules)}
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </div>
                  );
                case "radio":
                  return (
                    <div className="singleField" key={id}>
                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        type="radio"
                        className="form-select"
                        style={{ height: "0.4rem", width: "0.4rem" }}
                        {...methods.register(name, rules && rules)}
                      />
                    </div>
                  );
                case "textarea":
                  return (
                    <div className="singleField" key={id}>
                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <textarea
                        type={type}
                        id={label}
                        className="form-control"
                        placeholder={label}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                    </div>
                  );
                case "autocomplete":
                  return (
                    <div className="singleField" key={id}>
                      <label htmlFor={label} className="form-label">
                        {label}{" "}
                        {rules?.required ? (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        defaultValue={" "}
                        className="form-control"
                        list={label}
                        htmlFor={label}
                        autocomplete="off"
                        placeholder={label || "Type to search..."}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                      <datalist id={label}>
                        {options?.map((option) => {
                          return <option value={option?.value} />;
                        })}
                      </datalist>
                    </div>
                  );
                default:
                  return <h1>Nothing</h1>;
              }
            })}
        </div>
        {customInputs && <div className="column">{customInputs}</div>}
        {/* <div className="column">
          {schema
            ?.filter((field) => field.id > 17)
            .map((field) => {
              const {
                name,
                type,
                label,
                options,
                title,
                rules,
                styles,
                dynamic,
                id,
              } = field;
              let showField = dynamic ? watchValues[0] === dynamic.value : true;
              if (!showField) return;
              switch (type) {
                case "text":
                case "email":
                case "password":
                case "number":
                case "file":
                  return (
                    <div className="singleField" key={id}>
                      {title && <h3 className="title">{title}</h3>}
                      <label htmlFor={label} className="form-label">
                        {label}
                      </label>
                      <input
                        type={type}
                        id={label}
                        className="form-control"
                        placeholder={label}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                    </div>
                  );
                case "checkbox":
                  return (
                    <div className="singleField" key={id}>
                      <label htmlFor={label} className="form-label">
                        {label}
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        {...methods.register(name, rules && rules)}
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </div>
                  );
                case "textarea":
                  return (
                    <div className="singleField" key={id}>
                      <label htmlFor={label} className="form-label">
                        {label}
                      </label>
                      <textarea
                        type={type}
                        id={label}
                        className="form-control"
                        placeholder={label}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                    </div>
                  );
                case "autocomplete":
                  return (
                    <div className="singleField" key={id}>
                      <label htmlFor={label} className="form-label">
                        {label}
                      </label>
                      <input
                        className="form-control"
                        list={label}
                        htmlFor={label}
                        autocomplete="off"
                        placeholder={label || "Type to search..."}
                        {...methods.register(name, rules && rules)}
                        style={styles}
                      />
                      <datalist id={label}>
                        {options?.map((option) => {
                          return <option value={option?.value} />;
                        })}
                      </datalist>
                    </div>
                  );
                default:
                  return <h1>Nothing</h1>;
              }
            })}
        </div> */}
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {renderFields(schema)}
        <div className="buttonWrapper mt-5">
          <button type="submit" disabled={loading} className="btn btn-primary">
            {t("btns.submit")}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default FormComponent;
