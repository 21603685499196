import React from 'react'

const UsersIcon = () => {
  return (
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.8 7.20001C10.8 8.15479 10.4207 9.07046 9.7456 9.74559C9.07047 10.4207 8.15479 10.8 7.20001 10.8C6.24523 10.8 5.32956 10.4207 4.65443 9.74559C3.9793 9.07046 3.60001 8.15479 3.60001 7.20001C3.60001 6.24523 3.9793 5.32955 4.65443 4.65442C5.32956 3.97929 6.24523 3.60001 7.20001 3.60001C8.15479 3.60001 9.07047 3.97929 9.7456 4.65442C10.4207 5.32955 10.8 6.24523 10.8 7.20001ZM20.4 7.20001C20.4 7.67276 20.3069 8.14089 20.126 8.57767C19.9451 9.01444 19.6799 9.4113 19.3456 9.74559C19.0113 10.0799 18.6144 10.3451 18.1777 10.526C17.7409 10.7069 17.2728 10.8 16.8 10.8C16.3273 10.8 15.8591 10.7069 15.4224 10.526C14.9856 10.3451 14.5887 10.0799 14.2544 9.74559C13.9201 9.4113 13.655 9.01444 13.474 8.57767C13.2931 8.14089 13.2 7.67276 13.2 7.20001C13.2 6.24523 13.5793 5.32955 14.2544 4.65442C14.9296 3.97929 15.8452 3.60001 16.8 3.60001C17.7548 3.60001 18.6705 3.97929 19.3456 4.65442C20.0207 5.32955 20.4 6.24523 20.4 7.20001ZM15.516 20.4C15.5712 20.0076 15.6 19.608 15.6 19.2C15.6028 17.3146 14.9685 15.4836 13.8 14.004C14.7121 13.4774 15.7468 13.2002 16.8 13.2002C17.8532 13.2002 18.8878 13.4774 19.7999 14.004C20.7121 14.5306 21.4695 15.288 21.9961 16.2001C22.5227 17.1122 22.8 18.1468 22.8 19.2V20.4H15.516ZM7.20001 13.2C8.79131 13.2 10.3174 13.8321 11.4427 14.9574C12.5679 16.0826 13.2 17.6087 13.2 19.2V20.4H1.20001V19.2C1.20001 17.6087 1.83215 16.0826 2.95737 14.9574C4.08259 13.8321 5.60871 13.2 7.20001 13.2Z" fill="black" fill-opacity="0.6"/>
</svg>

    </div>
  )
}

export default UsersIcon