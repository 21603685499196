import React from 'react'

const NotificationIcon = ({color}) => {
  return (
    <div>
       <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F5F7FB"/>
<path d="M27 21.586V18C27 14.783 24.815 12.073 21.855 11.258C21.562 10.52 20.846 10 20 10C19.154 10 18.438 10.52 18.145 11.258C15.185 12.074 13 14.783 13 18V21.586L11.293 23.293C11.2 23.3857 11.1262 23.4959 11.0759 23.6172C11.0256 23.7386 10.9998 23.8687 11 24V26C11 26.2652 11.1054 26.5196 11.2929 26.7071C11.4804 26.8946 11.7348 27 12 27H28C28.2652 27 28.5196 26.8946 28.7071 26.7071C28.8946 26.5196 29 26.2652 29 26V24C29.0002 23.8687 28.9744 23.7386 28.9241 23.6172C28.8738 23.4959 28.8 23.3857 28.707 23.293L27 21.586ZM27 25H13V24.414L14.707 22.707C14.8 22.6143 14.8738 22.5041 14.9241 22.3828C14.9744 22.2614 15.0002 22.1313 15 22V18C15 15.243 17.243 13 20 13C22.757 13 25 15.243 25 18V22C25 22.266 25.105 22.52 25.293 22.707L27 24.414V25ZM20 30C20.6193 30.0008 21.2235 29.8086 21.7285 29.4502C22.2335 29.0917 22.6143 28.5849 22.818 28H17.182C17.3857 28.5849 17.7665 29.0917 18.2715 29.4502C18.7765 29.8086 19.3807 30.0008 20 30Z" fill="black"/>
</svg>


        </div>
  )
}

export default NotificationIcon