import React from 'react';
import "./WnPay.css";
import wnPayImage2 from "../../Assets/Assets/wnPay-image-2.png";
import wnPayImage3 from "../../Assets/SVG/wnPay-logo-1.svg";
import wnPayImage4 from "../../Assets/SVG/wnPay-logo-2.svg";
import wnPayImage5 from "../../Assets/SVG/wnPay-logo-3.svg";
import { Link } from "react-router-dom";

const WnPay = () => {
    return (
        <>
            {/* section 1 */}
            <div className=" container-fluid main-wnPay">
                <div className="row wnPay-section-1">
                    <div className='col-md-2 col-sm-2 col-1'></div>
                    <div className="col-md-8 col-sm-8 col-10">
                        <div className="wnPay-content-1">
                            <h2 className="wnPay-heading-1">
                                WnPay
                            </h2>
                            {/* <p className="wnPay-para-1">
                                Seamlessly pay worldwide with reduced bank fees and financing options for container purchases. Exclusive Visa card services available for members through our finance partners.
                            </p> */}
                        </div>
                    </div>
                    <div className='col-md-2 col-sm-2 col-1' ></div>
                </div>
            </div>

            {/* section 2 */}
            <div className="container wnPay-section-2">
                <div className="row ">
                    <div className="col-md-12 col-lg-6">
                        <div className="wnPay-content-2">
                            <p className="wnPay-heading-2">
                                About WnPay
                            </p>
                            <p className="wnPay-para-2">
                                WnPay is an innovative financial solution developed by WNVOEC in collaboration with Aspire. It is designed to streamline financial operations for businesses, enabling them to pay, manage, and earn smarter. With a strong focus on international transactions and expense management, WnPay offers a comprehensive platform for modern businesses to scale efficiently and effectively.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-md-12 col-lg-5">
                        <div className="wnPay-content-3">
                            <img src={wnPayImage2} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            {/* section 3 */}
            <div className='wnPay-Section-3'>
                <div className="container">
                    <p className="wnPay-heading-3">
                        How WnPay Works
                    </p>
                    <div className="row">
                        <div className='col-lg-6'>
                            <div className='wnPay-Work-Card'>
                                <p className="wnPay-para-3">
                                    Simple Online Application Process
                                </p>
                                <p className="wnPay-para-4">
                                    Opening a WnPay account is straightforward and entirely online. Businesses can apply in minutes, with account processing completed within 2-3 business days, allowing companies to get started without the hassle of visiting a bank.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='wnPay-Work-Card'>
                                <p className="wnPay-para-3">
                                    Cost-Effective Foreign Exchange
                                </p>
                                <p className="wnPay-para-4">
                                    WnPay provides significant savings on foreign exchange (FX) transactions, offering rates up to 3x cheaper than traditional banks. This benefit extends to over 60 currencies, making international payments more affordable and efficient.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-lg-6'>
                            <div className='wnPay-Work-Card'>
                                <p className="wnPay-para-3">
                                    Multi-Currency Accounts
                                </p>
                                <p className="wnPay-para-4">
                                    WnPay users can hold and receive funds in multiple currencies, including USD, SGD, EUR, GBP, and IDR. This feature is particularly advantageous for businesses engaged in cross-border transactions, as it simplifies the process of managing different currencies.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='wnPay-Work-Card'>
                                <p className="wnPay-para-3">
                                    Enhanced Payment Security
                                </p>
                                <p className="wnPay-para-4 wnPay-Crad-4">
                                    WnPay ensures secure transactions through features like merchant locks and spend limits on corporate cards. This helps businesses maintain control over their expenses and avoid unauthorized transactions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-lg-6'>
                            <div className='wnPay-Work-Card'>
                                <p className="wnPay-para-3">
                                    Unlimited Corporate Cards
                                </p>
                                <p className="wnPay-para-4">
                                    WnPay issues both physical and virtual Visa debit cards linked to the account at no additional charge. These cards can be assigned to different departments or employees, providing real-time control and visibility over spending. This feature includes automated receipt reminders and the ability to upload invoices directly onto the platform.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='wnPay-Work-Card'>
                                <p className="wnPay-para-3">
                                    Integrated Payments and Accounting
                                </p>
                                <p className="wnPay-para-4">
                                    The platform facilitates easy bill payments and invoicing. Businesses can create and send invoices with a few clicks and track payments seamlessly. WnPay also integrates with popular accounting software like Xero, QuickBooks, and NetSuite, allowing companies to automate their accounting processes and close books twice as fast.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section 4 */}
            <div className='wnPay-Section-4'>
                <div className="container">
                    <p className="wnPay-heading-4">
                        Advantages for WNVOEC Members
                    </p>
                    <p className="wnPay-para-5">
                        Exclusive benefits for WNVOEC members
                    </p>
                    <div className="row wnPay-Members-Upper">
                        <div className='col-sm-6 col-md-6 col-lg-4 wnPay-Members'>
                            <img src={wnPayImage3} alt="Value Home" className='img-fluid' />
                            <p className="wnPay-para-6">
                                Free USD Account
                            </p>
                            <p className="wnPay-para-7">
                                Members can open a USD account for free, eliminating the need for in-person bank visits
                                and reducing setup time to just a few days.
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 wnPay-Members'>
                            <img src={wnPayImage4} alt="Value Home" className='img-fluid' />
                            <p className="wnPay-para-6">
                                Lower FX Rates
                            </p>
                            <p className="wnPay-para-7">
                                Enjoy up to 40% savings on global payments and international transactions, benefiting from competitive exchange rates.
                            </p>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-4 wnPay-Members'>
                            <img src={wnPayImage5} alt="Value Home" className='img-fluid' />
                            <p className="wnPay-para-6">
                                Convenient Membership Payments
                            </p>
                            <p className="wnPay-para-7">
                                WnPay offers a secure and reliable method for paying membership fees, simplifying financial management for WNVOEC members.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* section 5 */}
            <div className="wnPay-section-5" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="wnTracker-content-5">
                                <div className="productConatctBtn">
                                    <p className="wnTracker-heading-6">
                                        Login now as a member or  &nbsp;
                                        <Link
                                            to="/joinus"
                                        >
                                            <span style={{ color: "white", textDecorationLine: "underline" }}>
                                                Join Now
                                            </span>
                                        </Link>
                                    </p>
                                </div>
                                <div>
                                    <Link className="text-decoration-none" to="/login">
                                        <button className="wnTracker-Contact-Btn2">
                                            Login
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WnPay;
