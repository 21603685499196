import React from "react";
import { useHistory } from "react-router-dom";
import membership from "../Assets/Png/membership.png";
import membership3 from "../Assets/Assets/membership3.png";
import Assset30 from "../Assets/Assets/Asset3.jpg";
import image1 from "../Assets/SVG/Platinum-Vector.svg";
import PlatinumImage from "../Assets/Assets/Platinum-Logo-Image.png";
import GoldImage from "../Assets/Assets/Gold-Logo-Image.png";
import "../Assets/Membership.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";


const Membership = () => {

  const history = useHistory();

  const handleButtonClick = () => {
    history.push("/products");
  };

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          Membership International Freight Forwarders and Freight Logistics
          Companies
        </title>
        <meta
          name="description"
          content="Internationational freight forwarders and freight logistics companies can sign up for WNVOEC Ordinary or Associate Membership. Enjoy membership benefits to grow your business through networking opportunities and valuable industry insights."
        />
      </Helmet>

      <div className="membership-page-1">
        <div
          className="membership-page-2"
          style={{
            backgroundImage: `url(${membership})`,
            backgroundSize: "cover",
            width: "100%",
            height: "970px",
            position: "relative",
          }}
        >
          <div
            className="membership-page-3"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "white",
              fontSize: "5rem",
              fontWeight: 700,

            }}
          >
            <p> {t("membershipPage.membership")} </p>
            <p
              style={{
                fontSize: "1.188rem",
                fontWeight: "500",
                marginTop: "-10px"
              }}
            >
              {t("membershipPage.membership-1")}
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-1"></div>
            <div className="col-lg-8 col-md-10">
              <p className="MemberShip-Tiers-Heading">
                MEMBERSHIP TIERS
              </p>
              <p className="MemberShip-Para-Upper">
                Finding your way in the complex world of managing global shipments demands a
                supportive environment. We provide two unique memberships:
              </p>
            </div>
            <div className="col-lg-2 col-md-1"></div>
          </div>
          <div className="row Platinum-Row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-6 Platinum-Section-1">
              <div className="text-center">
                <p className="Platinum-Heading-1">
                  Platinum Membership

                </p>
                <p className="Platinum-Para-1">
                  Experience unparalleled advantages with WNVOEC's Platinum Membership
                </p>
                <div className=" Platinum-logo-1">
                  <img
                    className="img-fluid"
                    src={PlatinumImage}
                    alt="Platinum Logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 Platinum-Section-2">
              <div className="pt-5 ps-5 pe-5">
                <p className="Platinum-Para-2">
                  Ideal for
                </p>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Container Manufacturers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Container Traders
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Ocean Liners
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Shipping Lines
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Shipping Agents
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Shipping Depot Operators
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    NVOCCs
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Freight Forwarder
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Distribution Hubs and Centers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Existing Gold members with over a year of edication to WNVOEC
                  </p>
                </div>
                <div className="d-flex mb-4 align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    WNVOEC Governors
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="row Gold-Row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-6 Platinum-Section-1">
              <div className="text-center">
                <p className="Gold-Heading-1">
                  Gold Membership

                </p>
                <p className="Gold-Para-1">
                  Enjoy exceptional benefits with WNVOEC's Gold Membership
                </p>
                <div className=" Gold-logo-1">
                  <img
                    className="img-fluid"
                    src={GoldImage}
                    alt="Gold Logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 Platinum-Section-2">
              <div className="pt-5 ps-5 pe-5">
                <p className="Platinum-Para-2">
                  Tailored for experienced professionals
                  in shipping and logistics
                </p>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Transport Operators
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Trucking Operators
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Marine Survey Companies
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Insurance Companies
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Asset Trackers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    System Integrators and IT System
                    Providers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3">
                    Any entities closely related to the
                    freight forwarding and logistics
                    industry
                  </p>
                </div>
                <div className="d-flex align-items-start mt-3">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-3 ms-3 mb-4">
                    Universities and Training Institutes
                    related to the marine industry
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>

        {/* <div className="container">
          <p className="MemberShip-Tiers-Heading">
            MEMBERSHIP TIERS
          </p>
          <div className="row Tiers-Row">
            <div className="col-lg-5 MemberShip-Tiers">
              <div className="Platinum-Upper">
                <p className="Platinum-Heading-2">
                  PLATINUM
                </p>
              </div>
              <div className="pt-5 ps-5 pe-5">
                <p className="Platinum-Para-1">
                  Ideal for
                </p>
                <div className="d-flex align-items-start mt-5">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Container Manufacturers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Container Traders
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Ocean Liners
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Shipping Lines
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Shipping Agents
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Shipping Depot Operators
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    NVOCCs
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Freight Forwarder
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Distribution Hubs and Centers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Existing Gold members with over a year of edication to WNVOEC
                  </p>
                </div>
                <div className="d-flex mb-4 align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    WNVOEC Governors
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 MemberShip-Tiers1">
              <div className="Gold-Upper">
                <p className="Platinum-Heading-2">
                  GOLD
                </p>
              </div>
              <div className="pt-5 ps-5 pe-5">
                <p className="Platinum-Para-1">
                  Tailored for experienced professionals in shipping and logistics.
                </p>
                <div className="d-flex align-items-start mt-5">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Transport Operators
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Trucking Operators
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Marine Survey Companies
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Insurance Companies
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Asset Trackers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    System Integrators and IT System Providers
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Any entities closely related to the freight forwarding and logistics industry
                  </p>
                </div>
                <div className="d-flex align-items-start mt-4 mb-4">
                  <img src={image1} alt='' className='img-fluid' />
                  <p className="Platinum-Para-2 ms-3">
                    Universities and Training Institutes related to the marine industry
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div> */}

        {/* <div className="membership-page-4"> */}
        {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
              paddingTop: "3rem",
              paddingBottom: "2rem",
              paddingLeft: '1rem',
              paddingRight: '1rem'
            }}
          > */}
        {/* <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                color: " rgb(12, 52, 89)",
              }}
            >
              {t("membershipPage.membership-2")}
            </h1> */}
        {/* <p
              style={{
                textAlign: "center",
                fontSize: "19px",
                fontWeight: "500",
                lineHeight: "32px",
                maxWidth: "710px",
                color: "rgb(161, 174, 183)",
              }}
            >
              {t("membershipPage.membership-3")}
            </p> */}

        {/* <div
              className="membership-page-5"
              style={{ display: "flex", justifyContent: "center", gap: "2rem" }}
            > */}
        {/* <div style={{
                textAlign: "center", width: "100%",
                padding: '15px',
              }}> */}
        {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                > */}
        {/* <circle cx="30" cy="30" r="30" fill="black" /> */}

        {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="14"
                    y="14"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <mask
                      id="mask0_65_498"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="32"
                      height="32"
                    >
                      <path
                        d="M0 1.90735e-06H32V32H0V1.90735e-06Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_65_498)">
                      <path
                        d="M14.4988 8.58498C13.8023 8.01991 12.9144 7.68129 11.9476 7.68129H9.80245C8.83001 7.68129 7.93763 8.02379 7.23926 8.59473"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.875 2.31228C9.5272 2.31228 8.43457 3.40484 8.43457 4.75271V5.24077C8.43457 6.58865 9.5272 7.68127 10.875 7.68127C12.2228 7.68127 13.3154 6.58865 13.3154 5.24077V4.75271C13.3154 3.40484 12.2228 2.31228 10.875 2.31228Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.875 7.68131V9.09375"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24.7438 8.58099C24.048 8.0183 23.1621 7.6813 22.1975 7.6813H20.0524C19.0818 7.6813 18.1909 8.02255 17.493 8.59167"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.125 2.31228C19.7772 2.31228 18.6846 3.40484 18.6846 4.75271V5.24077C18.6846 6.58865 19.7772 7.68127 21.125 7.68127C22.4728 7.68127 23.5654 6.58865 23.5654 5.24077V4.75271C23.5654 3.40484 22.4728 2.31228 21.125 2.31228Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.125 7.68131V9.09375"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.47356 18.8547H10.875V17.5382C10.875 15.3 9.06069 13.4857 6.82256 13.4857H4.67744C2.43931 13.4857 0.625 15.3 0.625 17.5382V18.8547H2.84856"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.75001 8.11653C4.4022 8.11653 3.30957 9.20916 3.30957 10.557V11.0451C3.30957 12.393 4.4022 13.4856 5.75001 13.4856C7.09782 13.4856 8.19045 12.393 8.19045 11.0451V10.557C8.19045 9.20916 7.09782 8.11653 5.75001 8.11653Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.75 13.4856V14.8981"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.125 18.8547V17.5382C21.125 15.3 19.3107 13.4857 17.0726 13.4857H14.9274C12.6893 13.4857 10.875 15.3 10.875 17.5382V18.8547"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 8.11653C14.6522 8.11653 13.5596 9.20916 13.5596 10.557V11.0451C13.5596 12.393 14.6522 13.4856 16 13.4856C17.3478 13.4856 18.4404 12.393 18.4404 11.0451V10.557C18.4404 9.20916 17.3478 8.11653 16 8.11653Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 13.4856V14.8981"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M27.3226 13.4856H25.1774C22.9393 13.4856 21.125 15.2999 21.125 17.5381V18.8546H31.375V17.5381C31.375 15.2999 29.5607 13.4856 27.3226 13.4856Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M26.25 8.11653C24.9022 8.11653 23.8096 9.20916 23.8096 10.557V11.0451C23.8096 12.393 24.9022 13.4856 26.25 13.4856C27.5978 13.4856 28.6904 12.393 28.6904 11.0451V10.557C28.6904 9.20916 27.5978 8.11653 26.25 8.11653Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M26.25 13.4856V14.8981"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.64844 23.3357C9.64844 19.8279 12.4921 16.9842 16 16.9842C19.5078 16.9842 22.3515 19.8279 22.3515 23.3357C22.3515 26.8436 19.5078 29.6873 16 29.6873C12.4921 29.6873 9.64844 26.8436 9.64844 23.3357Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.9525 21.7561L15.4334 20.2605C15.6089 19.7148 16.3807 19.7138 16.5576 20.2591L17.0429 21.7561H18.6273C19.1998 21.7561 19.4375 22.4889 18.9741 22.825L17.6959 23.7518L18.1856 25.2444C18.3642 25.7889 17.7407 26.2432 17.2771 25.9063L16.0064 24.9827L14.7288 25.9135C14.2659 26.2508 13.642 25.7977 13.8194 25.2531L14.3092 23.7502L13.0286 22.8256C12.564 22.4902 12.8013 21.7561 13.3744 21.7561H14.9525Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg> */}

        {/* <img src={membership1} alt="" srcset="" style={{ filter: "brightness(100%)" }} /> */}
        {/* </svg> */}

        {/* <h3
                  style={{
                    fontSize: "1.28rem",
                    fontWeight: "bold",
                    padding: "20px 0",
                    color: " rgb(12, 52, 89)",
                  }}
                >
                  {t("membershipPage.membership-4")}
                </h3> */}
        {/* <p style={customStyle}>
                  {t("membershipPage.membership-5")}
                </p> */}
        {/* </div> */}

        {/* <div style={{
                textAlign: "center", width: "100%",
                padding: '15px'
              }}>
                <Associate />
                <h3
                  style={{
                    fontSize: "1.28rem",
                    fontWeight: "bold",
                    padding: "20px 0",
                    color: " rgb(12, 52, 89)",
                  }}
                >
                  {t("membershipPage.membership-6")}{" "}
                </h3>
                <p style={customStyle}>
                  {t("membershipPage.membership-7")}{" "}
                </p>
              </div> */}

        {/* <div style={{
                textAlign: "center", width: "100%",
                padding: '15px'
              }}>
                <Afiliates />
                <h3
                  style={{
                    fontSize: "1.28rem",
                    padding: "20px 0",
                    color: " rgb(12, 52, 89)",
                    fontWeight: "bold",
                  }}
                >
                  {t("membershipPage.membership-14")}{" "}
                </h3>
                <p style={customStyle}>
                  {t("membershipPage.membership-15")}
                </p>
              </div> */}
        {/* </div>
          </div>
        </div> */}

        <div className="container-fluid" style={{ background: "#0C3459" }}>
          <div className="row pt-5 pb-5">
            <div className="col-lg-1 "> </div>
            <div className="col-lg-4 mx-auto col-md-6"
              style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
            >
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "700",
                  fontStyle: "normal",
                  color: "rgb(112, 214, 255)",
                }}
              >
                {t("membershipPage.membership-8")}
              </h3>
              <p
                style={{
                  fontSize: "1.188rem",
                  fontWeight: "500",
                  color: "rgb(161, 174, 183)",
                }}
              >
                {t("membershipPage.membership-9")}
              </p>
              <button
                style={{
                  background: "#0C3459",
                  color: "white",
                  width: "190px",
                  height: "44px",
                  fontWeight: "400",
                  border: "1px solid white",
                }}
                onClick={handleButtonClick}
              >
                {t("membershipPage.membership-10")}
              </button>
            </div>
            <div className="col-lg-1"> </div>
            <div className="col-lg-5 col-md-6 mt-5 mx-auto">
              <img
                className="img-fluid"
                src={membership3}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row pt-5 pb-5">
            <div className="col-lg-1"> </div>
            <div className="col-lg-5 col-md-6 mt-5 mx-auto">
              <img
                className="img-fluid"
                src={Assset30}
                alt=""
              />
            </div>
            <div className="col-lg-5 mx-auto col-md-6"
              style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
            >
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "700",
                  color: " rgb(12, 52, 89)",
                }}
              >
                {t("membershipPage.membership-11")}
              </h3>
              <p
                style={{
                  fontSize: "1.188rem",
                  fontWeight: "500",
                  color: "rgb(161, 174, 183)",
                }}
              >
                {" "}
                {t("membershipPage.membership-13")}
              </p>
              <p
                style={{
                  fontSize: "1.188rem",
                  fontWeight: "500",
                  color: "rgb(161, 174, 183)",
                }}
              >
                {t("membershipPage.membership-12")}
              </p>
            </div>
            <div className="col-lg-1"> </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Membership;
