import React from 'react'

const Container = () => {
  return (
    <div>
        <svg width="25" height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_466_556)">
<path opacity="0.973" fill-rule="evenodd" clip-rule="evenodd" d="M7.99805 0.263672C8.66602 0.263672 9.33398 0.263672 10.002 0.263672C10.0137 1.18975 10.002 2.11553 9.9668 3.04102C9.64589 2.9028 9.31778 2.78561 8.98242 2.68945C8.65389 2.7952 8.32577 2.90066 7.99805 3.00586C7.99805 2.0918 7.99805 1.17773 7.99805 0.263672Z" fill="black" fill-opacity="0.8"/>
<path opacity="0.968" fill-rule="evenodd" clip-rule="evenodd" d="M8.94775 3.2168C10.7894 3.87759 12.6293 4.54556 14.4673 5.2207C14.5024 6.40406 14.5141 7.58767 14.5024 8.77148C14.4389 8.7718 14.3803 8.75422 14.3267 8.71875C12.551 7.74302 10.7698 6.77623 8.98291 5.81836C7.21947 6.76452 5.46166 7.71957 3.70947 8.68359C3.63388 8.7332 3.55183 8.76252 3.46338 8.77148C3.45166 7.58767 3.46338 6.40406 3.49853 5.2207C5.32184 4.56054 7.13826 3.89257 8.94775 3.2168ZM6.87353 5.04492C7.18635 5.05579 7.28011 5.20225 7.15478 5.48438C6.83802 5.62124 6.51575 5.74429 6.18799 5.85352C5.92544 5.75747 5.8727 5.59927 6.02978 5.37891C6.31592 5.27006 6.59717 5.15872 6.87353 5.04492ZM10.8813 5.04492C11.2347 5.10996 11.5745 5.2213 11.9009 5.37891C12.0723 5.61125 12.0196 5.76946 11.7427 5.85352C11.428 5.74668 11.1175 5.6295 10.811 5.50195C10.671 5.32431 10.6945 5.17194 10.8813 5.04492ZM5.39697 5.64258C5.71879 5.68273 5.80081 5.84093 5.64306 6.11719C5.31316 6.26629 4.9733 6.37175 4.62353 6.43359C4.42283 6.30896 4.39941 6.1566 4.55322 5.97656C4.83936 5.86772 5.12061 5.75638 5.39697 5.64258ZM12.3579 5.64258C12.7068 5.72175 13.0467 5.83309 13.3774 5.97656C13.5526 6.21844 13.494 6.37664 13.2017 6.45117C12.9193 6.35509 12.638 6.25549 12.3579 6.15234C12.1281 5.98113 12.1281 5.81122 12.3579 5.64258Z" fill="black" fill-opacity="0.8"/>
<path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M8.66602 6.55664C8.6952 6.93724 8.71277 7.32396 8.71875 7.7168C8.76009 7.92214 8.88314 7.99829 9.08789 7.94531C9.15233 7.92773 9.19336 7.88671 9.21094 7.82227C9.25566 7.40285 9.27907 6.98098 9.28125 6.55664C11.921 8.00251 14.5636 9.44392 17.209 10.8809C16.4752 12.4948 15.737 14.1062 14.9941 15.7148C14.5167 15.7216 14.0831 15.8623 13.6934 16.1367C13.243 16.4968 12.7625 16.8132 12.252 17.0859C11.8626 17.1945 11.4994 17.1359 11.1621 16.9102C10.816 16.6727 10.4762 16.4266 10.1426 16.1719C9.87086 16.0038 9.58373 15.869 9.28125 15.7676C9.26954 13.4707 9.2578 11.1738 9.24609 8.87695C9.19009 8.59834 9.03776 8.52803 8.78906 8.66602C8.75486 8.73253 8.73141 8.80284 8.71875 8.87695C8.70704 11.1738 8.6953 13.4707 8.68359 15.7676C8.38389 15.8753 8.09677 16.0101 7.82227 16.1719C7.48863 16.4266 7.14881 16.6727 6.80273 16.9102C6.29244 17.223 5.78855 17.2113 5.29102 16.875C4.80396 16.4937 4.28832 16.1539 3.74414 15.8555C3.51327 15.787 3.27889 15.7343 3.04102 15.6973C2.2697 14.0961 1.50798 12.4906 0.755859 10.8809C3.40039 9.4474 6.0371 8.00599 8.66602 6.55664Z" fill="black" fill-opacity="0.8"/>
<path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M3.04102 16.2598C3.40562 16.2622 3.73373 16.3735 4.02539 16.5938C4.50271 16.9937 5.02421 17.3277 5.58984 17.5957C5.74446 17.6436 5.90266 17.6729 6.06445 17.6836C4.13086 17.707 2.19727 17.707 0.263672 17.6836C0.563688 17.6656 0.844938 17.5836 1.10742 17.4375C1.53899 17.1582 1.96087 16.8653 2.37305 16.5586C2.58291 16.4207 2.80556 16.3211 3.04102 16.2598Z" fill="black" fill-opacity="0.8"/>
<path opacity="0.965" fill-rule="evenodd" clip-rule="evenodd" d="M8.8418 16.2598C9.1607 16.2586 9.45366 16.3465 9.7207 16.5234C10.1329 16.8301 10.5547 17.1231 10.9863 17.4023C11.2593 17.5597 11.5523 17.6535 11.8652 17.6836C9.94335 17.707 8.0215 17.707 6.09961 17.6836C6.41254 17.6535 6.70553 17.5597 6.97852 17.4023C7.41009 17.1231 7.83197 16.8301 8.24414 16.5234C8.43863 16.4142 8.63786 16.3263 8.8418 16.2598Z" fill="black" fill-opacity="0.8"/>
<path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M14.6426 16.2598C14.863 16.2535 15.074 16.2945 15.2754 16.3828C15.8123 16.7206 16.3396 17.0722 16.8574 17.4375C17.1199 17.5836 17.4011 17.6656 17.7012 17.6836C15.7676 17.707 13.834 17.707 11.9004 17.6836C12.2445 17.6626 12.5609 17.5572 12.8496 17.3672C13.2227 17.123 13.5859 16.8652 13.9395 16.5938C14.1635 16.4523 14.3979 16.341 14.6426 16.2598Z" fill="black" fill-opacity="0.8"/>
</g>
<defs>
<clipPath id="clip0_466_556">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Container