import React, { useState } from "react";
import GenericTable from "../../../Components/CommonTable/GenericTable";
import CompareApplications from "./CompareApplications";

const DashboardReviewEdit = (props) => {
  const [compare, setCompare] = useState(null);

  const tableCols = [
    { id: "id", label: "ID" },
    {
      id: "email",
      label: "Email",
      accessor: (row, col) => {
        return <span>{row?.attributes?.email}</span>;
      },
    },
    {
      id: "nameOfApplicantCompany",
      label: "Company Name",
      accessor: (row, col) => {
        return <span>{row?.attributes?.nameOfApplicantCompany}</span>;
      },
    },

    {
      id: "companyType",
      label: "Type",
      accessor: (row, col) => {
        return <span>{row?.attributes?.companyType}</span>;
      },
    },
    {
      id: "companyEmail",
      label: "Company Email",
      accessor: (row, col) => {
        return <span>{row?.attributes?.emailAddressOfCompany}</span>;
      },
    },
    {
      id: "telephone",
      label: "Number",
      accessor: (row, col) => {
        return <span>{row?.attributes?.telephoneNumber}</span>;
      },
    },
    {
      id: "tableActions",
      actions: true,
      label: "Actions",
      actionOpts: {
        view: true,
      },
    },
  ];

  const editCols = [
    {
      id: "approve",
      label: "Approve",
      accessor: (row, col) => {
        return row?.attributes?.approved ? (
          <span>Yes</span>
        ) : (
          <select
            name=""
            defaultValue="choose"
            id=""
            className="selection_box"
            onChange={(e) => props.onEditApproveChange(row, e.target.value)}
          >
            <option value="choose">Choose</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        );
      },
    },
  ];
  return (
    <>
      {compare ? (
        <div style={{ paddingTop: "25px" }}>
          <button
            className="btn application_back_btn"
            onClick={(e) => setCompare(null)}
          >
            Back
          </button>
          {/* <ProfileDetails applicationData={selectedApplication} user={props.user} /> */}
          <CompareApplications user={props.user} compare={compare} />
        </div>
      ) : (
        <div>
          {!props.noEdit && (
            <div
              style={{
                marginBottom: "15px",
                marginRight: "50px",
                textAlign: "right",
              }}
            >
              <button className="invoice_file_label" onClick={props.onSave}>
                Save
              </button>
            </div>
          )}
          <GenericTable
            cols={props.noEdit ? tableCols : [...tableCols, ...editCols]}
            rows={props.tableData}
            actionMethods={{
              onViewClick: (row) => setCompare(row?.attributes),
            }}
          />
        </div>
      )}
    </>
  );
};

export default DashboardReviewEdit;
