import React from "react";

const Email = ({ color }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7695 2.57812H1.23047C0.550621 2.57812 0 3.13216 0 3.80859V11.1914C0 11.8719 0.554668 12.4219 1.23047 12.4219H12.7695C13.4437 12.4219 14 11.8742 14 11.1914V3.80859C14 3.13337 13.4516 2.57812 12.7695 2.57812ZM12.5972 3.39844C12.3458 3.6485 8.01946 7.95207 7.87008 8.10066C7.63766 8.33308 7.32867 8.46105 7 8.46105C6.67133 8.46105 6.36234 8.33305 6.12916 8.09989C6.0287 7.99995 1.75008 3.7439 1.40279 3.39844H12.5972ZM0.820312 11.0244V3.97607L4.3651 7.50219L0.820312 11.0244ZM1.40331 11.6016L4.9467 8.0807L5.54988 8.6807C5.93723 9.06805 6.45222 9.28136 7 9.28136C7.54778 9.28136 8.06277 9.06805 8.44936 8.68147L9.0533 8.0807L12.5967 11.6016H1.40331ZM13.1797 11.0244L9.6349 7.50219L13.1797 3.97607V11.0244Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default Email;
