import React, { useEffect, useState } from "react";
import GenericTable from "../../../Components/CommonTable/GenericTable";
import { DataGrid } from "@mui/x-data-grid";
import ProfileDetails from "../../Profile/ProfileDetails";
import { apiCaller } from "../../../utils/api";
import CreateAppAdmin from "./CreateAppAdmin";
import EditProfileForm from "../../Profile/EditProfileForm";
import EditAppAdmin from "./EditAppAdmin";
import UserEdit from "../../../Assets/Icons/UserEdit";
import ViewIcon from "../../../Assets/Icons/ViewIcon";

const DashboardApplications = (props) => {
  const [selectedApplication, setApplicationProfile] = useState(null);
  const [query, setQuery] = useState("");
  const [searchOpt, setSearchOpt] = useState("searchByName");
  const [sortOpt, setSortOpt] = useState("sortByDate");
  const applicationsPerPage = 10;
  const [page, SetPage] = useState(1);
  const [expand, setExpand] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);

  const [createAppData, setCreateAppData] = useState(null);

  // console.log(expand);

  const getInvoices = async () => {
    let result = await apiCaller("get", "invoices");
    if (result.err) console.log(null);
    else {
      setInvoiceData(result.data);
    }
  };

  const usersWithoutApplications = props.userList.filter((user) => {
    const hasApplication = props.tableData.some(
      (app) => app.attributes.email === user.email
    );
    const isVerified = user.confirmed;

    return !hasApplication && isVerified;
  });

  useEffect(() => {
    getInvoices();
  }, [props.tableData]);

  const sortApplications = (applications) => {
    if (sortOpt === "sortByDate") {
      return applications.sort((a, b) => {
        const dateA = new Date(a.attributes.createdAt);
        const dateB = new Date(b.attributes.createdAt);
        return dateB - dateA;
      });
    } else if (sortOpt === "sortByAlphabet") {
      return applications.sort((a, b) => {
        const nameA = a.attributes.nameOfApplicantCompany.toLowerCase();
        const nameB = b.attributes.nameOfApplicantCompany.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }
    return applications;
  };

  const tableCols = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      renderCell: (params) => {
        return <span>{params?.row?.attributes?.email}</span>;
      },
      valueGetter: (params) => params?.row?.attributes?.email,
    },

    {
      field: "nameOfApplicantCompany",
      headerName: "Company Name",
      width: 300,
      renderCell: (params) => {
        return <span>{params?.row?.attributes?.nameOfApplicantCompany}</span>;
      },
      valueGetter: (params) => params?.row?.attributes?.nameOfApplicantCompany,
    },

    {
      field: "companyType",
      headerName: "Type",
      width: 200,
      renderCell: (params) => {
        return <span>{params?.row?.attributes?.companyType}</span>;
      },
      valueGetter: (params) => params?.row?.attributes?.companyType,
    },
    {
      field: "companyEmail",
      headerName: "Company Email",
      width: 250,
      renderCell: (params) => {
        return <span>{params?.row?.attributes?.emailAddressOfCompany}</span>;
      },
      valueGetter: (params) => params?.row?.attributes?.emailAddressOfCompany,
    },
    {
      field: "telephone",
      headerName: "Number",
      width: 250,
      renderCell: (params) => {
        return <span>{params?.row?.attributes?.telephoneNumber}</span>;
      },
      valueGetter: (params) => params?.row?.attributes?.telephoneNumber,
    },
    // {
    //   id: "tableActions",
    //   actions: true,
    //   label: "Actions",
    //   actionOpts: {
    //     view: true,
    //   },
    // },
   {
      field: "referMemberId",
      headerName: "Referred By ",
      width: 150,
      renderCell: (params) => {
        // console.log("params", params);
        return (
          <span>
            {params?.row?.attributes?.referMemberId === "159" ? "Josh" : "N.A"}
          </span>
        );
      },
      valueGetter: (params) => params?.row?.attributes?.referMemberId,
    },


    {
      field: "tableActions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setApplicationProfile(params.row?.attributes)}
          >
            <ViewIcon />
          </span>
        </div>
      ),
    },
    // {
    //   field: 'editApp',
    //   headerName: 'Edit App',
    //   width: 200,
    //   renderCell: (params) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <span
    //         style={{ cursor: 'pointer' }}
    //         onClick={() => props.setEditApp(params?.row)}
    //       >
    //          <UserEdit />
    //       </span>
    //     </div>
    //   ),
    // }
  ];

  function formatString(inputString) {
    // If the string is empty or undefined, return an empty string
    if (!inputString) {
      return "";
    }
    if (inputString == "foundingMember") {
      return "Founding Member";
    } else {
      const words = inputString.split(" ");

      // Capitalize the first letter of each word
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );

      // Join the words back together
      const formattedString = capitalizedWords.join(" ");

      return formattedString;
    }
    // Split the string into words
  }

  const editCols = [
    {
      field: "approve",
      headerName: "Approve",
      renderCell: (params) => {
        return params?.row?.attributes?.Approved ? (
          <span>Yes</span>
        ) : (
          <select
            name=""
            defaultValue="no"
            id=""
            className="selection_box"
            onChange={(e) =>
              props.onAppApproveChange(params?.row, e.target.value)
            }
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
            {/* <option value="fm">FM</option> */}
          </select>
        );
      },
    },
    {
      field: "membershipType",
      headerName: "Membership Type",
      width: 250,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "2px",
              alignItems: "center",
            }}
          >
            {props.isEdit?.id === params?.row?.id ? (
              <select
                style={{
                  width: "10rem",
                }}
                name=""
                defaultValue={"choose"}
                id=""
                className="selection_box"
                onChange={(e) =>
                  props.onAppApproveChange(params?.row, e.target.value)
                }
              >
                <option value="choose">Choose</option>
                <option value="ordinary">Ordinary</option>
                <option value="associate">Associate</option>
                <option value="affiliate">Affiliate</option>
                <option value="foundingMember">Founding Member</option>
              </select>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                {/* {props.tableData.find(
                  (application) =>
                    application?.attributes?.email === row?.attributes?.email
                )?.attributes?.directAccess === true ? (
                  <span>Founding Member</span>
                ) : (
                  <span></span>
                )}

                {invoiceData.find(
                  (invoice) =>
                    invoice?.attributes?.email === row.attributes?.email
                )?.attributes?.received === true &&
                props?.tableData.find(
                  (application) =>
                    application?.attributes?.email === row.attributes?.email
                )?.attributes?.directAccess !== true ? (
                  <span>Ordinary</span>
                ) : (
                  <span></span>
                )} */}
                {/* {props?.tableData?.find(
                  (application) =>
                    application?.attributes?.email === row.attributes?.email
                ) ? (
                  <span></span>
                ) : (
                  <span>N/A</span>
                )} */}
                {formatString(
                  props?.tableData?.find(
                    (app) =>
                      app?.attributes?.email === params?.row?.attributes?.email
                  )?.attributes?.membershipType
                )}
              </div>
            )}
            {props.isEdit?.id !== params?.row?.id ? (
              <span
                onClick={() => props.setIsEdit({ id: params?.row?.id })}
                style={{
                  cursor: "pointer",
                  paddingRight: "50px",
                }}
              >
                <UserEdit />
              </span>
            ) : (
              <span
                onClick={() => props.setIsEdit({})}
                style={{
                  cursor: "pointer",
                  paddingRight: "50px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.1em"
                  viewBox="0 0 384 512"
                  fill="#034ea2"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: "editApp",
      headerName: "Edit App",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => props.setEditApp(params?.row)}
            >
              <UserEdit />
            </span>
          </div>
        );
      },
    },
  ];

  let toReturn = selectedApplication ? (
    <div style={{ paddingTop: "25px" }}>
      <button
        className="btn application_back_btn"
        onClick={(e) => setApplicationProfile(null)}
      >
        Back
      </button>
      <ProfileDetails applicationData={selectedApplication} user={props.user} />
    </div>
  ) : (
    <div>
      {createAppData ? (
        <div>
          <button
            className="btn application_back_btn"
            onClick={() => setCreateAppData(null)}
          >
            Back
          </button>
          <CreateAppAdmin
            userData={createAppData}
            setCreateAppData={setCreateAppData}
            getApplications={props.getApplications}
          />
        </div>
      ) : (
        <div>
          {!props.noEdit && (
            <div
              style={{
                marginBottom: "15px",
                marginRight: "50px",

                display: "flex",
                justifyContent: "end",
                gap: "1rem",
              }}
            >
              <div class="">
                <div className="c-dropdown">
                  {/* <button class="dropbtn">Settings</button> */}
                  <button
                    className="invoice_file_label dropdown-toggle"
                    type="button"
                  >
                    Create
                  </button>
                  <div
                    className="dropdown-content"
                    style={{
                      right: "1rem",
                    }}
                  >
                    {usersWithoutApplications.length < 1 ? (
                      <li
                        style={{
                          padding: "0.5rem 1rem",
                        }}
                      >
                        No users without applications
                      </li>
                    ) : (
                      <li>
                        {usersWithoutApplications.map((user) => {
                          return (
                            <span
                              key={user.id}
                              className="dropdown-item"
                              onClick={() => setCreateAppData(user)}
                            >
                              {user.name} - {user.email}
                            </span>
                          );
                        })}
                      </li>
                    )}
                  </div>
                </div>
              </div>
              <button className="invoice_file_label" onClick={props.onSave}>
                Save
              </button>
            </div>
          )}
          {/* <GenericTable
            cols={props.noEdit ? tableCols : [...tableCols, ...editCols]}
            rows={props.tableData}
            actionMethods={{
              onViewClick: (row) => setApplicationProfile(row?.attributes),
            }} */}
          <DataGrid
            style={{ height: 650 }}
            rows={props.tableData}
            columns={props.noEdit ? tableCols : [...tableCols, ...editCols]}
            pageSize={10}
          />
          {/* /> */}
        </div>
      )}
    </div>
  );
  return props.editApp == null ? (
    toReturn
  ) : (
    <div>
      <div className="container">
        <button
          className="btn application_back_btn"
          onClick={() => props.setEditApp(null)}
        >
          Back
        </button>
      </div>
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <EditAppAdmin
          application={props.editApp}
          user={props.user}
          onSubmit={props.onSubmit}
          setComCert={props.setComCert}
          setLogo={props.setLogo}
        />
      </div>
    </div>
  );
};
export default DashboardApplications;
