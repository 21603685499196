import { useRef, useState } from "react";
import GenericTable from "../../../Components/CommonTable/GenericTable";
import { DataGrid } from "@mui/x-data-grid";
import CreateNewInvoice from "../../../Components/CreateNewInvoice/CreateNewInvoice";
import UserEdit from "../../../Assets/Icons/UserEdit";
import TrashLcon from "../../../Assets/Icons/TrashLcon";
import UploadIcons from "../../../Assets/Icons/UploadIcons";

const DashboardInvoices = (props) => {
  // console.log("props" ,props);
  const [createNewInvoice, setCreateNewInvoice] = useState(false);
  const modalRef = useRef(null);

  const tableCols = [
    { field: "id", headerName: "ID", width: 150, filterable: true },
    {
      field: "invoiceUID",
      headerName: "Invoice ID",
      width: 200,
      filterable: true,
      renderCell: (params) => <span>{params?.row?.attributes?.invoiceId}</span>,
      valueGetter: (params) => params?.row?.attributes?.invoiceId,
    },

    {
      field: "createdAtInvoice",
      headerName: "Date Created",
      width: 200,
      renderCell: (params) => (
        <span>{params?.row?.attributes?.createdAt.split("T")[0]}</span>
      ),
      valueGetter: (params) => params?.row?.attributes?.createdAt.split("T")[0],
    },

    {
      field: "companyEmail",
      headerName: "Company Name",
      width: 200,
      renderCell: (params) => (
        <span>{params?.row?.attributes?.companyName}</span>
      ),
      valueGetter: (params) => params?.row?.attributes?.amount,
    },
    //     {
    //       id: "amount",
    //       label: "Amount",
    //       accessor: (row, col) => {
    //         return <span>{row?.attributes?.amount}</span>;
    //       },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      renderCell: (params) => <span>{params?.row?.attributes?.amount}</span>,
      valueGetter: (params) => params?.row?.attributes?.amount,
    },

    {
      field: "received",
      headerName: "Received",
      width: 200,
      renderCell: (params) => {
        // return <span>{row?.attributes?.received ? "Yes" : "No"}</span>;
        return params?.row?.attributes?.received ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              alignItems: "center",
            }}
          >
            {props.receiveEdit?.id === params?.row?.id ? (
              <select
                style={{
                  width: "10rem",
                }}
                name=""
                defaultValue={"choose"}
                id=""
                className="selection_box"
                onChange={(e) =>
                  props.onInvoiceReceived(params?.row, e.target.value)
                }
              >
                <option value="choose">Choose</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                {/* {row?.["member-manager"] && <span>Admin</span>}
                {row?.governor && <span>Governor</span>}

                {!row?.["member-manager"] && !row?.governor && (
                  <span>Regular</span>
                )} */}
                <span> {params?.row?.attributes?.received ? "Yes" : "No"}</span>
              </div>
            )}
            {props.receiveEdit?.id !== params?.row?.id ? (
              <span
                onClick={() => props.setReceiveEdit({ id: params?.row?.id })}
                style={{
                  cursor: "pointer",
                  paddingRight: "50px",
                }}
              >
                <UserEdit />
              </span>
            ) : (
              <span
                onClick={() => props.setReceiveEdit({})}
                style={{
                  cursor: "pointer",
                  paddingRight: "50px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.1em"
                  viewBox="0 0 384 512"
                  fill="#034ea2"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </span>
            )}
          </div>
        ) : (
          <select
            name=""
            id=""
            className="selection_box"
            onChange={(e) =>
              props.onInvoiceReceived(params?.row, e.target.value)
            }
          >
            <option value="yes">Yes</option>
            <option value="no" selected>
              No
            </option>
          </select>
        );
      },
      valueGetter: (params) => params?.row?.attributes?.amount,
    },

    {
      field: "upload",
      headerName: "Invoice File",
      width: 250,
      renderCell: (params) => {
        return params?.row?.attributes?.invoice?.data &&
          params?.row.attributes.invoice.data[0] ? (
          <div className="d-flex gap-4">
            <div>
              <label
                for={`upload_invoice_${params?.row.id}`}
                // className="btn invoice_upload_btn"
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z" />
                </svg>
              </label>
              <input
                type="file"
                id={`upload_invoice_${params?.row?.id}`}
                style={{ display: "none" }}
                onChange={(e) =>
                  props.uploadInvoice(params?.row, e.target.files[0])
                }
              ></input>
            </div>
            <a
              href={downloadURLGet(
                params?.row.attributes.invoice.data[
                  params?.row?.attributes?.invoice?.data?.length - 1
                ]
              )}
              className="invoice_download_btn"
            >
              {
                params?.row.attributes.invoice.data[
                  params?.row?.attributes?.invoice?.data?.length - 1
                ].attributes.name
              }
            </a>
          </div>
        ) : (
          <>
            <div>
              <label
                for={`upload_invoice_${params?.row?.id}`}
                className="btn invoice_upload_btn"
              >
                Upload
              </label>

              <input
                type="file"
                id={`upload_invoice_${params?.row?.id}`}
                style={{ display: "none" }}
                onChange={(e) =>
                  props.uploadInvoice(params?.row, e.target.files[0])
                }
              ></input>
            </div>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <span
            onClick={() => props.deleteInvoice(params?.row)}
            style={{
              cursor: "pointer",
            }}
          >
            <TrashLcon />
          </span>
        );
      },
    },
  ];
  return (
    <div>
      <div
        style={{
          marginBottom: "15px",
          marginRight: "50px",
          // textAlign: "right",
          display: "flex",
          gap: "1rem",
          justifyContent: "flex-end",
        }}
      >
        {/* <button
          className="invoice_file_label"
          onClick={() => setCreateNewInvoice(true)}
        >
          Create
        </button> */}

        <button
          type="button"
          className="invoice_file_label"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => setCreateNewInvoice(true)}
        >
          Create
        </button>

        <button className="invoice_file_label" onClick={props.onSave}>
          Save
        </button>
      </div>

      {/* {createNewInvoice && (
        <CreateNewInvoice setCreateNewInvoice={setCreateNewInvoice} />
      )} */}

      <div style={{ height: 800, width: "100%" }}>
        <DataGrid rows={props.tableData} columns={tableCols} pageSize={10} />
      </div>

      <div>
        <div
          className="modal fade"
          ref={modalRef}
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-heading">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Create New Invoice
                  </h5>
                </div>
                {/* <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
              </div>
              <div className="modal-body">
                <CreateNewInvoice
                  user={props.user}
                  getInvoices={props.getInvoices}
                  modalRef={modalRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardInvoices;

function downloadURLGet(file) {
  const downloadUrl = `${process.env.REACT_APP_IMG_URL}${file.attributes?.url}`;
  return downloadUrl;
}
