import React from "react";
import { Link } from "react-scroll";
import AboutTabs from "../Components/AboutTabs";
import asset1 from "../Assets/Png/Asset 24.webp";
import asset25 from "../Assets/Assets/Asset25.png";
import asset27 from "../Assets/Assets/Asset27.png";
import asset21 from "../Assets/SVG/Asset21.svg";
import asset22 from "../Assets/SVG/Asset22.svg";
import asset23 from "../Assets/SVG/Asset23.svg";
import backgroundImage1 from "../Assets/Png/Asset26.webp";
import Counter from "../Components/Counter";
import Testimonial2 from "../Components/Testimonial2";
import linkedin from "../Assets/SVG/linkedin.svg";
import { Helmet } from "react-helmet-async";
import "../Assets/Home.css";
import "../Assets/About.css";
import MapChart from "../Components/MapChart";
import Team from "../Components/Team/Team";
import Faq from "../Components/Faq";
import { useTranslation } from "react-i18next";
function About() {
  const { t } = useTranslation();
  const tabs = [
    {
      id: 1,
      name: t("about.innovation.heading"),
      content: (
        <div className="container mb-5 mt-5 pb-5 content_container">
          <div className="tab_columns">
            <div className="col-md-6">
              <img
                className="img-fluid mt-5"
                src={asset25}
                alt="Networking Facilities"
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="tab-content">
                <h1 className="mb-3 section3 ms-5 mt-5">
                  {t("about.innovation.heading")}
                </h1>
                <p
                  className="ms-5 mb-5 whatWeDoPara"
                >
                  {t("about.innovation.para")}
                </p>
                <hr
                  className="ms-5 mb-5"
                  style={{ border: "2px solid rgb(112, 214, 255)" }}
                />
                <div className="d-flex align-items-center ms-5 ">
                  <img src="/ellipse-11.svg" alt="" />
                  <div className="d-flex align-items-center">
                    <a
                      className="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link"
                      href="/products"
                      target="_self"
                    >
                      {" "}
                      <span className="qodef-m-button-icon">
                        <svg
                          className="qodef-svg--plus"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          style={{ fill: "white" }}
                          viewBox="0 0 14 14"
                        >
                          <path
                            class="qodef-m-horizontal"
                            d="M0,0H14V2H0Z"
                            transform="translate(0 6)"
                          ></path>
                          <path
                            class="qodef-m-vertical"
                            d="M0,0H2V14H0Z"
                            transform="translate(6)"
                          ></path>
                        </svg>
                      </span>{" "}
                      <span class="qodef-m-button-text">
                        {t("btns.viewMore")}{" "}
                      </span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      name: t("about.meeting.heading"),
      content: (
        <div className="container mb-5 mt-5 pb-5 content_container">
          <div className="tab_columns">
            <div className="col-md-6">
              <img
                className="img-fluid mt-5"
                src={asset25}
                alt="Networking Facilities"
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="tab-content">
                <h1 className="mb-3 section3 ms-5 mt-5">
                  {t("about.meeting.heading")}
                </h1>
                <p
                  className="ms-5 mb-5 whatWeDoPara"

                >
                  {t("about.meeting.para")}
                </p>
                <hr
                  className="ms-5 mb-5"
                  style={{ border: "2px solid rgb(112, 214, 255)" }}
                />
                <div className="d-flex align-items-center ms-5">
                  <img src="/ellipse-11.svg" alt="" />
                  <div className="d-flex align-items-center">
                    <a
                      className="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual qodef-html--link"
                      href="/products"
                      target="_self"
                    >
                      {" "}
                      <span className="qodef-m-button-icon">
                        <svg
                          className="qodef-svg--plus"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          style={{ fill: "white" }}
                          viewBox="0 0 14 14"
                        >
                          <path
                            class="qodef-m-horizontal"
                            d="M0,0H14V2H0Z"
                            transform="translate(0 6)"
                          ></path>
                          <path
                            class="qodef-m-vertical"
                            d="M0,0H2V14H0Z"
                            transform="translate(6)"
                          ></path>
                        </svg>
                      </span>{" "}
                      <span class="qodef-m-button-text">
                        {t("btns.viewMore")}{" "}
                      </span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      name: t("about.sustainable.heading"),
      content: (
        <div className="container mb-5 mt-5 pb-5 content_container">
          <div className="tab_columns">
            <div className="col-md-6">
              <img
                className="img-fluid mt-5"
                src={asset25}
                alt="Networking Facilities"
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="tab-content">
                <h1 className="mb-3 section3 ms-5 mt-5">
                  {t("about.sustainable.heading")}
                </h1>
                <p
                  className="ms-5 mb-5 whatWeDoPara"
                >
                  {t("about.sustainable.para")}
                </p>
                <hr
                  className="ms-5 mb-5"
                  style={{ border: "2px solid rgb(112, 214, 255)" }}
                />
                <div className="d-flex align-items-center ms-5">
                  <img src="/ellipse-11.svg" alt="" />
                  <div className="d-flex align-items-center">
                    <a
                      className="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link"
                      href="/products"
                      target="_self"
                    >
                      {" "}
                      <span className="qodef-m-button-icon">
                        <svg
                          className="qodef-svg--plus"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          style={{ fill: "white" }}
                          viewBox="0 0 14 14"
                        >
                          <path
                            class="qodef-m-horizontal"
                            d="M0,0H14V2H0Z"
                            transform="translate(0 6)"
                          ></path>
                          <path
                            class="qodef-m-vertical"
                            d="M0,0H2V14H0Z"
                            transform="translate(6)"
                          ></path>
                        </svg>
                      </span>{" "}
                      <span class="qodef-m-button-text">
                        {t("btns.viewMore")}{" "}
                      </span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>

      <Helmet>
        <title>
          Global Shipping Business Network for International Container Trade{" "}
        </title>
        <meta
          name="description"
          content="Global shipping business network, WMVOEC, supports trade through efficient supply chain management, operational optimization, business expansion, and networking opportunities."
        />
      </Helmet>

      <div
        id="carouselExampleIndicators"
        className="carousel carousel1 slide"
        data-bs-ride="carousel"
        style={{ marginBottom: "-5rem" }}
      >
        <div className="carousel-inner">
          <div className="carousel-item carousel-item1 active">
            <div className="slider-text overlay1">
              <div>
                <h2>
                  {t("about.header_heading")}
                </h2>
                <p>
                  {t("about.header_para_part_1")}
                  {t("about.header_para_part_2")} <br />
                  {t("about.header_para_part_3")}
                </p>
              </div>
            </div>
            <img
              src={
                "https://blog.blurredego.com/wp-content/uploads/2023/06/Asset-24.png"
              }
              className="d-block w-100 img-fluid"
              alt="Slider background"
              style={{ height: "92%" }}
            />
          </div>
        </div>

        {/* <div className="slider-bottom-btn">
          <Link to="bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="50"
              height="50"
            >
              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          </Link>
        </div> */}
      </div>
      {/* <div className="slider-bottom-text " style={{ marginLeft: "1.5rem" }}>
        <p style={{ textTransform: "capitalize" }}>
          {t("about.header_side_text_1")}
          <br />
          {t("about.header_side_text_2")}
          <br />
          {t("about.header_side_text_3")}
          <br />
          {t("about.header_side_text_4")}
        </p>
      </div> */}

      <div className="bottom">
          <AboutTabs tabs={tabs} />

        {/* <section className="bg-light p-0 m-0">
          <div
            className="background-image-icon d-flex align-items-md-center"
            style={{
              backgroundImage: `url(${backgroundImage1})`,
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              height: "auto",
              padding: "5rem 0",
              width: "100%",
            }}
          >
            <div className="container ">
              <div className="row ">
                <div className="col-md-3 col-sm-6   border-dark text-center py-3">
                  <h4 className="text-uppercase text-white">
                    Delivered Packages
                  </h4>
                  <Counter endValue={2393} />
                </div>
                <div className="col-md-3 col-sm-6  border-dark text-center py-3">
                  <h4 className="text-uppercase text-white">KM Per Month</h4>
                  <Counter endValue={9830} />
                </div>
                <div className="col-md-3 col-sm-6  border-dark text-center py-3">
                  <h4 className="text-uppercase text-white">Tons of Goods</h4>
                  <Counter endValue={7493} />
                </div>
                <div className="col-md-3 col-sm-6 text-center py-3">
                  <h4 className="text-uppercase text-white">
                    Satisfied Clients
                  </h4>
                  <Counter endValue={9202} />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className="container">
          <div className="row">
            <div
              className="col-md-6 d-flex align-items-center"
              style={{ position: "relative" }}
            >
              <div>
                <p
                  className="whatWeDoPara"
                >
                  {t("about.whatWeDoPara1")} <br /> {t("about.whatWeDoPara2")}
                  <br /> {t("about.whatWeDoPara3")}
                </p>
                <p
                  className="mb-5 whatWeDoPara"
                >
                  {t("about.whatWeDoPara4")}
                </p>

                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center  mt-5">
                    <a
                      className="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link"
                      href="/products"
                      target="_self"
                    >
                      {" "}
                      <span className="qodef-m-button-icon">
                        <svg
                          className="qodef-svg--plus"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          style={{ fill: "white" }}
                          viewBox="0 0 14 14"
                        >
                          <path
                            class="qodef-m-horizontal"
                            d="M0,0H14V2H0Z"
                            transform="translate(0 6)"
                          ></path>
                          <path
                            class="qodef-m-vertical"
                            d="M0,0H2V14H0Z"
                            transform="translate(6)"
                          ></path>
                        </svg>
                      </span>{" "}
                      <span class="qodef-m-button-text">
                        {t("btns.viewService")}{" "}
                      </span>{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="vertical-line"></div>
              <div
                className="horizontal-line"
                style={{ top: "calc(65% - 14px)", marginTop: "-2rem" }}
              ></div>
            </div>

            <div className="col-md-6">
              <img
                className="img-fluid"
                src={asset27}
                alt="dummyimage"
              />
            </div>
          </div>
        </div>

        <Testimonial2 />
        <Faq />
        <Team />

        <div className="container  ">
          <div className="row ">
            <div className="col-md-8">
              <h1
                className="mb-4 "
                style={{
                  color: "rgb(12, 52, 89)",
                  fontWeight: "700",
                  fontSize: "2rem",
                }}
              >
                {t("about.growth_heading")}
              </h1>
              <p
                className=""
                style={{
                  color: "rgb(161, 174, 183)",
                  fontWeight: "500",
                  fontSize: "1.188rem",
                }}
              >
                {t("about.growth_para")}
              </p>
            </div>
          </div>
        </div>
        <MapChart />
      </div>
    </>
  );
}

export default About;
