import React, { useRef } from 'react';
import "./WnHome.css";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import wnHomeimage1 from "../../Assets/Assets/wnhome-image-2.png";
import wnHomeimage2 from "../../Assets/Assets/wnhome-slider-1.png";
import wnHomeimage3 from "../../Assets/Assets/wnhome-slider-2.png";
import wnHomeimage4 from "../../Assets/Assets/wnhome-slider-3.png";
import wnHomeimage5 from "../../Assets/SVG/wnhome-arrow-prev.svg";
import wnHomeimage6 from "../../Assets/SVG/wnhome-arrow-next.svg";
import wnHomeimage7 from "../../Assets/Assets/wnhome-image-3.png";
import wnHomeimage8 from "../../Assets/SVG/wnhome-true.svg"
import wnHomeimage9 from "../../Assets/Assets/wnhome-last-Back.png";
import { useTranslation } from 'react-i18next';
import HomeContact from './HomeContact';

const WnHome = () => {

    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };

    const previous = () => {
        sliderRef.slickPrev();
    };


    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "260px",
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 769,
                settings: {
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: "20px",
                }
            },
        ]
    };

    const { t } = useTranslation();

    return (
        <>
            {/* section 1 */}
            <div className=" container-fluid main-wnHome">
                <div className='row wnHome-section-1'>
                    <div className=" col-xl-5 col-lg-6 col-md-6">
                        <div className='wnHome-upper' >
                            <h2 className="wnHome-heading-1">
                                {t("wnhomePage.wnhome")}
                            </h2>
                            <p className='wnHome-para-1'>
                                {t("wnhomePage.wnhome-1")}
                            </p>
                        </div>
                        <p className='wnHome-para-2'>
                            {t("wnhomePage.wnhome-2")} <br />
                            {t("wnhomePage.wnhome-3")}<br />
                            {t("wnhomePage.wnhome-4")}<br />
                            {t("wnhomePage.wnhome-5")}
                        </p>
                    </div>
                    <div className='col-xl-7 col-lg-6 col-md-6'>
                        <div className="wnHome-img-1">
                            <img src={wnHomeimage1} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

            {/* section 2 */}
            <div className="wnHome-section-2">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12 '>
                            <div className='wnHome-lower' >
                                <div className='wnHome-slider-upper'>
                                    <h2 className="wnHome-heading-2">
                                        {t("wnhomePage.wnhome-6")}
                                    </h2>
                                </div>
                                <div className='wnHome-slider'>
                                    <button onClick={previous} className='border-0 bg-transparent'>
                                        <img src={wnHomeimage5} alt=".." className='img-fluid'
                                        />
                                    </button>
                                    <button onClick={next} className='border-0 bg-transparent'>
                                        <img src={wnHomeimage6} alt=".." className='img-fluid' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className='col-lg-12'>
                            <Slider
                                ref={slider => {
                                    sliderRef = slider;
                                }}
                                {...settings}
                            >
                                <Link to="/wnhomeluxe">
                                    <div>
                                        <img src={wnHomeimage2} alt="Eco Home" className='img-fluid' />
                                    </div>
                                </Link>
                                 <Link to="/wnhomeEco"> 
                                <div>
                                    <img src={wnHomeimage3} alt="Value Home" className='img-fluid' />
                                </div>
                                 </Link>
                                 <Link to="/wnhomeValue">
                                <div>
                                    <img src={wnHomeimage4} alt="Luxe Home" className='img-fluid' />
                                </div>
                                 </Link>
                                
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>

            {/* section 3 */}
            <div className="wnHome-section-3">
                <div className="container">
                    <div className="row wnHome-last">
                        <div className='wnHome-about'>
                            <img src={wnHomeimage9} alt="Luxe Home" className='img-fluid' />
                        </div>
                        <div className='col-lg-6'>
                            <div className='wnHome-last-img'>
                                <img src={wnHomeimage7} alt="Luxe Home" className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-lg-6 my-auto'>
                            <div className='wnHome-last-scetion'>
                                <p className='wnHome-para-5'>
                                    {t("wnhomePage.wnhome-7")}
                                </p>
                                <h2 className="wnHome-heading-3">
                                    <span className='wnhome-span'> {t("wnhomePage.wnhome-8")}</span>  {t("wnhomePage.wnhome-9")}
                                </h2>
                                <p className='wnHome-para-6'>
                                    {t("wnhomePage.wnhome-10")}
                                </p>
                                <div className='wnHome-svg'>
                                    <img src={wnHomeimage8} alt="Luxe Home" className='img-fluid' />
                                    <p className='wnHome-para-7'>
                                        {t("wnhomePage.wnhome-11")}
                                    </p>
                                </div>
                                <div className='wnHome-svg'>
                                    <img src={wnHomeimage8} alt="Luxe Home" className='img-fluid' />
                                    <p className='wnHome-para-7'>
                                        {t("wnhomePage.wnhome-12")}
                                    </p>
                                </div>
                                <div className='wnHome-svg'>
                                    <img src={wnHomeimage8} alt="Luxe Home" className='img-fluid' />
                                    <p className='wnHome-para-7'>
                                        {t("wnhomePage.wnhome-13")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Us */}
            <HomeContact />
        </>
    )
}

export default WnHome;
