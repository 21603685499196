import React, { useState } from "react";
import { Link } from "react-scroll";
import asset1 from "../Assets/Assets/Asset-11.png";
// import asset14 from "../Assets/Png/Asset14.webp";
import CustomCard from "../Components/Customcard";
import Accordion from "../Components/Accordation";
import asset21 from "../Assets/SVG/Asset21.svg";
import asset22 from "../Assets/SVG/Asset22.svg";
import asset23 from "../Assets/SVG/Asset23.svg";
import linkedin from "../Assets/SVG/linkedin.svg";
import { Helmet } from "react-helmet-async";
import "../Assets/Home.css";
import "../Assets/Services.css";
import { useTranslation } from "react-i18next";
function Services() {
  const { t } = useTranslation();
  const [paragraph, setParagraph] = useState(
    t("services.solutions.solutions_defult_para_1")
  );
  const [title, setTitle] = useState("");
  const paragraphs = {
    airFreight: t("services.solutions.tracking.tracking_para"),
    seaFreight: t(
      "services.solutions.insurance_coverage.insurance_coverage_para"
    ),
    railFreight: t("services.solutions.partner_program.partner_program_para"),
    landTransport: t("services.solutions.expansion.expansion_para"),
  };

  const titles = {
    airFreight: t("services.solutions.tracking.tracking_main_heading"),
    seaFreight: t(
      "services.solutions.insurance_coverage.insurance_coverage_main_heading"
    ),
    railFreight: t(
      "services.solutions.partner_program.partner_program_main_heading"
    ),
    landTransport: t("services.solutions.expansion.expansion_main_heading"),
  };

  const newParagraphs = {
    airFreight: "",
    railFreight: "",
    landTransport: "",
  };

  const [newParagraph, setNewParagraph] = useState(
    t("services.solutions.solutions_defult_para_2")
  );
  const [activeOption, setActiveOption] = useState(null);

  const handleClick = (option) => {
    // console.log(option);
    setTitle(titles[option]);
    setParagraph(paragraphs[option]);
    setNewParagraph(newParagraphs[option]);
    setActiveOption(option);
  };
  return (
    <>
      <Helmet>
        <title>
          Freight Shipping Services | Freight Shipping Services | WNVOEC
        </title>
        <meta
          name="description"
          content="Your global logistics service company can streamline operations with WNVOEC and get insurance coverage. We support optimization and expansion of freight forwarder services, freight shipping services, and logistics services."
        />
      </Helmet>
      {/* slider in services page */}
      {/* <div
        id="carouselExampleIndicators"
        className="carousel carousel1 slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item carousel-item1 active">
            <div className="slider-text">
              <div>
                <h1 style={{ fontSize: "4.5rem", fontWeight: "700" }}>
                  {t("services.header_main_heading")}
                </h1>

                <p style={{ fontSize: "1.5rem" }}>
                  {t("services.header_para_part_1")}

                  <br />
                  {t("services.header_para_part_2")}
                </p>
              </div>
            </div>
            <img
              src={asset1}
              className="d-block w-100"
              alt="Slider background"
              style={{ height: "95%" }}
            />
          </div>
        </div>

        <div className="slider-bottom-btn arrow-btn">
          <Link to="bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="50"
              height="50"
            >
              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          </Link>
        </div>
      </div> */}

      {/* <div className="slider-bottom-text " style={{ marginLeft: "1.5rem" }}>
        <p style={{ textTransform: "capitalize" }}>
          {t("services.header_side_text_1")}
          <br />
          {t("services.header_side_text_2")}
          <br />
          {t("services.header_side_text_3")}
          <br />
          {t("services.header_side_text_4")}
        </p>
      </div> */}
      {/* slider in services page ended */}

      <div className="bottom">
        <div className="container mt-5 mb-5">
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="widget widget_nav_menu">
                <h4 className="qodef-widget-title ">
                  {t("services.solutions.solutions_heading")}
                </h4>
                <hr className="title-underline" />
                <div className="menu-services-sidebar-menu-container">
                  <ul id="menu-services-sidebar-menu" className="menu">
                    <li className="menu-item">
                      <span
                        onClick={() => handleClick("airFreight")}
                        className={
                          activeOption === "airFreight"
                            ? "menu-link active-solutions"
                            : "menu-link"
                        }
                      >
                        {t("services.solutions.tracking.tracking_main_heading")}
                      </span>
                      <hr className="menu-item-underline" />
                    </li>
                    <li className="menu-item">
                      <span
                        onClick={() => handleClick("seaFreight")}
                        className={
                          activeOption === "seaFreight"
                            ? "menu-link active-solutions"
                            : "menu-link"
                        }
                      >
                        {t(
                          "services.solutions.insurance_coverage.insurance_coverage_main_heading"
                        )}
                      </span>
                      <hr className="menu-item-underline" />
                    </li>
                    <li className="menu-item">
                      <span
                        onClick={() => handleClick("railFreight")}
                        className={
                          activeOption === "railFreight"
                            ? "menu-link active-solutions"
                            : "menu-link"
                        }
                      >
                        {t(
                          "services.solutions.partner_program.partner_program_main_heading"
                        )}
                      </span>
                      <hr className="menu-item-underline" />
                    </li>
                    <li className="menu-item">
                      <span
                        onClick={() => handleClick("landTransport")}
                        className={
                          activeOption === "landTransport"
                            ? "menu-link active-solutions"
                            : "menu-link"
                        }
                      >
                        {t(
                          "services.solutions.expansion.expansion_main_heading"
                        )}
                      </span>
                      <hr className="menu-item-underline" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-6 col-sm-12 mt-5">
              <h3 className="h3-heading">{title}</h3>
              <div className="qodef-shortcode qodef-m qodef-section-title mt-4">
                <p className="qodef-m-text" >
                  {paragraph}
                </p>
                <p className="qodef-m-text">
                  {newParagraph}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* download brochures wala section */}
        <CustomCard />

        <Accordion />

        {/* two cards with  background image */}
        {/* <div className="content-9  ">
          <div className="background-image-icon-2">
            <div className="container">
              <h1 className="text-white pt-4">Become a Member</h1>
              <p className="text-white membership-description">
                Choose from our range of membership options and connect with
                industry peers, access valuable <br />
                resources, and play a part in shaping a more sustainable future
                for the shipping industry.
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                }}
              >
                <div className="row justify-content-lg-start justify-content-center mt-5">
                  <div className="col-lg-12 col-md-12">
                    <div
                      className="card p-4 membership-card"
                      style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        // width: "85%",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          fontSize: "1.3rem",
                          marginBottom: "0.5rem",
                          color: "rgb(12, 52, 89)",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          width="30px"
                          height="30px"
                          className=""
                          style={{ fill: "rgb(12, 52, 89)" }}
                        >
                          <path d="M192 32c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32V64h48c26.5 0 48 21.5 48 48V240l44.4 14.8c23.1 7.7 29.5 37.5 11.5 53.9l-101 92.6c-16.2 9.4-34.7 15.1-50.9 15.1c-19.6 0-40.8-7.7-59.2-20.3c-22.1-15.5-51.6-15.5-73.7 0c-17.1 11.8-38 20.3-59.2 20.3c-16.2 0-34.7-5.7-50.9-15.1l-101-92.6c-18-16.5-11.6-46.2 11.5-53.9L96 240V112c0-26.5 21.5-48 48-48h48V32zM160 218.7l107.8-35.9c13.1-4.4 27.3-4.4 40.5 0L416 218.7V128H160v90.7zM306.5 421.9C329 437.4 356.5 448 384 448c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 501.7 417 512 384 512c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7l0 0C136.7 437.2 165.1 448 192 448c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z" />
                        </svg>
                        <b className="card-100 ms-3">Ordinary Membership</b>
                      </div>
                      <div className="goal-of ms-5 mb-3">
                        Ordinary Membership: Open to all Containers trades
                        Container manufactures, Containers owners NVOEC, and
                        NVOEC companies. <br /> <br />{" "}
                        <span style={{ fontSize: "0.9rem" }}>
                          {" "}
                          Membership fee:{" "}
                        </span>{" "}
                        <b>USD 1500 per year. </b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-lg-start justify-content-center mt-5">
                  <div className="col-lg-12 col-md-12 mb-4">
                    <div
                      className="card p-4 membership-card"
                      style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        width: "85%",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          fontSize: "1.3rem",
                          marginBottom: "0.5rem",
                          color: "rgb(12, 52, 89)",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          width="28px"
                          height="25px"
                          style={{ fill: "rgb(12, 52, 89)" }}
                        >
                          <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                        </svg>
                        <b className="card-100 ms-3">Associate Membership</b>
                      </div>
                      <div className="goal-of ms-5 mb-3">
                        Open to freight forwarding companies, Containers depots
                        , Transport operators, shipping agencies, or entities
                        closely associated with the freight forwarding and
                        logistics industry.
                        <br /> <br />
                        <span style={{ fontSize: "0.9rem" }}>
                          {" "}
                          Membership fee:{" "}
                        </span>{" "}
                        <b>USD 1200 per year. </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* form in services page */}

        <div className="bg-color pb-5">
          <div className="container">
            <form>
              <div className="row form  pt-5">
                <div className="col-lg-6 pt-3">
                  <h2
                    className="  pb-3 section3"
                  // style={{ textAlign: "left" }}
                  >
                    {t("services.join.main_heading")}
                  </h2>
                  <p
                    className="qodef-m-text px-2"
                    style={{
                      // color: "rgb(190, 179, 183)",
                      // fontWeight: "450",
                      // textAlign: "left",
                    }}
                  >
                    {t("services.join.para_1")} <br />
                    {t("services.join.para_2")} <br />
                    {t("services.join.para_3")} <br />
                    {t("services.join.para_4")}
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 mt-4">
                    <input
                      type="text"
                      className="form-control form-placeholder"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control form-placeholder"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control form-placeholder"
                      aria-label="Service"
                      required
                      style={{ color: "rgb(190, 179, 183)", height: "60px" }}
                      placeholder="Write Something..."
                    />
                  </div>

                  <button className="btn btn-sm small-btn mx-auto">
                    {t("btns.submit")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
