import React from "react";
import asset13 from "../Assets/Png/Asset13.webp";
import asset12 from "../Assets/Png/Asset12.webp";
import BrochureA4 from "../Assets/Assets/WNVOEC BROCHURE-1.pdf";

import { useTranslation } from "react-i18next";

const CustomCard = () => {
  const { t } = useTranslation();
  function handleDownload() {
    const url = BrochureA4;
    const link = document.createElement("a");
    link.href = url;
    // console.log(url);
    link.download = "WNVOEC BROCHURE-1";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-4 ">
          <div
            className=" mt-5"
            style={{ backgroundColor: "rgb(112, 214, 255)" }}
          >
            <div>
              <h5
                className=" text-center"
                style={{
                  color: "rgb(7, 39, 77)",
                  paddingTop: "15px",
                  fontSize:"1.28rem"
                }}
              >
                {t("customCard.heading")}
              </h5>
              <div className="card-body">
                <h5
                  className="card-title ms-4"
                  style={{
                    color: "rgb(7, 39, 77)",
                    backgroundColor: "rgb(112, 214, 255)",
                    fontWeight: "bold",
                    fontSize:"1.28rem"
                  }}
                >
                  {t("customCard.our")}
                  <br />
                  {t("customCard.services")}
                  <br />
                  {t("customCard.brochure")}
                </h5>
                <img src={asset13} alt="Small pic" className="mb-3 ms-4 w-50" />
              </div>
              <button
                type="button"
                className="btn w-100 pt-3 pb-3"
                style={{ backgroundColor: "rgb(12, 52, 89)", color: "white" }}
                onClick={handleDownload}
              >
                + {t("customCard.download")}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-7 col-lg-8 d-flex justify-content-center align-items-center mt-5">
          <img
            src={
              "https://images.unsplash.com/photo-1613690413712-dbe316605644?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1159&q=80"
            }
            alt="Right "
            className="w-100 h-100"
            style={{ maxWidth: "750px", maxHeight: "750px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
