import axios from "axios";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const EditProfileForm = ({
  application,
  onSubmit,
  user,
  setFiles,
  setComCert,
  setLogo,
}) => {
  // const [files, setFiles] = useState();
  // console.log(application);
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      industryClassification: application?.attributes?.industryClassification,
      companyType: application?.attributes?.companyType,
      nameOfApplicantCompany: application?.attributes?.nameOfApplicantCompany,
      addressOfMainOffice: application?.attributes?.addressOfMainOffice,
      correspondenceAddress: application?.attributes?.correspondenceAddress,
      telephoneNumber: application?.attributes?.telephoneNumber,
      fax: application?.attributes?.fax,
      office1: application?.attributes?.office1,
      emailAddressOfCompany: application?.attributes?.emailAddressOfCompany,
      companyInfo: application?.attributes?.companyInfo,
      websiteAddressOfCompany: application?.attributes?.websiteAddressOfCompany,
      staffStrengthOfCompany: application?.attributes?.staffStrengthOfCompany,
      contactPerson1: application?.attributes?.contactPerson1,
      designation1: application?.attributes?.designation1,
      emailAddress1: application?.attributes?.emailAddress1,
      mobile1: application?.attributes?.mobile1,
      contactPerson2: application?.attributes?.contactPerson2,
      designation2: application?.attributes?.designation2,
      emailAddress2: application?.attributes?.emailAddress2,
      logoOfCompany: application?.attributes?.logoOfCompany,
      companyRegistrationCertificate:
        application?.attributes?.companyRegistrationCertificate,
    },
  });

  const currentCountry = (data) => {
    // setSelectedCountry(data);
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const uploadFiles = async (files, name) => {
    const formData = {
      files: files,
    };

    try {
      const response = await axios.post(`${apiUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.jwt}`,
        },
      });
      // Handle the upload response here

      if (name === "logoOfCompany") setLogo(response.data[0].url);
      if (name === "companyRegistrationCertificate")
        setComCert(response.data[0].url);

      setFiles({ [name]: response.data[0].url });
      // setFiles([...newFile, { [name]: response.data[0].url }]);
    } catch (error) {
      // Handle the upload error
      // console.error(error);
      toast.error("File size is too large");
    }
  };

  const industryClassificationOptions = [
    "Container Traders",
    "NVOCC",
    "NVOECs",
    "Shipping Related companies including Depot operators",
    "Transport Operators",
    "IT Industry",
    "Trade Associations",
    "Direct Shippers",
    "Shipping Agencies",
    "Government Agencies",
    "Freight Forwarders",
  ];

  const companyTypeOptions = [
    "Limited Liability Company",
    "Partnership",
    "Sole Proprietorship",
  ];

  return (
    <div className="main_area container" style={{ marginTop: "8rem" }}>
      <h2 style={{ marginBottom: "1rem" }}>{t("editProfile.edit_profile")}</h2>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="column_wrapper"
      >
        <div className="column">
          <div className="singleField">
            <label htmlFor={"Industry Classification"} className="form-label">
              {t("membership.application.Industry_classification")}{" "}
            </label>
            <select
              style={{ height: "2.5rem" }}
              className="form-select"
              aria-label="Default select example"
              defaultValue={""}
              {...methods.register("industryClassification")}
            >
              <option value="" hidden>
                {t("membership.application.Choose")}
              </option>
              {industryClassificationOptions?.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="singleField">
            <label htmlFor={"Company Type"} className="form-label">
              {t("membership.application.comp_type")}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <select
              style={{ height: "2.5rem" }}
              className="form-select"
              aria-label="Default select example"
              {...methods.register("companyType")}
            >
              <option value="" hidden>
                {t("membership.application.Choose")}
              </option>
              {companyTypeOptions?.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
            <div>
              <label
                htmlFor={"Name of Applicant Company"}
                className="form-label"
              >
                {t("membership.application.comp_name")}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Name of Applicant Company"}
                className="form-control"
                placeholder={t("membership.application.comp_name")}
                {...methods.register("nameOfApplicantCompany")}
              />
            </div>
            <div>
              <label htmlFor={"Address of Main Office"} className="form-label">
                {t("membership.application.comp_main_address")}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Address of Main Office"}
                className="form-control"
                placeholder={t("membership.application.comp_main_address")}
                {...methods.register("addressOfMainOffice")}
              />
            </div>
            <div>
              <label
                htmlFor={"Correspondence Address (if differs from above)"}
                className="form-label"
              >
                {t("membership.application.comp_correspondence_address")}{" "}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Correspondence Address (if differs from above)"}
                className="form-control"
                placeholder={t(
                  "membership.application.comp_correspondence_address"
                )}
                {...methods.register("correspondenceAddress")}
              />
            </div>
            <div>
              <label htmlFor={"Telephone Number"} className="form-label">
                {t("membership.application.comp_telephone")}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Telephone Number"}
                className="form-control"
                placeholder={t("membership.application.comp_telephone")}
                {...methods.register("telephoneNumber")}
              />
            </div>
            <div>
              <label htmlFor={"Fax"} className="form-label">
                {t("membership.application.comp_fax")}{" "}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Fax"}
                className="form-control"
                placeholder={t("membership.application.comp_fax")}
                {...methods.register("fax")}
              />
            </div>
            <div>
              <label
                htmlFor={"Email Address of Company"}
                className="form-label"
              >
                {t("membership.application.comp_email")}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Email Address of Company"}
                className="form-control"
                placeholder={t("membership.application.comp_email")}
                {...methods.register("emailAddressOfCompany")}
              />
            </div>
            <div>
              <label htmlFor={"Company Information"} className="form-label">
                {t("membership.application.comp_info")}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>

              <textarea
                // style={{ height: "500px" }}
                id={"Company Information"}
                className="form-control"
                placeholder={t("membership.application.comp_info")}
                {...methods.register("companyInfo")}
              />
            </div>
            <div>
              <label
                htmlFor={"Website Address of Company"}
                className="form-label"
              >
                {t("membership.application.comp_website")}{" "}
                {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Website Address of Company"}
                className="form-control"
                placeholder={t("membership.application.comp_website")}
                {...methods.register("websiteAddressOfCompany")}
              />
            </div>
            <div>
              <label
                htmlFor={"Staff Strength of Company"}
                className="form-label"
              >
                {t("membership.application.comp_staff")}{" "}
              </label>
              <input
                defaultValue={""}
                type={"text"}
                id={"Staff Strength of Company"}
                className="form-control"
                placeholder={t("membership.application.comp_staff")}
                {...methods.register("staffStrengthOfCompany")}
              />
            </div>
          </div>
        </div>
        <div
          className="column"
          style={{
            width: "50%",
          }}
        >
          <div>
            <label htmlFor={"Contact Person 1"} className="form-label">
              {t("membership.application.contact_person_1")}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Contact Person 1"}
              className="form-control"
              placeholder={t("membership.application.contact_person_1")}
              {...methods.register("contactPerson1")}
            />
          </div>
          <div>
            <label htmlFor={"Designation"} className="form-label">
              {t("membership.application.person_designation")}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Designation"}
              className="form-control"
              placeholder={t("membership.application.person_designation")}
              {...methods.register("designation1")}
            />
          </div>
          <div>
            <label htmlFor={"Email Address"} className="form-label">
              {t("membership.application.person_email_1")}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Email Address"}
              className="form-control"
              placeholder={t("membership.application.person_email_1")}
              {...methods.register("emailAddress1")}
            />
          </div>
          <div>
            <label htmlFor={"Office"} className="form-label">
              {t("membership.application.person_office_1")}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Office"}
              className="form-control"
              placeholder={t("membership.application.person_office_1")}
              {...methods.register("office1")}
            />
          </div>
          <div>
            <label htmlFor={"Mobile"} className="form-label">
              {t("membership.application.person_mobile_1")}{" "}
              {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Mobile"}
              className="form-control"
              placeholder={t("membership.application.person_mobile_1")}
              {...methods.register("mobile1")}
            />
          </div>
          <div>
            <label htmlFor={"Contact Person 2"} className="form-label">
              {t("membership.application.contact_person_2")}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Contact Person 2"}
              className="form-control"
              placeholder={t("membership.application.contact_person_2")}
              {...methods.register("contactPerson2")}
            />
          </div>
          <div>
            <label htmlFor={"Designation"} className="form-label">
              {t("membership.application.person_designation")}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Designation"}
              className="form-control"
              placeholder={t("membership.application.person_designation")}
              {...methods.register("designation2")}
            />
          </div>
          <div>
            <label htmlFor={"Email Address"} className="form-label">
              {t("membership.application.person_email_2")}{" "}
            </label>
            <input
              defaultValue={""}
              type={"text"}
              id={"Email Address"}
              className="form-control"
              placeholder={t("membership.application.person_email_2")}
              {...methods.register("emailAddress2")}
            />
          </div>
        </div>
        <div className="column">
          <div>
            <label htmlFor="exampleFormControlInput3" className="form-label">
              {t("membership.application.logo_compony")}{" "}
            </label>
            <input
              type="file"
              accept="image/*"
              name="logoOfCompany"
              className="form-control"
              onChange={(e) => uploadFiles(e.target.files[0], e.target.name)}
              id="exampleFormControlInput3"
            />
          </div>
          <br />
          <div>
            <label htmlFor="exampleFormControlInput3" className="form-label">
              {/* {t("membership.application.logo_compony")}{" "} */}
              Company’s Business Registration Certificate
            </label>
            <input
              type="file"
              accept="image/*"
              name="companyRegistrationCertificate"
              className="form-control"
              onChange={(e) => uploadFiles(e.target.files[0], e.target.name)}
              id="exampleFormControlInput3"
            />
          </div>
        </div>
        <div></div>
        <div className="buttonWrapper ">
          <button type="submit" className="btn btn-primary">
            {t("btns.submit")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfileForm;
