import React, { useEffect, useRef, useState } from "react";
import GenericTable from "../../../Components/CommonTable/GenericTable";
import ProfileDetails from "../../Profile/ProfileDetails";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import "./MemberApplications.css";
import MemberApplicationCard from "./MemberApplicationCard";
import UserEdit from "../../../Assets/Icons/UserEdit";
import Search2 from "../../../Assets/Icons/Search2";

const MemberApplications = (props) => {
  const [selectedApplication, setApplicationProfile] = useState(null);
  const [query, setQuery] = useState("");
  const [searchOpt, setSearchOpt] = useState("searchByName");
  const [sortOpt, setSortOpt] = useState("sortByAlphabet");
  const [openApplication, setOpenApplication] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showApplication, setShowApplication] = useState("10");
  const applicationsPerPage = showApplication;
  const { t, i18n } = useTranslation();

  /////////// scroll drag states

  const sliderRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const [showSelects, setShowSelects] = useState(false);

  const toggleSelects = () => {
    setShowSelects(!showSelects);
  };

  const indexOfLastApplication = currentPage * applicationsPerPage;
  const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const viewProfile = (application) => {
    setApplicationProfile(application);
    // props?.storeNotification(
    //   props?.user?.email,
    //   application?.email,
    //   "Member profile viewed",
    //   `User ${props.user?.name} has viewed your member profile.`
    // );
  };

  // useEffect(() => {
  //   props?.storeNotification(
  //     props?.user?.email,
  //     selectedApplication?.email,
  //     "Member profile viewed",
  //     `User ${props.user?.name} has viewed your member profile.`
  //   );
  // }, [viewProfile]);

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(props.tableData.length / applicationsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleApplication = (index) => {
    setOpenApplication(openApplication === index ? null : index);
  };

  const sortApplications = (applications) => {
    if (sortOpt === "sortByDate") {
      return applications.sort((a, b) => {
        const dateA = new Date(a?.attributes?.createdAt);
        const dateB = new Date(b?.attributes?.createdAt);
        return dateB - dateA;
      });
    } else if (sortOpt === "sortByAlphabet") {
      return applications.sort((a, b) => {
        const nameA = a?.attributes?.nameOfApplicantCompany?.toLowerCase();
        const nameB = b?.attributes?.nameOfApplicantCompany?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }
    return applications;
  };

  const startDragging = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isMouseDown) {
      return;
    }
    const x = e.pageX - sliderRef.current.offsetLeft;
    const scroll = x - startX;
    sliderRef.current.scrollLeft = scrollLeft - scroll;
  };

  const tableCols = [
    { id: "id", label: "ID" },
    {
      id: "email",
      label: "Email",
      accessor: (row, col) => {
        return <span>{row?.attributes?.email}</span>;
      },
    },
    {
      id: "nameOfApplicantCompany",
      label: "Company Name",
      accessor: (row, col) => {
        return <span>{row?.attributes?.nameOfApplicantCompany}</span>;
      },
    },

    {
      id: "companyType",
      label: "Type",
      accessor: (row, col) => {
        return <span>{row?.attributes?.companyType}</span>;
      },
    },
    {
      id: "companyEmail",
      label: "Company Email",
      accessor: (row, col) => {
        return <span>{row?.attributes?.emailAddressOfCompany}</span>;
      },
    },
    {
      id: "telephone",
      label: "Number",
      accessor: (row, col) => {
        return <span>{row?.attributes?.telephoneNumber}</span>;
      },
    },
    {
      id: "tableActions",
      actions: true,
      label: "Actions",
      actionOpts: {
        view: true,
      },
    },
  ];

  const editCols = [
    {
      id: "approve",
      label: "Approve",
      accessor: (row, col) => {
        return row?.attributes?.Approved ? (
          <span>Yes</span>
        ) : (
          <select
            name=""
            defaultValue="no"
            id=""
            className="selection_box"
            onChange={(e) => props.onAppApproveChange(row, e.target.value)}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
            <option value="fm">FM</option>
          </select>
        );
      },
    },
  ];

  let toReturn = selectedApplication ? (
    <div style={{ padding: "25px" }}>
      <button
        className="btn application_back_btn"
        onClick={(e) => setApplicationProfile(null)}
      >
        Back
      </button>
      <ProfileDetails applicationData={selectedApplication} user={props.user} />
    </div>
  ) : (
    <div>
      {!props.noEdit && (
        <div
          style={{
            marginBottom: "15px",
            marginRight: "50px",
            textAlign: "right",
          }}
        >
          <button className="invoice_file_label" onClick={props.onSave}>
            Save
          </button>
        </div>
      )}
      {/* <GenericTable
        cols={props.noEdit ? tableCols : [...tableCols, ...editCols]}
        rows={props.tableData}
        actionMethods={{
          onViewClick: (row) => setApplicationProfile(row?.attributes),
        }}
      /> */}
      <div
        className="application_container"
        ref={sliderRef}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
        onMouseMove={handleMouseMove}
      >
        <div className="search_container">
          <div className="d-flex align-items-center ">
            <input
              type="text"
              className="search_input form-control"
              placeholder={t("members.search")}
              onChange={(e) => setQuery(e.target.value)}
              style={{ minWidth: "12rem", paddingTop: "0.2rem" }}
            />

            <div
              className="toggle-icon"
              onClick={() => toggleSelects()}
              style={{ cursor: "pointer" }}
            >
              <Search2 />
            </div>
          </div>

          {showSelects && (
            <>
              <select
                className="form-select"
                style={{ maxWidth: "12rem", paddingTop: "0.2rem" }}
                name=""
                id=""
                onChange={(e) => setSearchOpt(e.target.value)}
              >
                <option value="searchByName">{t("members.search_name")}</option>
                <option value="searchByIndustry">
                  {t("members.search_industry")}
                </option>
                <option value="searchByAddress">
                  {t("members.search_address")}
                </option>
                <option value="searchByCountry">Search By Country</option>
              </select>

              <select
                className="form-select"
                style={{ maxWidth: "12rem", paddingTop: "0.2rem" }}
                name=""
                id=""
                onChange={(e) => setSortOpt(e.target.value)}
              >
                <option value="sortByDate">{t("members.sort_date")}</option>
                <option value="sortByAlphabet" selected>
                  {t("members.sort_alp")}
                </option>
              </select>
            </>
          )}
        </div>

        <hr />

        <div className="applications_container">
          {sortApplications(props.tableData)
            .filter((application) => {
              if (query === "") {
                return application;
              } else if (
                searchOpt === "searchByName" &&
                application.attributes.nameOfApplicantCompany
                  ?.toLowerCase()
                  ?.includes(query?.toLocaleLowerCase())
              ) {
                return application;
              } else if (
                searchOpt === "searchByIndustry" &&
                application.attributes.industryClassification
                  ?.toLowerCase()
                  ?.includes(query?.toLocaleLowerCase())
              ) {
                return application;
              } else if (
                searchOpt === "searchByAddress" &&
                application.attributes.addressOfMainOffice
                  ?.toLowerCase()
                  ?.includes(query?.toLocaleLowerCase())
              ) {
                return application;
              } else if (
                searchOpt === "searchByCountry" &&
                application.attributes.country
                  ?.toLowerCase()
                  ?.includes(query?.toLocaleLowerCase())
              ) {
                return application;
              }
            })
            .slice(indexOfFirstApplication, indexOfLastApplication)
            .map((application, id) => (
              <>
                <MemberApplicationCard
                  application={application}
                  viewProfile={viewProfile}
                />
              </>
            ))}
        </div>

        <div className="pagination_box">
          <div className="pagination">
            <button
              className="pagination_btn"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              {t("btns.prev")}
            </button>
            {pageNumbers.map((number) => (
              <button
                className={`pagination_btn ${
                  currentPage === number ? "bg-primary " : ""
                }`}
                key={number}
                onClick={() => paginate(number)}
              >
                {number}
              </button>
            ))}
            <button
              className="pagination_btn"
              onClick={nextPage}
              disabled={currentPage === pageNumbers.length}
            >
              {t("btns.next")}
            </button>
          </div>

          <select
            name=""
            id=""
            className="application_perpage"
            onChange={(e) => setShowApplication(e.target.value)}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="35">35</option>
            <option value="45">45</option>
          </select>
        </div>
      </div>
    </div>
  );
  return toReturn;
};
export default MemberApplications;
