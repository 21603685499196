import React, { useEffect, useState } from "react";
import "./Analytics.css";
// import { apiCaller } from "../../../utils/api";

import AnalyticsUi from "./AnalyticsUi";
import { apiCaller } from "../../utils/api";

const Analytics = ({ user }) => {
  const [isManager, setManager] = useState(
    user && user["member-manager"] ? true : false
  );

  const [users, setUsers] = useState([]);
  const [verifiedUsers, setVerifiedUsers] = useState([]);
  const [nonVerifiedUsers, setNonVerifiedUsers] = useState([]);
  

  const [applications, setApplications] = useState([]);
  const [approvedApplications, setApprovedApplications] = useState([]);
  const [nonApprovedApplications, setNonApprovedApplications] = useState([]);

  const [invoices, setInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [notIssuedInvoices, setNotIssuedInvoices] = useState([]);

  const [members, setMembers] = useState([]);
  const [ordinaryMembers, setOrdinaryMembers] = useState([]);
  const [foundingMembers, setFoundingMembers] = useState([]);
  const [associateMembers , setAssociateMembers] = useState([]) ;
  const [affiliateMembers , setAffiliateMembers] = useState([])

  const getAllUsers = async () => {
    let result = await apiCaller("get", "users");
    if (result.err) console.log(null);
    else {
      setUsers(result);
      setVerifiedUsers(result?.filter((user) => user?.confirmed == true));
      setNonVerifiedUsers(result?.filter((user) => user?.confirmed == false));
    }
  };

  const getAllApplications = async () => {
    let result = await apiCaller("get", "applications");
    console.log("result", result);
    if (result.err) console.log(null);
    else {
      setApplications(result.data);
      setApprovedApplications(
        result.data?.filter((app) => app?.attributes?.Approved == true)
      );
      setNonApprovedApplications(
        result.data?.filter((app) => app?.attributes?.Approved == false)
      );
    }
  };

  const getAllInvoices = async () => {
    let result = await apiCaller("get", "invoices");
    if (result.err) console.log(null);
    else {
      setInvoices(result.data);
      setPaidInvoices(
        result.data?.filter((invoice) => invoice?.attributes?.received == true)
      );
      setUnpaidInvoices(
        result.data?.filter((invoice) => invoice?.attributes?.received == false)
      );
    }
  };

  useEffect(() => {
    if (isManager) {
      getAllUsers();
      getAllApplications();
      getAllInvoices();
    }
  }, []);

  useEffect(() => {
    if (isManager) {
      // const getMembers = users?.filter((user) => {
      //   const userApplication = applications?.find(
      //     (app) => app?.attributes?.email === user?.email
      //   );
      //   return userApplication !== undefined;
      // });

      const getFoundingMembers =  applications?.filter((app) => app?.attributes?.membershipType === "foundingMember"
      );

      const getOrdinaryMembers = applications?.filter((app) => app?.attributes?.membershipType === "ordinary"
      );
     
      const getAssociateMembers = applications?.filter((app) => app?.attributes?.membershipType === "associate"
      )

      const getAffiateMembers = applications?.filter((app) => app?.attributes?.membershipType === "affiliate"
      )

      const getNotIssuedInvoices = invoices?.filter(
        (invoice) => invoice?.attributes?.invoiceUrl === null
      );

      // setMembers(getMembers);
      setFoundingMembers(getFoundingMembers);
      setOrdinaryMembers(getOrdinaryMembers);
      setNotIssuedInvoices(getNotIssuedInvoices);
      setAssociateMembers(getAssociateMembers);
      setAffiliateMembers(getAffiateMembers)
    }
  }, [users, applications, invoices]);

  return (
    <AnalyticsUi
      users={users}
      applications={applications}
      verifiedUsers={verifiedUsers}
      nonVerifiedUsers={nonVerifiedUsers}
      approvedApplications={approvedApplications}
      nonApprovedApplications={nonApprovedApplications}
      invoices={invoices}
      paidInvoices={paidInvoices}
      unpaidInvoices={unpaidInvoices}
      members={members}
      foundingMembers={foundingMembers}
      ordinaryMembers={ordinaryMembers}
      notIssuedInvoices={notIssuedInvoices}
      associateMembers={associateMembers}
      affiliateMembers={affiliateMembers}
    />
  );
};

export default Analytics;
